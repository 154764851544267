import TableCustom from "@/components/TableCustom";
import ContentWrapper from "@/components/layouts/DashboardLayout/Content";
import { columns } from "./quickOrder.constants";
import routes from "@/routes";
import FilterComponent from "./components/FilterComponent";
import { useTypedDispatch, useTypedSelector } from "@/app/store";
import { useGetUserInfoQuery } from "@/rtk-query/userQueries";
import { quickOrderSlide } from "./quickOrder.slice";
import { useEffect, useRef, useState } from "react";
import useIntersectionObserver from "@/hooks/useIntersectionObserver";
import { Row, Spin } from "antd";
import C from "@/utils/constants";
import { checkTotalAllItem } from "@/utils/helpers";
import { useGetWarningProductListQuery } from "@/rtk-query/productQueries";
import { WarehouseType } from "@/utils/types";
import TagIcon from "@/assets/icons/TagIcon";
import styles from "./quickOrder.module.less";
import { CompanySelectType } from "../WarehouseManagement/warehouseManagement.slice";

function QuickOrderModule() {
  const [data, setData] = useState<Array<any>>([]);
  const [page, setPage] = useState(1);

  const { companySelected, wareHouseSelected } = useTypedSelector((store) => store.quickOrderSlide);
  const { currentData, isFetching, isLoading } = useGetWarningProductListQuery({
    page,
    companyId: companySelected?.id || "",
    warehouseId: wareHouseSelected?.id || "",
    pageSize: C.PAGE_SIZE,
    ignoreDeleted: true,
    isLoading: undefined,
  });

  const { currentData: userInfo } = useGetUserInfoQuery();

  const dispatch = useTypedDispatch();
  const { onSelectCompany, onSelectWareHouse } = quickOrderSlide.actions;

  const handleSelectCompany = (value: CompanySelectType) => {
    dispatch(onSelectCompany(value));
    setPage(1);
  };

  const handleOnSelectWareHouse = (value: WarehouseType | null) => {
    dispatch(onSelectWareHouse(value));
    setPage(1);
  };

  useEffect(() => {
    handleSelectCompany({
      id: userInfo?.company?.id,
      companyName: userInfo?.company?.companyName,
      address: userInfo?.company?.address,
    });
    handleOnSelectWareHouse(null);
  }, [userInfo]);

  // Intersection Observer
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {});
  const isVisible = !!entry?.isIntersecting;

  useEffect(() => {
    setData(page === 1 ? currentData?.data || [] : [...data, ...(currentData?.data || [])]);
  }, [currentData, isFetching, page]);

  useEffect(() => {
    if (isVisible) {
      setPage((currentData?.page || 0) + 1);
    }
  }, [isVisible]);

  return (
    <ContentWrapper
      dataBreadCrumb={[
        {
          title: routes.quickOrderList.title,
          icon: <TagIcon />,
          url: routes.quickOrderList.path,
        },
      ]}
      renderFilter={<FilterComponent type="quickOrder" onSelectCompany={handleSelectCompany} onSelectWareHouse={handleOnSelectWareHouse} />}
    >
      <div className={styles.title}>ALERT: PRODUCTS WITH LOW STOCK</div>
      <TableCustom
        data={data || []}
        columns={columns()}
        total={checkTotalAllItem({
          page,
          companyId: companySelected?.id || "",
          search: wareHouseSelected?.identityCode || "",
        })}
        loading={isLoading}
      />
      {currentData && Number(currentData?.page) < Number(currentData?.totalPage) && (
        <Row
          ref={ref}
          justify="center"
          style={{
            padding: 36,
          }}
        >
          <Spin tip="Loading" size="large" />
        </Row>
      )}
    </ContentWrapper>
  );
}

export default QuickOrderModule;
