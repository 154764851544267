import { Col, Input, Modal, Row, Typography, DatePicker, InputRef, Tooltip, Select, Form, Button, notification } from "antd";
import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import clsx from "clsx";
import styles from "./dialogUserCUR.module.less";
import commonStyles from "@/styles/common.module.less";
import CalendarIcon from "@/assets/icons/CalendarIcon";
import CopyIcon from "@/assets/icons/CopyIcon";
import SwapIcon from "@/assets/icons/SwapIcon";
import { addZero, autoGeneratePassword, getError, removeVietnameseTones, unsecuredCopyToClipboard } from "@/utils/helpers";
import { useTypedDispatch, useTypedSelector } from "@/app/store";
import { dialogUserCURSlide } from "./dialogUserCUR.slice";
import message from "@/utils/message";
import { useAddUserToClientMutation, useGetUserDetailQuery, useResetDefaultPasswordMutation, useUpdateUserMutation } from "@/rtk-query/userQueries";
import { useGetCompanyRolesQuery } from "@/rtk-query/companyQueries";
import C from "@/utils/constants";
import { useGetCallingCodeListQuery } from "@/rtk-query/commonQueries";
import { RangePickerProps } from "antd/es/date-picker";

const { Title } = Typography;
const hidePassword = "************";

const DialogUserCUR = () => {
  const { isOpen, companyId, id, type } = useTypedSelector((store) => store.dialogUserCURSlide);
  const dispatch = useTypedDispatch();
  const { updateClientCURState } = dialogUserCURSlide.actions;

  const passwordRef = useRef<InputRef>(null);
  const [copyPasswordStatus, setCopyPasswordStatus] = useState(false);

  const { currentData: roleList } = useGetCompanyRolesQuery(companyId || "", {
    skip: !companyId,
  });
  const { currentData: callingCodeList } = useGetCallingCodeListQuery({});

  // API
  const [addUserApi, { isLoading: isLoadingCreateUser }] = useAddUserToClientMutation();
  const [updateUserApi, { isLoading: isLoadingUpdateUser }] = useUpdateUserMutation();
  const [resetDefaultPasswordApi, { isLoading: isLoadingResetPassword }] = useResetDefaultPasswordMutation();

  const { currentData: detailUser, isFetching: isFetchingUserDetail } = useGetUserDetailQuery(String(id), {
    skip: !id,
  });
  //

  // Form Instance
  const [form] = Form.useForm();
  const fullNameWatcher = Form.useWatch("fullName", form);
  const passwordWatcher = Form.useWatch("password", form);

  useEffect(() => {
    type === "create" &&
      form.setFieldValue("username", fullNameWatcher ? `${removeVietnameseTones(fullNameWatcher).split(" ").join("")}.${addZero(companyId || "")}` : "");
  }, [fullNameWatcher]);

  //

  const handleSetIntialValues = () => {
    if (!id || isFetchingUserDetail) return;
    const initialValues = {
      fullName: detailUser?.fullName || "",
      username: detailUser?.username || "",
      email: detailUser?.email || "",
      password: hidePassword,
      role: detailUser?.roles?.[0]?.id || "",
      dob: detailUser?.dob ? dayjs(detailUser?.dob, "YYYY-MM-DD") : "",
      status: detailUser?.status || 1,
      contact: {
        phoneExt: detailUser?.phoneExt || "",
        phoneNumber: detailUser?.phoneNumber || "",
      },
      address: detailUser?.address || "",
    };

    form.setFields(
      Object.entries(initialValues).map(([name, value]) => ({
        name,
        value,
      }))
    );
  };

  const handleGeneratePassword = async () => {
    if (type === "create") {
      form.setFieldValue("password", autoGeneratePassword());
    } else {
      try {
        const newPassword = autoGeneratePassword();
        await resetDefaultPasswordApi({
          id: id || "",
          body: {
            newPassword,
          },
        }).unwrap();

        await notification.success({
          message: message.SUCCESSFULLY,
          description: "Please copy new password!",
          key: "reset_password",
        });
        form.setFieldValue("password", newPassword);
      } catch (error: any) {
        notification.error({
          key: "reset_password",
          message: getError(error),
        });
      }
    }
  };

  const handleCopyPassword = () => {
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(form.getFieldValue("password"));
    } else {
      unsecuredCopyToClipboard(form.getFieldValue("password"));
    }
    setCopyPasswordStatus(true);
  };

  const handleCloseDialog = () => {
    dispatch(
      updateClientCURState({
        isOpen: false,
      })
    );
  };

  const handleSubmit = async ({ fullName, username, email, password, role, dob, contact, address, status }: any) => {
    const body = {
      fullName,
      roleId: role,
      email,
      dob: dayjs(dob).format("YYYY-MM-DD"),
      ...contact,
      address,
      status,
    } as any;
    try {
      type === "create"
        ? await addUserApi({
            ...body,
            password,
            companyId: String(companyId),
            username,
          }).unwrap()
        : await updateUserApi({ id: id || "", body }).unwrap();
      handleCloseDialog();
      notification.success({
        message: message.SUCCESSFULLY,
        key: "user_cur",
      });
    } catch (err) {
      notification.error({
        key: "user_cur",
        message: getError(err),
      });
    }
  };

  useEffect(() => {
    if (isOpen) {
      type === "create" ? handleGeneratePassword() : handleSetIntialValues();
    } else {
      form.resetFields();
    }
  }, [isOpen, isFetchingUserDetail]);

  const requiredValidate = (field: string) => ({
    required: true,
    validator: (_: any, value: string) => (value.trim().length > 0 ? Promise.resolve() : Promise.reject()),
    message: message.ERROR_NOT_BLANK(field),
  });

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days after today and today
    return current && current > dayjs().endOf("day");
  };

  const dropdownStyle = {
    background: "#dfe3ef",
    padding: 0,
    borderRadius: 0,
    boxShadow: "inset 4px 4px 3px rgba(0, 0, 0, 0.03)",
    fontSize: 12,
    fontWeight: 600,
  };

  return (
    <Modal
      title={
        <Title level={5} style={{ color: "#DE5706", marginLeft: 92, marginBottom: 32 }}>
          {type === "create" ? "ADD NEW USER" : "EDIT USER"}
        </Title>
      }
      open={isOpen}
      footer={null}
      centered
      width={1000}
      wrapClassName={styles.dialogWrap}
      closable={false}
    >
      <Form name="basic" labelCol={{ span: 6 }} wrapperCol={{ span: 24 }} onFinish={handleSubmit} autoComplete="off" form={form}>
        <Row
          style={{
            marginBottom: 36,
          }}
          gutter={[8, 16]}
        >
          <Col span={12}>
            <Form.Item label={<span className={commonStyles.label}>Fullname</span>} name="fullName" rules={[requiredValidate("Fullname")]}>
              <Input className={clsx(commonStyles.textField, commonStyles.textFieldDark)} size="large" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={<span className={commonStyles.label}>D.O.B</span>}
              name="dob"
              rules={[
                {
                  required: true,
                  message: message.ERROR_NOT_BLANK("D.O.B"),
                },
              ]}
            >
              <DatePicker
                className={clsx(commonStyles.textField, commonStyles.textFieldDark)}
                format="DD/MM/YY"
                suffixIcon={<CalendarIcon />}
                placeholder=""
                disabledDate={disabledDate}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label={<span className={commonStyles.label}>Email</span>} name="email" rules={[requiredValidate("Email")]}>
              <Input className={clsx(commonStyles.textField, commonStyles.textFieldDark)} size="large" type="email" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={<span className={commonStyles.label}>Address</span>}
              name="address"
              rules={[requiredValidate("Address")]}
              wrapperCol={{
                span: 24,
              }}
            >
              <Input className={clsx(commonStyles.textField, commonStyles.textFieldDark)} size="large" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label={<span className={commonStyles.label}>Contact</span>} required>
              <Input.Group compact>
                <Form.Item
                  name={["contact", "phoneExt"]}
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: message.ERROR_NOT_BLANK("Country code"),
                    },
                  ]}
                >
                  <Select
                    style={{ width: "30%" }}
                    dropdownStyle={dropdownStyle}
                    popupClassName="customDropdownSelectAntd"
                    className={commonStyles.selectDark}
                    size="large"
                    showSearch
                    filterOption={(input, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}
                  >
                    {(callingCodeList || []).map(({ code, alpha2Code }: any, index: number) => (
                      <Select.Option value={code} key={index}>
                        {`${alpha2Code} ${code}`}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name={["contact", "phoneNumber"]}
                  noStyle
                  rules={[
                    requiredValidate("Contact"),
                    {
                      pattern: new RegExp(C.REGEX_PHONE_NUMBER),
                      validator: (rule, value) => {
                        if (!value || rule.pattern?.test(value)) return Promise.resolve();
                        return Promise.reject();
                      },
                      message: message.ERROR_INVALID("contact number"),
                    },
                  ]}
                >
                  <Input style={{ width: "70%" }} className={clsx(commonStyles.textField, commonStyles.textFieldDark)} />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={<span className={commonStyles.label}>Status</span>}
              name="status"
              rules={[
                {
                  required: true,
                  message: message.ERROR_NOT_BLANK("Status"),
                },
              ]}
              style={{
                flex: 1,
                width: "100%",
              }}
            >
              <Select
                className={commonStyles.selectDark}
                size="large"
                dropdownStyle={dropdownStyle}
                popupClassName="customDropdownSelectAntd"
                placeholder=""
                optionFilterProp="children"
                allowClear
                getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}
              >
                {Object.entries(C.USER_STATUS)
                  .slice(Object.keys(C.USER_STATUS).length / 2)
                  .map(([key, value], index) => (
                    <Select.Option value={value} key={index}>
                      {key}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[8, 16]}>
          <Col span={12}>
            <Form.Item
              label={<span className={commonStyles.label}>Username</span>}
              name="username"
              rules={[
                {
                  required: true,
                  message: message.ERROR_NOT_BLANK("Username"),
                },
              ]}
            >
              <Input className={clsx(commonStyles.textField, commonStyles.textFieldDark)} size="large" disabled />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={<span className={commonStyles.label}>Default password</span>}
              name="password"
              rules={[
                {
                  required: true,
                  message: message.ERROR_NOT_BLANK("Default password"),
                },
              ]}
            >
              <Input
                ref={passwordRef}
                className={clsx(commonStyles.textField, commonStyles.textFieldDark)}
                size="large"
                disabled
                suffix={
                  <div
                    style={{
                      display: "flex",
                      gap: 12,
                    }}
                  >
                    {passwordWatcher !== hidePassword && (
                      <Tooltip
                        title={copyPasswordStatus ? "Copied" : "Copy"}
                        onOpenChange={() => {
                          setCopyPasswordStatus(false);
                        }}
                        overlayInnerStyle={{
                          fontSize: 12,
                        }}
                      >
                        <div onClick={handleCopyPassword}>
                          <CopyIcon
                            style={{
                              width: 16,
                              height: "100%",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      </Tooltip>
                    )}

                    <div onClick={() => (isLoadingResetPassword ? undefined : handleGeneratePassword())}>
                      <SwapIcon
                        style={{
                          width: 16,
                          height: "100%",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </div>
                }
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={<span className={commonStyles.label}>Role</span>}
              name="role"
              rules={[
                {
                  required: true,
                  message: message.ERROR_NOT_BLANK("Role"),
                },
              ]}
            >
              <Select
                className={commonStyles.selectDark}
                size="large"
                dropdownStyle={dropdownStyle}
                popupClassName="customDropdownSelectAntd"
                placeholder=""
                optionFilterProp="children"
                getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}
              >
                {(roleList || []).map(({ name, id }: any) => (
                  <Select.Option value={id} key={id}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <div
          style={{
            marginTop: 24,
            display: "flex",
            justifyContent: "flex-end",
            gap: 14,
          }}
        >
          <Button
            type="primary"
            size="large"
            onClick={handleCloseDialog}
            style={{
              background: "#8C8888",
            }}
          >
            Cancel
          </Button>
          <Button
            htmlType="submit"
            type="primary"
            size="large"
            style={{
              width: 180,
              fontWeight: 600,
            }}
            disabled={isLoadingCreateUser || isLoadingUpdateUser}
          >
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default DialogUserCUR;
