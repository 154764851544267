import { Col, Row } from "antd";
import LogoIcon from "@/assets/icons/Logo";

interface IFilterComponentProps {
  title: string;
}

function FilterComponent({ title }: IFilterComponentProps) {
  return (
    <Row
      justify="space-between"
      align="middle"
      style={{
        paddingRight: 40,
        height: "100%",
      }}
    >
      <Col
        flex={1}
        style={{
          height: "100%",
        }}
      >
        <div
          style={{
            height: "100%",
            display: "flex",
            gap: 10,
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 16,
              width: 234,
              height: "100%",
              padding: "0 16px",
            }}
          >
            <LogoIcon />
            <span
              style={{
                fontSize: 14,
                color: "#234C66",
                fontWeight: 700,
              }}
            >
              {title}
            </span>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default FilterComponent;
