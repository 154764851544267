import { IconProps } from "@/utils/types";

function BellIcon({ color = "#FDFDFD", style }: IconProps) {
  return (
    <svg
      width="18"
      height="21"
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M16.8967 14.6394C15.6007 13.544 14.8576 11.9429 14.8576 10.2466V7.85686C14.8576 4.84058 12.617 2.34372 9.7147 1.92544V0.999721C9.7147 0.525721 9.3307 0.142578 8.85756 0.142578C8.38442 0.142578 8.00042 0.525721 8.00042 0.999721V1.92544C5.09728 2.34372 2.85756 4.84058 2.85756 7.85686V10.2466C2.85756 11.9429 2.11442 13.544 0.810704 14.6463C0.477276 14.9317 0.286133 15.3466 0.286133 15.7854C0.286133 16.6126 0.95899 17.2854 1.78613 17.2854H15.929C16.7561 17.2854 17.429 16.6126 17.429 15.7854C17.429 15.3466 17.2378 14.9317 16.8967 14.6394Z"
        fill={color}
      />
      <path
        d="M8.85764 20.714C10.4099 20.714 11.7085 19.6074 12.0068 18.1426H5.7085C6.00678 19.6074 7.30535 20.714 8.85764 20.714Z"
        fill={color}
      />
    </svg>
  );
}
export default BellIcon;
