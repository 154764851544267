import { Select, Spin } from "antd";
import { useEffect, useState } from "react";
import useDebounce from "@/hooks/useDebounce";
import { BaseOptionType } from "antd/es/select";
import { useGetProductListQuery } from "@/rtk-query/productQueries";
import { IProductOrder, ProductType } from "@/utils/types";

interface IProductCodeDropdownListProps {
  value?: string | number | null;
  onChange: (data: ProductType | null) => void;
  disabled?: boolean;
  filter?: any;
  search?: string;
  className?: string;
  skip?: boolean;
  listHide?: IProductOrder[];
}

const ProductCodeDropdownList = ({
  value,
  onChange,
  disabled,
  filter = {},
  search = "",
  className,
  skip = false,
  listHide = [],
}: IProductCodeDropdownListProps) => {
  const [page, setPage] = useState(1);
  const [searchProduct, setSearchProduct] = useState("");
  const searchProductDebounce = useDebounce(searchProduct, 300);
  const [data, setData] = useState<any[]>([]);

  const { currentData: productList, isFetching: isFetchingProduct } = useGetProductListQuery(
    {
      ...filter,
      page,
      pageSize: 30,
      search: searchProductDebounce,
    },
    { skip }
  );

  useEffect(() => {
    setSearchProduct(search);
  }, [search]);

  useEffect(() => {
    if (isFetchingProduct || !productList) return;
    page === 1 ? setData(productList?.data || []) : setData([...data, ...(productList?.data || [])]);
  }, [isFetchingProduct, productList]);

  // Scroll infinite =========
  const onScroll = async (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    if (!isFetchingProduct && event.target.scrollTop + event.target.offsetHeight === event.target.scrollHeight && (productList?.totalPage || 1) > page) {
      setPage(page + 1);
    }
  };
  // ================

  return (
    <Select
      showSearch
      popupClassName="customDropdownSelectAntd"
      className={className}
      size="middle"
      loading={isFetchingProduct}
      value={value}
      onSearch={(value) => {
        setSearchProduct(value);
        setPage(1);
      }}
      onChange={(_, children: BaseOptionType) => {
        onChange(data?.find((e) => e.id === children?.value) || null);
      }}
      onBlur={() => {
        setSearchProduct("");
        setPage(1);
      }}
      allowClear
      onClear={() => onChange(null)}
      disabled={disabled}
      getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}
      filterOption={false}
      onPopupScroll={onScroll}
    >
      {data.map(({ productName, id }: any, index: number) => {
        if (listHide.find((e) => e.productId === id)) {
          return null;
        }
        return (
          <Select.Option value={id} key={index}>
            {productName}
          </Select.Option>
        );
      })}
      {isFetchingProduct && (
        <Select.Option value="load_more">
          <div className="center-element">
            <Spin />
          </div>
        </Select.Option>
      )}
    </Select>
  );
};

export default ProductCodeDropdownList;
