import React from "react";
import { Button, Result } from "antd";
import { useHistory } from "react-router";
import PageLayout from "@/components/layouts/PageLayout";
import routes from "@/routes";

const Custom500Page: React.FC = () => {
  const history = useHistory();
  return (
    <PageLayout title={routes[500].title}>
      <Result
        status="500"
        title="500"
        subTitle="Sorry, something went wrong."
        extra={
          <Button type="primary" onClick={() => history.goBack()}>
            Back Home
          </Button>
        }
      />
    </PageLayout>
  );
};

export default Custom500Page;
