import { Row, Spin } from "antd";
import { useEffect } from "react";
import routes from "@/routes";
import baseApi from "@/rtk-query";
import ApiServices from "@/utils/api";

function LogoutPage() {
  const handleLogout = async () => {
    const apiAuth = new ApiServices();
    await apiAuth.logout();
    localStorage.clear();
    baseApi.util.resetApiState();
    window.location.href = routes.login.path;
  };

  useEffect(() => {
    handleLogout();
  }, []);

  return (
    <Row
      justify="center"
      align="middle"
      style={{
        height: "100vh",
      }}
    >
      <Spin tip="Loading" size="large" />
    </Row>
  );
}

export default LogoutPage;
