import { SearchOutlined } from "@ant-design/icons";
import { Row, Input, Col } from "antd";
import styles from "../../masterProductManagement.module.less";

interface ISearchAreaProps {
  value: string;
  onChange: (value: string) => void;
}

const SearchArea = ({ value, onChange }: ISearchAreaProps) => {
  return (
    <div className={styles.searchArea}>
      <Row justify="end" align="middle" gutter={8}>
        <Col>
          <Input
            placeholder="Search product..."
            suffix={<SearchOutlined style={{ color: "#9DA1B4", fontSize: 18 }} />}
            size="large"
            className={styles.productSearch}
            value={value}
            onChange={(event) => onChange(event?.target?.value)}
          />
        </Col>
      </Row>
    </div>
  );
};

export default SearchArea;
