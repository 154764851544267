import { IconProps } from "@/utils/types";

function MultiBoxIcon({ color = "#8491A6", style }: IconProps) {
  return (
    <svg width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path d="M20.6472 13.2354L14.2943 15.353L20.6472 17.4706L27.0001 15.353L20.6472 13.2354Z" fill={color} />
      <path d="M0 15.353L6.3529 17.4706L12.7058 15.353L6.3529 13.2354L0 15.353Z" fill={color} />
      <path
        d="M13.7511 0.0407379C13.5881 -0.0135793 13.4118 -0.0135793 13.2488 0.0407379L7.14709 2.07467L13.5 4.1923L19.8529 2.07467L13.7511 0.0407379Z"
        fill={color}
      />
      <path d="M7.14709 27.1061L12.7059 25.2531V17.0273L7.14709 18.8803V27.1061Z" fill={color} />
      <path d="M0 24.6808C0 25.0226 0.218698 25.3261 0.543014 25.4341L5.55878 27.1061V18.8803L0 17.0273V24.6808Z" fill={color} />
      <path d="M14.2943 25.2531L19.8531 27.1061V18.8803L14.2943 17.0273V25.2531Z" fill={color} />
      <path d="M21.4409 27.1061L26.4567 25.4341C26.781 25.326 26.9997 25.0226 26.9997 24.6808V17.0273L21.4409 18.8803V27.1061Z" fill={color} />
      <path d="M7.14709 12.1227L12.7059 13.9756V5.60103L7.14709 3.74805V12.1227Z" fill={color} />
      <path d="M19.8531 12.1227V3.74805L14.2943 5.60103V13.9756L19.8531 12.1227Z" fill={color} />
    </svg>
  );
}
export default MultiBoxIcon;
