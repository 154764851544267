import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IinitialState {
  fileIdImporting: string | number | null;
  fileIdLastImported: string | number | null;
}

const initialState: IinitialState = {
  fileIdImporting: null,
  fileIdLastImported: null,
};

export const masterProductManagementSlide = createSlice({
  name: "masterProductManagementSlide",
  initialState,
  reducers: {
    updateFileIdImporting(
      state,
      action: PayloadAction<string | number | null>
    ) {
      state.fileIdImporting = action.payload;
      if (!action.payload) return;
      state.fileIdLastImported = action.payload;
    },
    updateFileIdLastImported(
      state,
      action: PayloadAction<string | number | null>
    ) {
      state.fileIdLastImported = action.payload;
    },
  },
});

export const masterProductManagementReducer =
  masterProductManagementSlide.reducer;
