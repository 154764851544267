import React from "react";
import { Button, Result } from "antd";
import { useHistory } from "react-router";
import PageLayout from "@/components/layouts/PageLayout";
import routes from "@/routes";

const Custom404Page: React.FC = () => {
  const history = useHistory();
  return (
    <PageLayout title={routes[404].title}>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button type="primary" onClick={() => history.goBack()}>
            Back Home
          </Button>
        }
      />
    </PageLayout>
  );
};

export default Custom404Page;
