import TableCustom from "@/components/TableCustom";
import ContentWrapper from "@/components/layouts/DashboardLayout/Content";
import { columns } from "./warehouseManagement.constants";
import routes from "@/routes";
import WareHouseIcon from "@/assets/icons/WareHouse";
import FilterComponent from "./components/FilterComponent";
import { useDeleteWarehouseMutation, useGetWarehouseListQuery, useRestoreWarehouseMutation } from "@/rtk-query/warehouseQueries";
import { Toast, useTypedDispatch, useTypedSelector } from "@/app/store";
import { useGetUserInfoQuery } from "@/rtk-query/userQueries";
import { CompanySelectType, warehouseManagementSlide } from "./warehouseManagement.slice";
import { useEffect, useRef, useState } from "react";
import useIntersectionObserver from "@/hooks/useIntersectionObserver";
import { Row, Spin, notification } from "antd";
import C from "@/utils/constants";
import { checkTotalAllItem, getError } from "@/utils/helpers";
import message from "@/utils/message";

function WarehouseManagementModule() {
  const [data, setData] = useState<Array<any>>([]);
  const [page, setPage] = useState(1);
  const [loadingList, setLoadingList] = useState<Array<string | number>>([]);

  const { companySelected, warehouseIdSelected } = useTypedSelector((store) => store.warehouseManagementSlide);
  const { currentData, isFetching, isLoading } = useGetWarehouseListQuery({
    page,
    companyId: companySelected?.id || "",
    pageSize: C.PAGE_SIZE,
    search: warehouseIdSelected || "",
    isLoading: undefined,
  });
  const { currentData: userInfo } = useGetUserInfoQuery();
  const [deleteWarehouseApi] = useDeleteWarehouseMutation();
  const [restoreWarehouseApi] = useRestoreWarehouseMutation();

  const dispatch = useTypedDispatch();
  const { onSelectCompany, onSelectWarehouse } = warehouseManagementSlide.actions;

  const handleSelectCompany = (value: CompanySelectType) => {
    dispatch(onSelectCompany(value));
    setPage(1);
  };

  const handleSelectWarehouse = (value: string | number | null) => {
    dispatch(onSelectWarehouse(value));
    setPage(1);
  };

  const handleLoaded = (id: string | number) => {
    setTimeout(() => {
      setLoadingList(loadingList.filter((item) => item !== id));
    }, 300);
  };

  const handleDeleteRestore = async (id: string | number, type?: "delete" | "restore") => {
    setLoadingList([...loadingList, id]);
    Toast.close();

    try {
      type === "delete" ? await deleteWarehouseApi(id).unwrap() : await restoreWarehouseApi(id).unwrap();
      notification.success({
        key: "warehouse_del",
        message: message.SUCCESSFULLY,
      });
      handleLoaded(id);
      setPage(1);
    } catch (error) {
      notification.error({
        key: "warehouse_del",
        message: getError(error),
      });
      handleLoaded(id);
    }
  };

  const handleDeletePopup = (id: string | number) => {
    Toast.delete({
      isOpen: true,
      title: "Are you sure you want to delete this warehouse?",
      description: "You will not able to view and edit this warehouse’s information.",
      cancelText: "Cancel",
      okText: "Delete",
      onCancel: () => {
        Toast.close();
      },
      onOk: () => handleDeleteRestore(id, "delete"),
      okStyle: {
        background: "#F32121",
      },
    });
  };

  useEffect(() => {
    if (!companySelected) {
      const selectCompany: any = {
        id: userInfo?.company?.id,
        companyName: userInfo?.company?.companyName,
        address: userInfo?.company?.address,
      };
      handleSelectCompany(selectCompany);
    }
  }, [userInfo, companySelected]);

  // Intersection Observer
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {});
  const isVisible = !!entry?.isIntersecting;

  useEffect(() => {
    setData(page === 1 ? currentData?.data || [] : [...data, ...(currentData?.data || [])]);
  }, [currentData, isFetching, page]);

  useEffect(() => {
    if (isVisible) {
      setPage((currentData?.page || 0) + 1);
    }
  }, [isVisible]);

  return (
    <ContentWrapper
      title="LIST OF WAREHOUSES"
      dataBreadCrumb={[
        {
          title: routes.warehouseList.title,
          icon: <WareHouseIcon />,
          url: routes.warehouseList.path,
        },
      ]}
      renderFilter={<FilterComponent type="list" onSelectCompany={handleSelectCompany} onSelectWarehouseId={handleSelectWarehouse} />}
    >
      <TableCustom
        data={data || []}
        columns={columns({
          onDelete: handleDeletePopup,
          onRestore: (id) => handleDeleteRestore(id, "restore"),
          loadingList,
        })}
        total={checkTotalAllItem({
          page,
          companyId: companySelected?.id || "",
          search: warehouseIdSelected || "",
        })}
        loading={isLoading}
      />
      {currentData && Number(currentData?.page) < Number(currentData?.totalPage) && (
        <Row
          ref={ref}
          justify="center"
          style={{
            padding: 36,
          }}
        >
          <Spin tip="Loading" size="large" />
        </Row>
      )}
    </ContentWrapper>
  );
}

export default WarehouseManagementModule;
