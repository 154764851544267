import { RightOutlined } from "@ant-design/icons";
import { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import { BreadcrumbItemType } from "../layouts/DashboardLayout/Header/header.types";

interface IBreadCrumbComponentProps {
  data: Array<BreadcrumbItemType>;
}

function BreadCrumbComponent({ data }: IBreadCrumbComponentProps) {
  const location = useLocation();

  const breadcrumbSize = data.length;
  const checkShowNextIcon = (index: number) =>
    breadcrumbSize > 1 && index < breadcrumbSize - 1;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: 8,
      }}
    >
      {data.map(({ icon, title, style, url }, index) => (
        <Fragment key={index}>
          <Link
            to={
              index < (data || []).length - 1 ? url || "/" : location.pathname
            }
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              gap: 10,
              color: "#8491A6",
              fontWeight: index < (data || []).length - 1 ? 500 : 800,
              ...style,
            }}
          >
            {icon}
            <span
              style={{
                fontSize: 16,
              }}
            >
              {title}
            </span>
          </Link>
          {checkShowNextIcon(index) && (
            <div
              style={{
                fontSize: 10,
                color: "#8491A6",
              }}
            >
              <RightOutlined />
            </div>
          )}
        </Fragment>
      ))}
    </div>
  );
}

export default BreadCrumbComponent;
