import { InputNumber, Space } from "antd";
import type { ColumnsType } from "antd/es/table";
import { IProductOrder } from "@/utils/types";
import QRCode from "qrcode.react";
import DeleteIcon from "@/assets/icons/DeleteIcon";
import styles from "./orderCUR.module.less";
import { handleCheckNumberKey } from "../SiteProductCUR";

export const columns = ({
  isEdit = false,
  orderStatus = 1,
  onDelete,
  onUpdate,
}: {
  isEdit?: boolean;
  orderStatus?: number;
  onDelete: (ix: number) => void;
  onUpdate: (product: IProductOrder) => void;
}) =>
  [
    {
      title: "Manufacture item no",
      dataIndex: "manufactureItemNo",
      key: "manufactureItemNo",
      width: 150,
      render: (text, record) => (
        <span
          style={{
            color: "#000000E0",
            maxWidth: 150,
            display: "block",
          }}
        >
          {record?.manufactureItemNumber}
        </span>
      ),
    },
    {
      title: "Product id",
      dataIndex: "productCode",
      key: "productCode",
      width: 100,
      render: (text) => (
        <span
          style={{
            color: "#000000E0",
            maxWidth: 100,
            display: "block",
          }}
        >
          {text || ""}
        </span>
      ),
    },
    {
      title: "Product name",
      dataIndex: "name",
      key: "name",
      width: 100,
      render: (text, record) => (
        <span
          style={{
            color: "#000000E0",
            maxWidth: 100,
            display: "block",
          }}
        >
          {record?.productName}
        </span>
      ),
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      width: 100,
      render: (text, record) => (
        <span
          style={{
            color: "#000000E0",
            maxWidth: 100,
            display: "block",
          }}
        >
          {record?.companyName}
        </span>
      ),
    },
    {
      title: "Warehouse code",
      dataIndex: "wareHouseId",
      key: "wareHouseId",
      width: 100,
      render: (text, record) => (
        <span
          style={{
            color: "#000000E0",
            maxWidth: 100,
            display: "block",
          }}
        >
          {record?.warehouseCode}
        </span>
      ),
    },
    {
      title: "Bin Code",
      dataIndex: "binCode",
      key: "binCode",
      width: 100,
      render: (text, record) => (
        <span
          style={{
            color: "#000000E0",
            maxWidth: 100,
            display: "block",
          }}
        >
          {record.binCode}
        </span>
      ),
    },
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
      width: 100,
      render: (text, record) => (
        <span
          style={{
            color: "#000000E0",
            maxWidth: 100,
            display: "block",
          }}
        >
          {record.position}
        </span>
      ),
    },
    {
      title: "QR code",
      dataIndex: "qrCode",
      key: "qrCode",
      width: 50,
      render: (t, record) => (
        <>
          <QRCode
            style={{ width: 50, height: 50 }}
            value={JSON.stringify({
              productId: record.productId,
              companyId: record.companyId,
            })}
          />
        </>
      ),
    },
    {
      title: "Ordered quantity",
      dataIndex: "orQ",
      key: "orQ",
      width: 107,
      render: (text, record) => {
        return (
          <InputNumber
            disabled={!isEdit || Number(orderStatus) > 2}
            className={styles.inputOrderQuantity}
            value={record.orderQuantity || 0}
            onChange={(e) => onUpdate({ ...record, orderQuantity: e || 0 })}
            min={(record?.minOrderQuantity as any) || 0}
            type="number"
            onKeyDown={handleCheckNumberKey}
          />
        );
      },
    },
    {
      title: "Received quantity",
      dataIndex: "minQ",
      key: "minQ",
      width: 100,
      render: (text, record) => (
        <span
          style={{
            color: "#000000E0",
            maxWidth: 100,
            display: "block",
          }}
        >
          {Number.isInteger(record.receivedQuantity) && Number(record.receivedQuantity) >= 0 ? record.receivedQuantity : "-"}
        </span>
      ),
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
      width: 70,
      render: (text, record) => (
        <span
          style={{
            color: "#000000E0",
            maxWidth: 70,
            display: "block",
          }}
        >
          {record.unit}
        </span>
      ),
    },
    {
      title: "Functions",
      key: "action",
      width: 150,
      render: (_, e, ix) => {
        if (!isEdit || Number(orderStatus) > 2) return null;
        return (
          <Space
            size="middle"
            style={{
              fontSize: 12,
            }}
          >
            <div
              onClick={() => {
                onDelete(ix);
              }}
            >
              <DeleteIcon style={{ width: 16, cursor: "pointer" }} />
            </div>
          </Space>
        );
      },
    },
  ] as ColumnsType<IProductOrder>;
