import OrderIcon from "@/assets/icons/OrderIcon";
import ContentWrapper from "@/components/layouts/DashboardLayout/Content";
import routes from "@/routes";
import FilterComponent from "../OrderManagement/components/FilterComponent";
import CardRecord from "./components/CardRecord";
import { useGetOrderHistoryListQuery } from "@/rtk-query/orderQueries";
import { useParams } from "react-router";
import EmptyWrapper from "@/components/EmptyCustom";

const RecordManagementModule = () => {
  const params: any = useParams();
  const { currentData } = useGetOrderHistoryListQuery(
    {
      orderId: params?.id,
    },
    {
      skip: !params?.id,
    }
  );

  return (
    <ContentWrapper
      title="LIST OF RECORD"
      dataBreadCrumb={[
        {
          title: routes.orderList.title,
          icon: <OrderIcon />,
          url: routes.orderList.path,
        },
        {
          title: routes.orderRecordList.title,
          url: routes.orderRecordList.path,
        },
      ]}
      renderFilter={<FilterComponent type="view" />}
      hideTitle
    >
      <EmptyWrapper data={currentData || []} filter={{}}>
        <div
          style={{
            maxWidth: 704,
            display: "flex",
            flexDirection: "column",
            gap: 20,
          }}
        >
          {(currentData || []).map((item, index) => (
            <CardRecord
              key={index}
              id={params?.id}
              data={item}
              version={(currentData || []).length - index}
            />
          ))}
        </div>
      </EmptyWrapper>
    </ContentWrapper>
  );
};

export default RecordManagementModule;
