import { Col, Row } from "antd";
import { useHistory } from "react-router";
import PrevIcon from "@/assets/icons/PrevIcon";

interface IFilterComponentProps {
  title: string;
}

function FilterComponent({ title }: IFilterComponentProps) {
  const history = useHistory();

  return (
    <Row
      justify="space-between"
      align="middle"
      style={{
        paddingRight: 40,
        height: "100%",
      }}
    >
      <Col
        flex={1}
        style={{
          height: "100%",
        }}
      >
        <div
          style={{
            height: "100%",
            display: "flex",
            gap: 10,
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 16,
              width: 234,
              height: "100%",
            }}
          >
            <div
              className="center-element"
              style={{
                width: 42,
                background: "#D3D7E1",
                height: "100%",
                cursor: "pointer",
              }}
              onClick={() => history.goBack()}
            >
              <PrevIcon />
            </div>
            <span
              style={{
                fontSize: 14,
                color: "#234C66",
                fontWeight: 700,
              }}
            >
              {title}
            </span>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default FilterComponent;
