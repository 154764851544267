import ContentWrapper from "@/components/layouts/DashboardLayout/Content";
import routes from "@/routes";
import { Fragment, useEffect, useState } from "react";
import { Col, Input, Row, Select, Tooltip } from "antd";
import C from "@/utils/constants";
import { IProductOrder, WarehouseType } from "@/utils/types";
import TagIcon from "@/assets/icons/TagIcon";
import styles from "./orderCUR.module.less";
import { CompanySelectType } from "../WarehouseManagement/warehouseManagement.slice";
import WarehouseCodeDropdownList from "@/components/FilterSelectCustom/WarehouseCodeDropdownList";
import clsx from "clsx";
import commonStyles from "@/styles/common.module.less";
import FilterComponent from "./components/FilterComponent";
import ProductList from "./components/ProductList";
import { useGetWarningProductListWarehouseQuery } from "@/rtk-query/productQueries";
import { useGetOrderDetailQuery, useGetOrderHistoryDetailQuery } from "@/rtk-query/orderQueries";
import { useHistory, useParams } from "react-router";
import dayjs from "dayjs";
import { useGetUserInfoQuery } from "@/rtk-query/userQueries";
import { useTypedDispatch, useTypedSelector } from "@/app/store";
import { quickOrderSlide } from "../QuickOrder/quickOrder.slice";
import { renderFieldViewOnly } from "@/utils/helpers";

interface IOrderCUR {
  type?: "view" | "full" | "create" | "edit";
}
function OrderCURModule({ type = "view" }: IOrderCUR) {
  const params: any = useParams();
  const history = useHistory();

  const isFull = ["full"].includes(type);
  const isEdit = ["edit"].includes(type);
  const isCreate = ["full", "create"].includes(type);
  const isViewOnly = ["view"].includes(type);

  const [searchWarehouse, setSearchWarehouse] = useState("");

  const [orderStatus, setOrderStatus] = useState<any>(1);

  const [customerPurchase, setCustomerPurchase] = useState("");

  const { companySelected, wareHouseSelected } = useTypedSelector((store) => store.quickOrderSlide);

  const { currentData: dataOrderCurrent, isFetching: isFetchingDataCurrent } = useGetOrderDetailQuery(params?.id, {
    skip: !params?.id || !!params?.recordId,
  });

  const { currentData: dataHistoryOrder, isFetching: isFetchingDataHistory } = useGetOrderHistoryDetailQuery(params?.recordId, {
    skip: !params?.recordId,
  });

  const dataOrder = dataHistoryOrder || dataOrderCurrent;

  const isFetching = isFetchingDataHistory || isFetchingDataCurrent;

  const [listWarningConverted, setListWarningConverted] = useState<IProductOrder[]>([]);

  const { currentData: listWarning, isFetching: isFetchingListWarning } = useGetWarningProductListWarehouseQuery(
    {
      companyId: companySelected?.id || "",
      warehouseId: wareHouseSelected?.id,
    },
    {
      skip: !companySelected?.id || !wareHouseSelected?.id || !isFull,
    }
  );

  useEffect(() => {
    setListWarningConverted(
      dataOrder?.products.map((item: any) => ({
        ...item,
        minOrderQuantity: item?.minimumOrderQuantity || 0,
      })) ||
        listWarning?.map((prod) => {
          const defaultOrderedQuantity = Math.max(+(prod?.minimumQuantity || 0) - +(prod?.inventory || 0), prod?.masterProduct?.minimumQuantity || 0);

          return {
            id: prod.id,
            productId: prod.productId,
            productCode: prod.productCode,
            productName: prod.productName,
            position: prod.position,
            unit: prod.unit,
            binCode: prod.bin.binCode,
            orderQuantity: defaultOrderedQuantity,
            receivedQuantity: null,
            warehouseCode: prod.bin.warehouse?.identityCode,
            companyName: prod.bin.warehouse?.company.companyName || "",
            companyId: prod.bin.warehouse?.company.id || "",
            address: prod.bin.warehouse?.address || "",
            minOrderQuantity: prod?.masterProduct?.minimumQuantity || 0,
            manufactureItemNumber: prod?.masterProduct?.manufactureItemNumber,
          };
        }) ||
        []
    );
  }, [dataOrder, listWarning, companySelected, wareHouseSelected]);

  const [notes, setNotes] = useState<string[]>([]);

  const handleNote = (ix?: number, value?: string, remove?: boolean) => {
    const notesClone = [...notes];
    if (remove && ix?.toString().length) {
      notesClone.splice(ix, 1);
      setNotes(notesClone);
      return;
    }
    if (ix?.toString().length && ix >= 0) {
      notesClone[ix] = value || "";
      setNotes(notesClone);
      return;
    }
    notesClone.push("");
    setNotes(notesClone);
  };

  const { currentData: userInfo } = useGetUserInfoQuery();

  const dispatch = useTypedDispatch();

  const { onSelectCompany, onSelectWareHouse } = quickOrderSlide.actions;

  const handleSelectCompany = (value: CompanySelectType) => {
    dispatch(onSelectCompany(value));
  };

  const handleOnSelectWareHouse = (value: WarehouseType | null) => {
    dispatch(onSelectWareHouse(value));
  };

  const dropdownStyle = {
    background: "#dfe3ef",
    padding: 0,
    borderRadius: 0,
    boxShadow: "inset 4px 4px 3px rgba(0, 0, 0, 0.03)",
    fontSize: 12,
    fontWeight: 600,
  };

  useEffect(() => {
    setOrderStatus(Number(dataOrder?.status) || 1);
    setNotes((dataOrder?.notes || []).map(({ note }: any) => note));
    setCustomerPurchase(dataOrder?.customerPurchaseOrderNumber || "");

    if (dataOrder) {
      const firstProduct = dataOrder?.products?.[0];
      handleSelectCompany({
        companyName: firstProduct?.companyName,
        id: firstProduct?.companyId,
        address: firstProduct?.address,
      });
      handleOnSelectWareHouse({
        id: firstProduct?.warehouseId,
        identityCode: firstProduct?.warehouseCode,
      } as any);
    }
  }, [dataOrder]);

  const getButtonByStatus = () => {
    let txtButton = "";
    // if (+orderStatus === C.ORDER_STATUS.Cancel) return "Re-order";
    switch (+(dataOrder?.status || 0)) {
      case C.ORDER_STATUS["New order"]:
        txtButton = isCreate ? "Save" : "Submit";
        break;
      case C.ORDER_STATUS.Pending:
        txtButton = "Approve";
        break;
      case C.ORDER_STATUS["In progress"]:
        txtButton = "Receive order";
        break;
      case C.ORDER_STATUS.Delivered:
      case C.ORDER_STATUS.Cancel:
        txtButton = "Re-order";
        break;
      default:
        txtButton = "Save";
        break;
    }
    return txtButton;
  };

  const currentStatus = () => {
    let result = {
      status: 0,
      text: "",
    };
    Object.entries(C.ORDER_STATUS).forEach(([key, v]) => {
      if (key === dataOrder?.status)
        result = {
          status: +key,
          text: String(v),
        };
    });
    return result;
  };

  const isShowCancel = () => {
    const listHide = [C.ORDER_STATUS["In progress"], C.ORDER_STATUS.Delivered, C.ORDER_STATUS.Cancel];

    const listStatus = Object.entries(C.ORDER_STATUS).map(([key, v]) => {
      return {
        status: +key,
        text: String(v),
      };
    });
    return !!listStatus.map((status) => !listHide.includes(status.status) && status.status).find((e) => e === orderStatus);
  };

  const getRouteKey = () => {
    if (params?.recordId) return "viewRecord";
    let key = "";
    switch (type) {
      case "full":
        key = "fullOrder";
        break;
      case "create":
        key = "createOrder";
        break;
      case "edit":
        key = "editOrder";
        break;
      default:
        key = "viewOrder";
        break;
    }

    return key;
  };
  
  return (
    <ContentWrapper
      dataBreadCrumb={[
        {
          title: type === "full" ? routes.quickOrderList.title : routes.orderList.title,
          icon: <TagIcon />,
          url: type === "full" ? routes.quickOrderList.path : routes.orderList.path,
        },
        {
          title: routes[getRouteKey()].title,
          url: routes[getRouteKey()].path,
          style: {
            fontWeight: 800,
          },
        },
      ]}
      renderFilter={
        <FilterComponent
          title={routes[getRouteKey()].title}
          companySelected={companySelected}
          warehouseSelected={wareHouseSelected}
          onSelectCompany={handleSelectCompany}
          onSelectWareHouse={handleOnSelectWareHouse}
          dataOrder={dataOrder}
          isCreate={isCreate}
        />
      }
    >
      <div className={styles.wrapForm}>
        <Row>
          <Col span={12}>
            <Row gutter={[20, 30]}>
              <Col span={4} xs={6} className={styles.textRight}>
                Sales Order ID:
              </Col>
              <Col span={20} xs={18}>
                {dataOrder?.id || ""}
              </Col>

              <Col span={4} xs={6} className={styles.textRight}>
                Company:
              </Col>
              <Col span={20} xs={18}>
                {dataOrder?.companyName || companySelected?.companyName || ""}
              </Col>

              <Col span={4} xs={6} className={styles.textRight}>
                Address:
              </Col>
              <Col span={20} xs={18}>
                {dataOrder?.address || companySelected?.address || ""}
              </Col>

              <Col span={4} xs={6} className={styles.textRight}>
                Created by:
              </Col>
              <Col span={20} xs={18}>
                {dataOrder?.createdBy || userInfo?.fullName}
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row gutter={[20, 30]}>
              <Col
                span={4}
                xs={6}
                className={styles.textRight}
                style={{
                  paddingTop: Number(dataOrder?.status) === 2 && isEdit ? 8 : 0,
                }}
              >
                Customer purchase order no.
              </Col>
              <Col span={20} xs={18}>
                {Number(dataOrder?.status) === 2 && isEdit ? (
                  <Input
                    size="middle"
                    style={{ borderRadius: 0, height: 32 }}
                    value={customerPurchase}
                    onChange={(event) => setCustomerPurchase(event.target.value)}
                  />
                ) : (
                  dataOrder?.customerPurchaseOrderNumber || ""
                )}
              </Col>

              <Col span={4} xs={6} className={styles.textRight} style={{ marginTop: isCreate ? 8 : 0 }}>
                Warehouse code:
              </Col>
              <Col span={20} xs={18}>
                {isCreate ? (
                  <WarehouseCodeDropdownList
                    placeholder={wareHouseSelected?.identityCode}
                    value={wareHouseSelected?.identityCode || null}
                    search={searchWarehouse}
                    onChange={() => {
                      setSearchWarehouse("");
                    }}
                    onChangeWareHouse={handleOnSelectWareHouse}
                    filter={{
                      companyId: companySelected?.id,
                      ignoreDeleted: true,
                    }}
                    className={styles.dropdown}
                    size="middle"
                    style={{
                      width: "100%",
                    }}
                    skipByCompany={!companySelected?.id}
                  />
                ) : (
                  dataOrder?.products?.[0]?.warehouseCode || ""
                )}
              </Col>
              <Col span={4} xs={6} className={styles.textRight} style={{ marginTop: 0 }}>
                Job reference:
              </Col>
              <Col span={20} xs={18}>
              {isCreate ? renderFieldViewOnly(wareHouseSelected?.jobReference || "", {
                    color: '#f36f21',
                  }) :
                  (renderFieldViewOnly(dataOrder?.products?.[0]?.jobReference || "", {
                    color: '#f36f21',
                  }))
              }
              </Col>

              <Col span={4} xs={6} className={styles.textRight}>
                Order date:
              </Col>
              <Col span={20} xs={18}>
                {dayjs(dataOrder?.createdAt).format("DD/MM/YY")}
              </Col>
            </Row>
          </Col>
        </Row>

        {!params?.recordId && (isEdit || isViewOnly) && (
          <div className={styles.viewLastBtn} onClick={() => history.push(routes.orderRecordList.path.replace(":id", dataOrder?.id))}>
            View record &#62;&#62;
          </div>
        )}
      </div>
      <div className={styles.wrapAdd}>
        <Row align="middle" gutter={[16, 0]}>
          <Col span={12}>
            <Row gutter={[20, 30]} align="middle">
              <Col span={4} xs={6} className={styles.textRight}>
                Status:
              </Col>
              <Col span={20} xs={18}>
                <div className={styles.statusWrapper}>
                  <Select
                    className={clsx(commonStyles.selectDark, styles.dropdownStatus)}
                    size="large"
                    dropdownStyle={dropdownStyle}
                    popupClassName="customDropdownSelectAntd"
                    placeholder=""
                    optionFilterProp="children"
                    getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}
                    suffixIcon={null}
                    value={C.ORDER_STATUS[orderStatus]}
                    onChange={(value) => setOrderStatus(value)}
                    disabled={isCreate || isViewOnly || Number(dataOrder?.status) > 2}
                  >
                    <Select.Option value={currentStatus().status}>{currentStatus().text}</Select.Option>
                    {isShowCancel() && <Select.Option value={C.ORDER_STATUS.Cancel}>Cancel</Select.Option>}
                  </Select>
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            {(isEdit || isCreate) && Number(dataOrder?.status || orderStatus) <= 2 && !notes.length && (
              <div className={clsx(styles.flexGap, styles.wrapButtonAdd)} onClick={() => handleNote()}>
                <div className={styles.buttonAdd}>+</div>
                <span className={styles.textAdd}> Add notes</span>
              </div>
            )}
          </Col>
          {notes.map((note, i) => (
            <Fragment key={i}>
              <Col span={12}>
                <Row gutter={[20, 30]} align="middle">
                  <Col span={4} xs={6} className={styles.textRight}>
                    {i === 0 && "Notes:"}
                  </Col>
                  <Col span={20} xs={18}>
                    <div className={styles.statusWrapper}>
                      <Tooltip mouseLeaveDelay={0} title={isViewOnly || (Number(dataOrder?.status) > 2 && note.trim()) ? note.trim() : ""} trigger={["hover"]}>
                        <Input
                          className={styles.statusInput}
                          value={note}
                          onChange={(e) => {
                            if (isViewOnly || Number(dataOrder?.status) > 2) return;
                            handleNote(i, e.target.value);
                          }}
                        />
                      </Tooltip>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col
                span={12}
                style={{
                  visibility: isViewOnly || Number(dataOrder?.status) > 2 ? "hidden" : "unset",
                }}
              >
                {(isEdit || isFull || isCreate) && notes.length < 3 ? (
                  <div className={clsx(styles.flexGap, styles.wrapButtonAdd)}>
                    <div onClick={() => handleNote()} className={styles.buttonAdd}>
                      +
                    </div>

                    <div className={clsx(styles.flexGap, styles.wrapButtonAdd)} onClick={() => handleNote(i, "", true)}>
                      <div className={styles.buttonAdd}>-</div>
                    </div>
                  </div>
                ) : (
                  <div className={clsx(styles.flexGap, styles.wrapButtonAdd)} onClick={() => handleNote(i, "", true)}>
                    <div className={styles.buttonAdd}>-</div>
                  </div>
                )}
              </Col>
            </Fragment>
          ))}
        </Row>
      </div>
      <ProductList
        isViewOnly={isViewOnly}
        isEdit={isEdit}
        isCreate={isCreate}
        companySelectedId={companySelected?.id as any}
        wareHouseSelectedId={wareHouseSelected?.id || dataOrder?.products?.[0]?.warehouseId}
        dataOrder={listWarningConverted}
        setDataOrder={setListWarningConverted}
        isFetching={isFetching || isFetchingListWarning}
        notes={notes}
        isFull={isFull}
        textSubmit={getButtonByStatus()}
        orderDetail={dataOrder}
        orderStatus={orderStatus}
        customerPurchase={customerPurchase}
      />
    </ContentWrapper>
  );
}

export default OrderCURModule;
