import { IconProps } from "@/utils/types";

function LogoIcon({ color = "#8491A6", style }: IconProps) {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <rect y="12" width="11.6667" height="3" fill={color} />
      <path d="M11.6665 12L14.9998 10V13L11.6665 15V12Z" fill={color} />
      <rect y="7" width="11.6667" height="3" fill={color} />
      <path d="M11.6665 7L14.9998 5V8L11.6665 10V7Z" fill={color} />
      <rect y="2" width="11.6667" height="3" fill={color} />
      <path d="M11.6665 2L14.9998 0V3L11.6665 5V2Z" fill={color} />
    </svg>
  );
}
export default LogoIcon;
