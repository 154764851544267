import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IinitialState {
  isOpen: boolean;
  id?: string | number | null;
  data?: any[];
}

const initialState: IinitialState = {
  isOpen: false,
  id: null,
  data: [],
};

export const dialogReceiveSlide = createSlice({
  name: "dialogReceiveSlide",
  initialState,
  reducers: {
    updateReceiveOrderState(state, action: PayloadAction<IinitialState>) {
      state.isOpen = action.payload.isOpen;
      state.id = action.payload.id || null;
      state.data =
        action.payload.data?.map((m) => ({
          ...m,
          receivedQuantity: m.receivedQuantity
            ? m.receivedQuantity
            : m.orderQuantity,
        })) || [];
    },
  },
});

export const dialogReceiveReducer = dialogReceiveSlide.reducer;
