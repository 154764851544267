const queryTagTypes = {
  USER_INFO_QUERY_TAG: "USER_INFO_QUERY_TAG",
  COMPANY_QUERY_TAG: "COMPANY_QUERY_TAG",
  USER_QUERY_TAG: "USER_QUERY_TAG",
  WAREHOUSE_QUERY_TAG: "WAREHOUSE_QUERY_TAG",
  BIN_QUERY_TAG: "BIN_QUERY_TAG",
  PRODUCT_QUERY_TAG: "PRODUCT_QUERY_TAG",
  MASTER_PRODUCT_QUERY_TAG: "MASTER_PRODUCT_QUERY_TAG",
  IMPORT_MASTER_PRODUCT_QUERY_TAG: "IMPORT_MASTER_PRODUCT_QUERY_TAG",
  ORDER_QUERY_TAG: "ORDER_QUERY_TAG",
  NOTI_QUERY_TAG: "NOTI_QUERY_TAG",
};

export default queryTagTypes;
