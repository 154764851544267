import { Space } from "antd";
import type { ColumnsType } from "antd/es/table";
import { Link } from "react-router-dom";
import DeleteIcon from "@/assets/icons/DeleteIcon";
import EditIcon from "@/assets/icons/EditIcon";
import routes from "@/routes";
import { ProductType } from "@/utils/types";
import QRCode from "qrcode.react";
import NextIcon from "@/assets/icons/NextIcon";

export const columns = ({
  onDelete,
  onRestore,
  loadingList,
  titleBinCode,
  titlePosition,
}: {
  onDelete?: (id: string | number) => void;
  onRestore?: (id: string | number) => void;
  loadingList?: Array<string | number>;
  titleBinCode?: JSX.Element;
  titlePosition?: JSX.Element;
}) =>
  [
    {
      title: "Stocktake’s Product ID",
      width: "8.5%",
      render: (_, record) => (
        <span
          style={{
            color: record?.deletedAt ? "#5B5959" : "#000000E0",
            display: "block",
          }}
        >
          {record?.masterProduct?.id}
        </span>
      ),
    },
    {
      title: "Manufacture product no",
      dataIndex: "identityCode",
      key: "identityCode",
      width: "9%",
      render: (text, record) => (
        <span
          style={{
            color: record?.deletedAt ? "#5B5959" : "#000000E0",
            maxWidth: 100,
            display: "block",
          }}
        >
          {record?.masterProduct?.manufactureItemNumber}
        </span>
      ),
    },
    {
      title: "Product name",
      dataIndex: "name",
      key: "name",
      width: "9%",
      render: (text, record) => (
        <span
          style={{
            color: record?.deletedAt ? "#5B5959" : "#000000E0",
            maxWidth: 100,
            display: "block",
          }}
        >
          {record?.productName}
        </span>
      ),
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      width: "7.5%",
      render: (text, record) => (
        <span
          style={{
            color: record?.deletedAt ? "#5B5959" : "#000000E0",
            maxWidth: 80,
            display: "block",
          }}
        >
          {record?.bin?.warehouse?.company?.companyName}
        </span>
      ),
    },
    {
      title: "Warehouse code",
      dataIndex: "wareHouseId",
      key: "wareHouseId",
      width: "12%",
      render: (text, record) => (
        <span
          style={{
            color: record?.deletedAt ? "#5B5959" : "#000000E0",
            maxWidth: 100,
            display: "block",
          }}
        >
          {record?.bin?.warehouse?.identityCode}
        </span>
      ),
    },
    {
      title: titleBinCode,
      dataIndex: "binCode",
      key: "binCode",
      render: (text, record) => (
        <span
          style={{
            color: record?.deletedAt ? "#5B5959" : "#000000E0",
            maxWidth: 100,
            display: "block",
          }}
        >
          {record.bin.binCode}
        </span>
      ),
    },
    {
      title: titlePosition,
      dataIndex: "position",
      key: "position",
      render: (text, record) => (
        <span
          style={{
            color: record?.deletedAt ? "#5B5959" : "#000000E0",
            maxWidth: 100,
            display: "block",
          }}
        >
          {record.position}
        </span>
      ),
    },
    {
      title: "QR code",
      dataIndex: "qrCode",
      key: "qrCode",
      render: (t, record) => (
        <>
          <QRCode
            style={{ width: 50, height: 50 }}
            value={JSON.stringify({
              productId: record.id,
              companyId: record.bin.warehouse?.companyId,
            })}
          />
        </>
      ),
    },
    {
      title: "Inventory",
      dataIndex: "inventory",
      key: "inventory",
      render: (text, record) => (
        <span
          style={{
            color: record?.deletedAt ? "#5B5959" : "#000000E0",
            maxWidth: 84,
            display: "block",
          }}
        >
          {record.inventory}
        </span>
      ),
    },
    {
      title: "Max Par Level",
      dataIndex: "minQ",
      key: "minQ",
      width: "7.5%",
      render: (text, record) => (
        <span
          style={{
            color: record?.deletedAt ? "#5B5959" : "#000000E0",
            maxWidth: 66,
            display: "block",
          }}
        >
          {record.minimumQuantity}
        </span>
      ),
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
      render: (text, record) => (
        <span
          style={{
            color: record?.deletedAt ? "#5B5959" : "#000000E0",
            maxWidth: 70,
            display: "block",
          }}
        >
          {record.unit}
        </span>
      ),
    },
    {
      title: "Functions",
      key: "action",
      render: (_, record) => {
        const loading = !!loadingList?.find((item) => item === record?.id);
        return (
          <Space
            size="middle"
            style={{
              fontSize: 12,
            }}
          >
            {record?.deletedAt ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 6,
                  color: "#192EEB",
                  cursor: "pointer",
                }}
                onClick={() => (loading ? undefined : onRestore?.(record?.id))}
              >
                <span>Restore</span>
                <NextIcon style={{ width: 16, cursor: "pointer" }} />
              </div>
            ) : (
              <>
                <Link to={routes.editSiteProduct.path.replace(":id", record.id)}>
                  <EditIcon style={{ width: 16, cursor: "pointer" }} />
                </Link>
                <div onClick={() => (loading ? undefined : onDelete?.(record?.id))}>
                  <DeleteIcon style={{ width: 16, cursor: "pointer" }} />
                </div>
                <Link to={routes.viewSiteProduct.path.replace(":id", record.id)}>
                  <NextIcon style={{ width: 16, cursor: "pointer" }} />
                </Link>
              </>
            )}
          </Space>
        );
      },
    },
  ] as ColumnsType<ProductType>;
