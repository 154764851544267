import { Select, Spin } from "antd";
import { useEffect, useState } from "react";
import useDebounce from "@/hooks/useDebounce";
import { BaseOptionType } from "antd/es/select";
import { useGetWarningProductListWarehouseQuery } from "@/rtk-query/productQueries";
import { IProductOrder, ProductType } from "@/utils/types";

interface IProductWarningCodeDropdownListProps {
  value?: string | number | null;
  onChange: (data: ProductType | null) => void;
  disabled?: boolean;
  filter?: any;
  search?: string;
  className?: string;
  skip?: boolean;
  listHide?: IProductOrder[];
  productChoiced?: any[];
}

const ProductWarningCodeDropdownList = ({
  value,
  onChange,
  disabled,
  filter = {},
  search = "",
  className,
  skip = false,
  listHide = [],
  productChoiced = []
}: IProductWarningCodeDropdownListProps) => {
  const [searchProduct, setSearchProduct] = useState("");
  const searchProductDebounce = useDebounce(searchProduct, 300);
  const [data, setData] = useState<any[]>([]);

  const { currentData: productList, isFetching: isFetchingProduct } = useGetWarningProductListWarehouseQuery(
    {
      ...filter,
      search: searchProductDebounce,
    },
    { skip }
  );

  useEffect(() => {
    setSearchProduct(search);
  }, [search]);

  useEffect(() => {
    if (isFetchingProduct || !productList) return;
    const listIdProductChoiced: any[] = [];
    for(let i = 0; i < productList.length; i++) {
      if(productList[i].id !== productChoiced[i]?.id) {
        listIdProductChoiced.push(productList[i])
      }
    }
    setData(listIdProductChoiced || []);
  }, [isFetchingProduct, productList]);

  // ================

  return (
    <Select
      showSearch
      popupClassName="customDropdownSelectAntd"
      className={className}
      size="middle"
      loading={isFetchingProduct}
      value={value}
      onSearch={(value) => {
        setSearchProduct(value);
      }}
      onChange={(_, children: BaseOptionType) => {
        onChange(data?.find((e) => e.id === children?.value) || null);
      }}
      onBlur={() => {
        setSearchProduct("");
      }}
      allowClear
      onClear={() => onChange(null)}
      disabled={disabled}
      getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}
      filterOption={false}
    >
      {data.map(({ productName, id }: any, index: number) => {
        if (listHide.find((e) => e.productId === id)) {
          return null;
        }
        return (
          <Select.Option value={id} key={index}>
            {productName}
          </Select.Option>
        );
      })}
      {isFetchingProduct && (
        <Select.Option value="load_more">
          <div className="center-element">
            <Spin />
          </div>
        </Select.Option>
      )}
    </Select>
  );
};

export default ProductWarningCodeDropdownList;
