import { useGetNotificationListQuery, usePatchNotificationsMutation } from "@/rtk-query/notificationQueries";
import { TypeNoti } from "@/utils/types";
import { Button } from "antd";
import { useEffect, useRef, useState } from "react";
import styles from "./notification.module.less";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import routes from "@/routes";

enum NOTI_STATUS {
  UNREAD = 1,
  READED = 2,
}

export default function Notification({ onSetOpenNoti, pathname }: { onSetOpenNoti: (open: boolean) => void; pathname: string }) {
  const [page, setPage] = useState(1);
  const [data, setData] = useState<Array<TypeNoti>>([]);

  const listInnerRef = useRef(null);

  const {
    currentData: list,
    isLoading,
    refetch,
  } = useGetNotificationListQuery({
    page,
    isLoading: undefined,
  });
  const [patchNotifications] = usePatchNotificationsMutation();
  const [hasLoadMore, setHasLoadMore] = useState(true);

  const loadMoreData = () => {
    if (hasLoadMore) {
      setHasLoadMore((list?.totalPage || 1) > page);
      setPage(page + 1);
    }
  };

  const handleScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        loadMoreData();
      }
    }
  };

  const handleMaskAll = async () => {
    await patchNotifications([]);
    setPage(1);
  };
  const handleClickView = async (id: string) => {
    onSetOpenNoti(false);
    await patchNotifications([id]);
    const cloneData = [...data];
    const newData = cloneData.map((noti) => {
      if (noti.id === id) {
        return { ...noti, status: NOTI_STATUS.READED.toString() };
      }
      return noti;
    });
    setData(newData);
    refetch();
  };
  useEffect(() => {
    if (isLoading || !list) return;
    page === 1 ? setData(list?.data || []) : setData([...data, ...(list?.data || [])]);
  }, [isLoading, list]);

  useEffect(() => {
    setPage(1);
    refetch();
  }, [pathname]);

  return (
    <div className={styles.wrapNoti}>
      <div className="top">
        <span className="txtTitle">Notification</span>
        <span className="mask" onClick={handleMaskAll}>
          Mask all as read
        </span>
      </div>
      <div className="listNoti" onScroll={handleScroll} ref={listInnerRef}>
        {data.map((e) => (
          <div className="itemWrapNoti" key={e.id}>
            <p>
              <b>{e.properties.companyName.replace("[", "").replace("]", "")}</b> has update
              <b> Order No. {e.properties.orderId}</b> <br />
              <div
                style={{
                  display: "flex",
                  gap: 4,
                }}
              >
                on{" "}
                <b
                  style={{
                    display: "flex",
                    gap: 4,
                    alignItems: "center",
                    width: "fit-content",
                  }}
                >
                  {dayjs(e.properties.created).format("DD/MM/YYYY")} {+e.status === NOTI_STATUS.UNREAD && <span className="unread" />}
                </b>
              </div>
            </p>
            <Link to={routes.viewOrder.path.replace(":id", e.properties.orderId)} onClick={() => handleClickView(e.id)}>
              <Button className="buttonView">view</Button>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}
