import { Col, Row, notification } from "antd";
import { Link, useHistory, useLocation } from "react-router-dom";
import LogoIcon from "@/assets/icons/Logo";
import routes from "@/routes";
import ButtonRectangle from "@/components/ButtonRectangle";
import styles from "../../warehouseManagement.module.less";
import { useTypedSelector } from "@/app/store";
import { CompanySelectType } from "../../warehouseManagement.slice";
import { useState } from "react";
import { useExportWarehouseMutation } from "@/rtk-query/warehouseQueries";
import message from "@/utils/message";
import { getError } from "@/utils/helpers";
import CompanyDropdownList from "@/components/FilterSelectCustom/CompanyDropdownList";
import WarehouseCodeDropdownList from "@/components/FilterSelectCustom/WarehouseCodeDropdownList";
import PrevIcon from "@/assets/icons/PrevIcon";

interface IFilterComponentProps {
  type: "create" | "edit" | "view" | "list";
  onSelectCompany?: (value: CompanySelectType) => void;
  onSelectWarehouseId?: (value: string | number | null) => void;
}

function FilterComponent({ type, onSelectCompany, onSelectWarehouseId }: IFilterComponentProps) {
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const binId = query.get("binId");

  const [searchCompany, setSearchCompany] = useState("");
  const [searchWarehouse, setSearchWarehouse] = useState("");

  const { companySelected, warehouseIdSelected } = useTypedSelector((store) => store.warehouseManagementSlide);

  const [exportWarehouseApi, { isLoading: isLoadingExportWarehouseApi }] = useExportWarehouseMutation();
  
  const handleExportWarehouse = async () => {
   
    try {
      await exportWarehouseApi({
        companyId: companySelected?.id || "",
      }).unwrap();
      notification.success({
        key: "warehouse_export",
        message: message.SUCCESSFULLY,
      });
    } catch (error) {
      notification.error({
        key: "warehouse_export",
        message: getError(error),
      });
    }
  };

  const renderTitle = () => {
    if (binId) return "View bin";

    let title = "";
    switch (type) {
      case "create":
        title = routes.createWarehouse.title;
        break;
      case "edit":
        title = routes.editWarehouse.title;
        break;
      case "view":
        title = routes.viewWarehouse.title;
        break;
      default:
        title = routes.warehouseList.title;
        break;
    }
    return title;
  };

  const shouldDisableFilter = ["edit", "view"].includes(type);

  return (
    <Row justify="space-between" align="middle" className={styles.filterArea}>
      <Col
        flex={1}
        style={{
          height: "100%",
        }}
      >
        <div className={styles.leftContent}>
          {(binId || type === "create") && (
            <div
              className="center-element"
              style={{
                width: 42,
                background: "#D3D7E1",
                height: "100%",
                cursor: "pointer",
              }}
              onClick={() => history.push(type === "create" ? routes.warehouseList.path : location.pathname)}
            >
              <PrevIcon />
            </div>
          )}
          <div className={styles.logoWrapper}>
            {!binId && type !== "create" && <LogoIcon />}
            <span
              style={{
                fontSize: 14,
                color: "#234C66",
                fontWeight: 700,
              }}
            >
              {renderTitle()}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              gap: 16,
            }}
          >
            <CompanyDropdownList
              value={companySelected?.companyName || (companySelected?.id ? JSON.stringify(companySelected) : null)}
              search={searchCompany}
              onChange={(value: any) => {
                onSelectCompany?.(JSON.parse(value || "null"));
                setSearchCompany("");
              }}
              disabled={shouldDisableFilter}
            />
            <WarehouseCodeDropdownList
              value={warehouseIdSelected || null}
              search={searchWarehouse}
              onChange={(value: any) => {
                onSelectWarehouseId?.(value);
                setSearchWarehouse("");
              }}
              disabled={shouldDisableFilter || type === "create"}
              filter={{
                companyId: companySelected?.id,
              }}
              skipByCompany={!companySelected?.id}
            />
          </div>
        </div>
      </Col>
      {type === "list" && (
        <Col
          style={{
            height: "100%",
          }}
        >
          <div className={styles.rightContent}>
            <Link to={routes.createWarehouse.path}>
              <ButtonRectangle>Add new warehouse</ButtonRectangle>
            </Link>

            <ButtonRectangle
              style={{
                background: "#8491A6",
              }}
              onClick={handleExportWarehouse}
              disabled={isLoadingExportWarehouseApi}
            >
              Export Excel file
            </ButtonRectangle>
          </div>
        </Col>
      )}
    </Row>
  );
}

export default FilterComponent;
