import { FilterType, CommonArray, MasterProductType, MasterProductUnitType } from "@/utils/types";
import baseApi from ".";
import { cleanObject, downloadFile } from "@/utils/helpers";
import queryTagTypes from "./queryTagTypes";
import dayjs from "dayjs";

const masterProductApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getMasterProductList: builder.query<
      CommonArray<MasterProductType>,
      FilterType & {
        ignoreDeleted?: boolean;
      }
    >({
      query: (params) => ({
        url: "/master-products",
        method: "GET",
        params: cleanObject({
          sortBy: "createdAt",
          sortOrder: "desc",
          ...params,
        }),
      }),
      keepUnusedDataFor: 0.0001,
      providesTags: [queryTagTypes.MASTER_PRODUCT_QUERY_TAG],
    }),
    getMasterProductDetail: builder.query<MasterProductType, string | number>({
      query: (id) => ({
        url: `/master-products/${id}`,
        method: "GET",
      }),
      providesTags: [queryTagTypes.MASTER_PRODUCT_QUERY_TAG],
    }),
    createMasterProduct: builder.mutation<any, FormData>({
      query: (body) => ({
        url: "/master-products",
        method: "POST",
        body,
      }),
      invalidatesTags: (_, error) => (error ? [] : [queryTagTypes.MASTER_PRODUCT_QUERY_TAG]),
    }),
    editMasterProduct: builder.mutation<
      any,
      {
        id: string | number;
        body: FormData;
      }
    >({
      query: ({ id, body }) => ({
        url: `/master-products/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (_, error) => (error ? [] : [queryTagTypes.MASTER_PRODUCT_QUERY_TAG]),
    }),
    getMasterProductUnitList: builder.query<CommonArray<MasterProductUnitType>, FilterType>({
      query: () => ({
        url: "/master-products/units",
        method: "GET",
      }),
      providesTags: [],
    }),
    deleteMasterProduct: builder.mutation<any, string | number>({
      query: (id) => ({
        url: `/master-products/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (_, error) => (error ? [] : [queryTagTypes.MASTER_PRODUCT_QUERY_TAG]),
    }),
    restoreMasterProduct: builder.mutation<any, string | number>({
      query: (id) => ({
        url: `/master-products/${id}/restore`,
        method: "POST",
      }),
      invalidatesTags: (_, error) => (error ? [] : [queryTagTypes.MASTER_PRODUCT_QUERY_TAG]),
    }),
    exportMasterProduct: builder.mutation<
      Blob,
      {
        page?: number;
        pageSize?: number;
      }
    >({
      query: (params) => ({
        url: `/master-products/export`,
        method: "POST",
        params: cleanObject(params),
        responseHandler: async (response) => {
          if (!response.ok)
            return {
              message: response.statusText,
            };
          const blobReponse = await response.blob();
          downloadFile(blobReponse, `Masterproduct_${dayjs().format("DD_MM_HH_MM")}.xlsx`);
          return { data: null };
        },
        cache: "no-cache",
      }),
      invalidatesTags: [],
    }),
  }),
});

export default masterProductApi;
export const {
  useCreateMasterProductMutation,
  useGetMasterProductDetailQuery,
  useGetMasterProductListQuery,
  useEditMasterProductMutation,
  useGetMasterProductUnitListQuery,
  useDeleteMasterProductMutation,
  useRestoreMasterProductMutation,
  useExportMasterProductMutation,
} = masterProductApi;
