import { FilterType, CommonArray } from "@/utils/types";
import baseApi from ".";
import { cleanObject, downloadFile } from "@/utils/helpers";
import queryTagTypes from "./queryTagTypes";

const orderApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrderList: builder.query<
      CommonArray<any>,
      FilterType & {
        companyId?: string | number;
        warehouseId?: string | number;
        orderStatus?: number | null;
      }
    >({
      query: (params) => ({
        url: "/orders",
        method: "GET",
        params: cleanObject({
          sortBy: "createdAt",
          sortOrder: "desc",
          ...params,
        }),
      }),
      providesTags: [queryTagTypes.ORDER_QUERY_TAG],
    }),
    getOrderDetail: builder.query<any, string | number>({
      query: (id) => ({
        url: `/orders/${id}`,
        method: "GET",
      }),
      providesTags: [queryTagTypes.ORDER_QUERY_TAG],
    }),
    createOrder: builder.mutation<
      any,
      {
        warehouseId: string | number;
        products: Array<{
          id: string | number;
          quantity: number;
        }>;
        notes?: { note: string }[];
      }
    >({
      query: (body) => ({
        url: "/orders",
        method: "POST",
        body,
      }),
      invalidatesTags: (_, error) =>
        error ? [] : [queryTagTypes.ORDER_QUERY_TAG],
    }),
    receivedOrder: builder.mutation<
      any,
      {
        id: string | number;
        body: {
          products: Array<{
            productId: string | number;
            receivedQuantity: number;
          }>;
        };
      }
    >({
      query: ({ id, body }) => ({
        url: `/orders/${id}/received`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: (_, error) =>
        error ? [] : [queryTagTypes.ORDER_QUERY_TAG],
    }),
    editOrder: builder.mutation<
      any,
      {
        id: string | number;
        body: {
          products: Array<{
            id: string | number;
            quantity: number;
          }>;
          notes?: { note: string }[];
          customerPurchaseOrderNumber?: string;
          status: number;
        };
      }
    >({
      query: ({ id, body }) => ({
        url: `/orders/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: (_, error) =>
        error ? [] : [queryTagTypes.ORDER_QUERY_TAG],
    }),
    deleteProductInOrder: builder.mutation<
      any,
      { orderId: string | number; productId: string | number }
    >({
      query: ({ orderId, productId }) => ({
        url: `/orders/${orderId}/product/${productId}`,
        method: "DELETE",
      }),
      invalidatesTags: (_, error) =>
        error ? [] : [queryTagTypes.ORDER_QUERY_TAG],
    }),
    addProductToOrder: builder.mutation<
      any,
      {
        id: string | number;
        body: {
          id: string | number;
          quantity: number;
        };
      }
    >({
      query: ({ id, body }) => ({
        url: `/orders/${id}/product`,
        method: "POST",
        body,
      }),
      invalidatesTags: (_, error) =>
        error ? [] : [queryTagTypes.ORDER_QUERY_TAG],
    }),
    getOrderHistoryList: builder.query<
      any[],
      {
        orderId: string | number;
      }
    >({
      query: (params) => ({
        url: "/order-histories",
        method: "GET",
        params: cleanObject({
          ...params,
        }),
      }),
      providesTags: [queryTagTypes.ORDER_QUERY_TAG],
    }),
    getOrderHistoryDetail: builder.query<any, string | number>({
      query: (id) => ({
        url: `/order-histories/${id}`,
        method: "GET",
      }),
      providesTags: [queryTagTypes.ORDER_QUERY_TAG],
    }),
    exportOrders: builder.mutation<
      any,
      FilterType & {
        orderStatus?: number;
        warehouseId?: string | number;
        companyId?: string | number;
      }
    >({
      query: (params) => ({
        url: `/orders/export`,
        method: "POST",
        params: cleanObject(params),
        responseHandler: async (response) => {
          const blobReponse = await response.blob();
          downloadFile(blobReponse, `orders_report.xlsx`);
          return { data: null };
        },
        cache: "no-cache",
      }),
      invalidatesTags: [],
    }),
    exportOrderDetail: builder.mutation<any, string | number>({
      query: (id) => ({
        url: `/orders/${id}/export-detail`,
        method: "POST",
        responseHandler: async (response) => {
          const blobReponse = await response.blob();
          downloadFile(blobReponse, `orders_${id}_report.xlsx`);
          return { data: null };
        },
        cache: "no-cache",
      }),
      invalidatesTags: [],
    }),
    cancelMultipleOrder: builder.mutation<any, { body: { 
      status: number | string,
      ids: Array<string | number>
     } } >({
      query: ({ body }) => ({
        url: `/orders/status-multiple`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (_, error) =>
      error ? [] : [queryTagTypes.ORDER_QUERY_TAG],
    }),
    deleteOrder: builder.mutation<any, string | number >({
      query: (orderId) => ({
        url: `/orders/${orderId}`,
        method: "DELETE",
      }),
      invalidatesTags: (_, error) =>
        error ? [] : [queryTagTypes.ORDER_QUERY_TAG],
    }),
  }),
});

export default orderApi;
export const {
  useGetOrderListQuery,
  useCreateOrderMutation,
  useGetOrderDetailQuery,
  useReceivedOrderMutation,
  useEditOrderMutation,
  useDeleteProductInOrderMutation,
  useAddProductToOrderMutation,
  useGetOrderHistoryListQuery,
  useGetOrderHistoryDetailQuery,
  useExportOrdersMutation,
  useExportOrderDetailMutation,
  useCancelMultipleOrderMutation,
  useDeleteOrderMutation
} = orderApi;
