import { ReactNode, useEffect } from "react";

interface IPageLayoutProps {
  children: ReactNode;
  title?: string;
}

function PageLayout({ children = null, title = "" }: IPageLayoutProps) {
  useEffect(() => {
    document.title = title || "";
  }, [title]);

  return (
    <div
      style={{
        overflow: "auto",
      }}
    >
      {children}
    </div>
  );
}

export default PageLayout;
