import { Select, Spin } from "antd";
import commonStyles from "@/styles/common.module.less";
import clsx from "clsx";
import { useEffect, useState } from "react";
import useDebounce from "@/hooks/useDebounce";
import { useGetCompaniesQuery } from "@/rtk-query/companyQueries";
import { useGetUserInfoQuery } from "@/rtk-query/userQueries";
import { BaseOptionType } from "antd/es/select";
import styles from "./select.module.less";
import { CompanyType } from "@/utils/types";

interface ICompanyDropdownListProps {
  value: string | number | null;
  onChange: (value: any) => void;
  disabled?: boolean;
  isHideOptionAll?: boolean;
  search?: string;
}

const CompanyDropdownList = ({ value, onChange, disabled, search = "", isHideOptionAll }: ICompanyDropdownListProps) => {
  const [searchCompany, setSearchCompany] = useState("");
  const searchCompanyDebounce = useDebounce(searchCompany, 300);

  const [page, setPage] = useState(1);
  const [data, setData] = useState<Array<CompanyType>>([]);

  const { currentData: userInfo } = useGetUserInfoQuery();
  const { currentData: companyList, isFetching: isFetchingCompany } = useGetCompaniesQuery(
    {
      search: searchCompanyDebounce,
      page,
      isLoading: undefined,
    },
    {
      skip: !userInfo?.isSuperAdmin,
    }
  );

  // Scroll infinite =========
  const onScroll = async (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    if (
      !isFetchingCompany &&
      event.target.scrollTop + event.target.offsetHeight === event.target.scrollHeight &&
      (companyList?.totalPage || 1) > page
    ) {
      setPage(page + 1);
    }
  };
  // ================

  useEffect(() => {
    setSearchCompany(search);
  }, [search]);

  useEffect(() => {
    if (isFetchingCompany || !companyList) return;
    page === 1 ? setData(companyList?.data || []) : setData([...data, ...(companyList?.data || [])]);
  }, [isFetchingCompany, companyList]);

  const dropdownStyle = {
    background: "#dfe3ef",
    padding: 0,
    borderRadius: 0,
    boxShadow: "inset 4px 4px 3px rgba(0, 0, 0, 0.03)",
    fontSize: 12,
    fontWeight: 600,
  };

  return (
    <Select
      style={{ width: 230, background: "#BFCFD9", color: "#234C66" }}
      showSearch
      dropdownStyle={dropdownStyle}
      popupClassName="customDropdownSelectAntd"
      className={clsx(commonStyles.select, styles.filterSelect)}
      size="large"
      placeholder="Company name..."
      loading={isFetchingCompany}
      value={value}
      onSearch={(value) => {
        setPage(1);
        setSearchCompany(value);
      }}
      onChange={(_, children: BaseOptionType) => onChange(children?.value || null)}
      onBlur={() => {
        setSearchCompany("");
        setPage(1);
      }}
      allowClear
      onClear={() => onChange(null)}
      disabled={disabled || !userInfo?.isSuperAdmin}
      getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}
      onPopupScroll={onScroll}
      filterOption={false}
    >
      {!isHideOptionAll && <Select.Option value="">All</Select.Option>}
      {(data || []).map(({ companyName, id, address }: any, index: number) => (
        <Select.Option
          value={JSON.stringify({
            id,
            companyName,
            address,
          })}
          key={index}
        >
          {companyName}
        </Select.Option>
      ))}
      {isFetchingCompany && (
        <Select.Option value="load_more">
          <div className="center-element">
            <Spin />
          </div>
        </Select.Option>
      )}
    </Select>
  );
};

export default CompanyDropdownList;
