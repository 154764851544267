// import { CommonArray, CommonQueryType, ProductType, SimilarProductType } from '@/utils/types';
import {
  CommonArray,
  FilterType,
  UserPayloadType,
  UserType,
} from "@/utils/types";
import baseApi from ".";
import queryTagTypes from "./queryTagTypes";
import { cleanObject, downloadFile } from "@/utils/helpers";

const userApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserInfo: builder.query<any, void>({
      query: () => ({
        url: "/users/info",
        method: "GET",
      }),
      providesTags: [queryTagTypes.USER_INFO_QUERY_TAG],
    }),
    addUserToClient: builder.mutation<any, UserPayloadType>({
      query: (body) => ({
        url: "/users",
        method: "POST",
        body,
      }),
      invalidatesTags: (_, error) =>
        error ? [] : [queryTagTypes.USER_QUERY_TAG],
    }),
    updateUser: builder.mutation<
      any,
      {
        id: string | number;
        body: UserPayloadType;
      }
    >({
      query: ({ id, body }) => ({
        url: `/users/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (_, error) =>
        error ? [] : [queryTagTypes.USER_QUERY_TAG],
    }),
    getUserList: builder.query<
      CommonArray<UserType>,
      {
        companyId: string | number;
      } & FilterType
    >({
      query: (params) => ({
        url: "/users",
        method: "GET",
        params: cleanObject({
          sortBy: "createdAt",
          sortOrder: "desc",
          ...params,
        }),
      }),
      providesTags: [queryTagTypes.USER_QUERY_TAG],
    }),
    getUserDetail: builder.query<UserType, string | number>({
      query: (id) => ({
        url: `/users/${id}`,
        method: "GET",
      }),
      providesTags: [queryTagTypes.USER_QUERY_TAG],
    }),
    changePassword: builder.mutation<
      any,
      {
        oldPassword: string;
        newPassword: string;
        confirmPassword: string;
      }
    >({
      query: (body) => ({
        url: "/users/change-password",
        method: "PATCH",
        body,
      }),
      invalidatesTags: (_, error) =>
        error ? [] : [queryTagTypes.USER_INFO_QUERY_TAG],
    }),
    resetDefaultPassword: builder.mutation<
      any,
      {
        id: string | number;
        body: {
          newPassword: string;
        };
      }
    >({
      query: ({ id, body }) => ({
        url: `/users/${id}/default-password`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: [],
    }),
    getUserWarehouseList: builder.query<
      any,
      {
        companyId?: string | number;
      } & FilterType
    >({
      query: (params) => ({
        url: "/users/warehouses",
        method: "GET",
        params: cleanObject({
          sortBy: "createdAt",
          sortOrder: "desc",
          ...params,
        }),
      }),
      providesTags: [],
    }),
    exportUser: builder.mutation<
      Blob,
      {
        companyId: string | number;
      }
    >({
      query: (params) => ({
        url: `/users/export`,
        method: "POST",
        params: cleanObject(params),
        responseHandler: async (response) => {
          const blobReponse = await response.blob();
          downloadFile(
            blobReponse,
            `users_${params?.companyId || "all"}_report.xlsx`
          );
          return { data: null };
        },
        cache: "no-cache",
      }),
      invalidatesTags: [],
    }),
  }),
});

export default userApi;
export const {
  useGetUserInfoQuery,
  useGetUserListQuery,
  useAddUserToClientMutation,
  useUpdateUserMutation,
  useGetUserDetailQuery,
  useChangePasswordMutation,
  useResetDefaultPasswordMutation,
  useGetUserWarehouseListQuery,
  useExportUserMutation,
} = userApi;
