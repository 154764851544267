import { FilterType, CommonArray, TypeNoti } from "@/utils/types";
import baseApi from ".";
import { cleanObject } from "@/utils/helpers";
import queryTagTypes from "./queryTagTypes";

const notificationApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getNotificationList: builder.query<
      CommonArray<TypeNoti>,
      FilterType & {
        ignoreDeleted?: boolean;
      }
    >({
      query: (params) => ({
        url: "/notifications",
        method: "GET",
        params: cleanObject({
          sortBy: "createdAt",
          sortOrder: "desc",
          ...params,
        }),
      }),
      providesTags: [queryTagTypes.NOTI_QUERY_TAG],
    }),
    patchNotifications: builder.mutation<any, string[]>({
      query: (ids) => ({
        url: `/notifications/mask-as-read/`,
        method: "PATCH",
        body: {
          ids,
        },
      }),
      invalidatesTags: (_, error) =>
        error ? [] : [queryTagTypes.NOTI_QUERY_TAG],
    }),
  }),
});

export default notificationApi;
export const { useGetNotificationListQuery, usePatchNotificationsMutation } =
  notificationApi;
