import { FilterType, WarehouseType } from "../../utils/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cleanObject } from "@/utils/helpers";
import C from "@/utils/constants";

export interface IFilterWarehouse extends FilterType {
  companyId?: string | number | null;
}

export type CompanySelectType = {
  id: string | number;
  companyName: string;
  address?: string;
} | null;

interface IinitialState {
  filter: IFilterWarehouse;
  data: Array<any>;
  companySelected?: CompanySelectType;
  warehouseIdSelected?: string | number | null;
  wareHouseSelected?: WarehouseType | null;
}

const initialState: IinitialState = {
  filter: {
    page: 1,
    pageSize: C.PAGE_SIZE_LITTLE,
    isLoading: undefined,
  },
  companySelected: null,
  warehouseIdSelected: null,
  wareHouseSelected: null,
  data: [],
};

export const orderManagementSlide = createSlice({
  name: "orderManagementSlide",
  initialState,
  reducers: {
    updateFilterState(state, action: PayloadAction<IFilterWarehouse>) {
      state.filter = cleanObject({
        ...state.filter,
        ...action.payload,
      });
    },
    updateDataState(state, action: PayloadAction<Array<any>>) {
      state.data = action.payload;
    },
    onSelectCompany(state, action: PayloadAction<CompanySelectType>) {
      state.companySelected = action.payload;
      state.warehouseIdSelected = null;
    },
    onSelectWarehouse(state, action: PayloadAction<any | null>) {
      state.wareHouseSelected = action.payload;
    },
  },
});

export const orderManagementReducer = orderManagementSlide.reducer;
