import React from "react";
import { Button, Result } from "antd";
import { useHistory } from "react-router";
import PageLayout from "@/components/layouts/PageLayout";
import routes from "@/routes";

const Custom403Page: React.FC = () => {
  const history = useHistory();

  return (
    <PageLayout title={routes[403].title}>
      <Result
        status="403"
        title="403"
        subTitle="Sorry, you are not authorized to access this page."
        extra={
          <Button type="primary" onClick={() => history.goBack()}>
            Back Home
          </Button>
        }
      />
    </PageLayout>
  );
};

export default Custom403Page;
