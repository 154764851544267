import {
  BinPayloadType,
  BinType,
  FilterType,
  CommonArray,
} from "@/utils/types";
import baseApi from ".";
import queryTagTypes from "./queryTagTypes";
import { cleanObject, downloadFile } from "@/utils/helpers";

const binApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getBinList: builder.query<
      CommonArray<BinType>,
      { warehouseId?: string | number } & FilterType
    >({
      query: (params) => ({
        url: "/bins",
        method: "GET",
        params: cleanObject({
          sortBy: "createdAt",
          sortOrder: "desc",
          ...params,
        }),
      }),
      providesTags: [queryTagTypes.BIN_QUERY_TAG],
    }),
    getBinDetail: builder.query<CommonArray<BinType>, string | number>({
      query: (id) => ({
        url: `/bins/${id}`,
        method: "GET",
      }),
      providesTags: [queryTagTypes.BIN_QUERY_TAG],
    }),
    createNewBin: builder.mutation<any, BinPayloadType>({
      query: (body) => ({
        url: "/bins",
        method: "POST",
        body,
      }),
      invalidatesTags: (_, error) =>
        error
          ? []
          : [queryTagTypes.BIN_QUERY_TAG, queryTagTypes.WAREHOUSE_QUERY_TAG],
    }),
    editBin: builder.mutation<
      any,
      {
        id: string | number;
        body: BinPayloadType;
      }
    >({
      query: ({ id, body }) => ({
        url: `/bins/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (_, error) =>
        error ? [] : [queryTagTypes.BIN_QUERY_TAG],
    }),
    deleteBin: builder.mutation<any, string | number>({
      query: (id) => ({
        url: `/bins/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (_, error) =>
        error ? [] : [queryTagTypes.BIN_QUERY_TAG],
    }),
    restoreBin: builder.mutation<any, string | number>({
      query: (id) => ({
        url: `/bins/${id}/restore`,
        method: "POST",
      }),
      invalidatesTags: (_, error) =>
        error ? [] : [queryTagTypes.BIN_QUERY_TAG],
    }),
  
    exportBin: builder.mutation<
    Blob, 
    {
      id: string | number;
    }>({
      query: (params) => ({
        url: `/bins/export`,
        method: "POST",
        params: cleanObject(params), 
        responseHandler: async(response) => {
          const blobReponse = await response.blob();
          downloadFile(
            blobReponse,
            `bins${params?.id}_report.xlsx`
          );
          return { data: null };
        },
        cache: "no-cache",
      }),
      invalidatesTags: []
    })
  }),
});

export default binApi;
export const {
  useGetBinListQuery,
  useGetBinDetailQuery,
  useCreateNewBinMutation,
  useEditBinMutation,
  useDeleteBinMutation,
  useRestoreBinMutation,
  useExportBinMutation,
} = binApi;
