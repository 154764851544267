import { FilterType } from "../../utils/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cleanObject } from "@/utils/helpers";
import C from "@/utils/constants";

interface IinitialState {
  filter: FilterType;
  data: Array<any>;
}

const initialState: IinitialState = {
  filter: {
    page: 1,
    pageSize: C.PAGE_SIZE_LITTLE,
    isLoading: undefined,
  },
  data: [],
};

export const clientManagementSlide = createSlice({
  name: "clientManagementSlide",
  initialState,
  reducers: {
    updateFilterState(state, action: PayloadAction<FilterType>) {
      state.filter = cleanObject({
        ...state.filter,
        ...action.payload,
      });
    },
    updateDataState(state, action: PayloadAction<Array<any>>) {
      state.data = action.payload;
    },
  },
});

export const clientManagementReducer = clientManagementSlide.reducer;
