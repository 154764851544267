import { FilterType } from "../utils/types";
import baseApi from ".";
import { cleanObject } from "@/utils/helpers";
import queryTagTypes from "./queryTagTypes";

const fileImportApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    importMasterProduct: builder.mutation<any, FormData>({
      query: (body) => ({
        url: `/file-import/master-product`,
        method: "POST",
        body,
      }),
      invalidatesTags: (_, error) =>
        error ? [] : [queryTagTypes.IMPORT_MASTER_PRODUCT_QUERY_TAG],
    }),
    importProduct: builder.mutation<any, FormData>({
      query: (body) => ({
        url: `/file-import/product`,
        method: "POST",
        body,
      }),
      invalidatesTags: (_, error) =>
        error ? [] : [queryTagTypes.IMPORT_MASTER_PRODUCT_QUERY_TAG],
    }),
    getStatusImportMasterProduct: builder.query<
      any,
      {
        type?: 1 | 2;
      }
    >({
      query: (params) => ({
        url: "/file-import/status",
        method: "GET",
        params,
      }),
      providesTags: [],
    }),
    getFileImport: builder.query<
      any,
      {
        id: string | number;
        detail?: boolean;
      }
    >({
      query: ({ id, detail }) => ({
        url: `/file-import/${id}`,
        method: "GET",
        params: {
          detail,
        },
      }),
      providesTags: [],
    }),
    getDetailFileDataImport: builder.query<
      any,
      FilterType & {
        fileImportId: string | number;
        status?: "OPEN" | "SUCCESS" | "ERROR";
      }
    >({
      query: (params) => ({
        url: `/file-data-import`,
        method: "GET",
        params: cleanObject(params),
      }),
      providesTags: [],
    }),
    getFileDataImportList: builder.query<
      any,
      FilterType & {
        type?: 1 | 2;
      }
    >({
      query: (params) => ({
        url: `/file-import`,
        method: "GET",
        params: cleanObject({
          sortBy: "createdAt",
          sortOrder: "desc",
          page: 1,
          pageSize: 1,
          ...params,
        }),
      }),
      providesTags: [queryTagTypes.IMPORT_MASTER_PRODUCT_QUERY_TAG],
    }),
  }),
});

export default fileImportApi;
export const {
  useImportMasterProductMutation,
  useImportProductMutation,
  useGetStatusImportMasterProductQuery,
  useGetFileImportQuery,
  useGetDetailFileDataImportQuery,
  useGetFileDataImportListQuery,
} = fileImportApi;
