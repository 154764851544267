import { Select } from "antd";
import { CSSProperties, useEffect, useState } from "react";
import useDebounce from "@/hooks/useDebounce";
import { BaseOptionType } from "antd/es/select";
import { useGetBinListQuery } from "@/rtk-query/binQueries";

interface IBinsCodeDropdownListProps {
  value?: string | number | null;
  onChange: (dataBins: any) => void;
  disabled?: boolean;
  filter?: any;
  search?: string;
  className?: string;
  style?: CSSProperties;
  skip?: boolean;
}

const BinsCodeDropdownList = ({ value, onChange, disabled, filter = {}, search = "", style, skip, className }: IBinsCodeDropdownListProps) => {
  const [searchBins, setSearchBins] = useState("");
  const searchBinsDebounce = useDebounce(searchBins, 300);
  const [data, setData] = useState<any[]>([]);
  const { currentData: list, isFetching: isFetchingBins } = useGetBinListQuery(
    {
      ...filter,
      search: searchBinsDebounce,
    },
    { skip, refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    setSearchBins(search);
  }, [search]);

  useEffect(() => {
    setData(list?.data || []);
  }, [isFetchingBins]);

  const dropdownStyle = {
    background: "#dfe3ef",
    padding: 0,
    borderRadius: 0,
    boxShadow: "inset 4px 4px 3px rgba(0, 0, 0, 0.03)",
    fontSize: 12,
    fontWeight: 600,
  };

  return (
    <Select
      style={
        style || {
          color: "#234C66",
        }
      }
      className={className}
      showSearch
      dropdownStyle={dropdownStyle}
      popupClassName="customDropdownSelectAntd"
      size="middle"
      placeholder="Bin Code..."
      loading={isFetchingBins}
      value={value}
      onSearch={(value) => setSearchBins(value)}
      onChange={(_, children: BaseOptionType) => {
        onChange(data?.find((e) => e.id === children?.value));
      }}
      allowClear
      onClear={() => onChange(null)}
      disabled={disabled}
      getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}
      filterOption={false}
    >
      {(skip ? [] : data).map(({ binCode, id }: any, index: number) => {
        return (
          <Select.Option value={id} key={index}>
            {binCode}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default BinsCodeDropdownList;
