import { Select, Spin } from "antd";
import { CSSProperties, useEffect, useState } from "react";
import useDebounce from "@/hooks/useDebounce";
import { BaseOptionType } from "antd/es/select";
import { useGetMasterProductListQuery } from "@/rtk-query/masterProductQueries";
import { MasterProductType } from "@/utils/types";

interface IMasterProductCodeDropdownListProps {
  value?: string | number | null;
  onChange: (idLinkedMasterProduct: any, data?: MasterProductType) => void;
  disabled?: boolean;
  filter?: any;
  search?: string;
  style?: CSSProperties;
  className?: string;
  placeholder?: string;
  dataSelected?: MasterProductType | null;
}

const MasterProductCodeDropdownList = ({
  value,
  onChange,
  disabled,
  filter = {},
  search = "",
  style,
  className,
  placeholder = "Product code...",
  dataSelected,
}: IMasterProductCodeDropdownListProps) => {
  const [page, setPage] = useState(1);
  const [searchMasterProduct, setSearchMasterProduct] = useState("");
  const searchMasterProductDebounce = useDebounce(searchMasterProduct, 300);
  const [data, setData] = useState<any[]>([]);
  const { currentData: list, isFetching: isFetchingMasterProduct } = useGetMasterProductListQuery({
    ...filter,
    page,
    pageSize: 30,
    search: searchMasterProductDebounce,
  });

  // Scroll infinite =========
  const onScroll = async (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    if (!isFetchingMasterProduct && event.target.scrollTop + event.target.offsetHeight === event.target.scrollHeight && (list?.totalPage || 1) > page) {
      setPage(page + 1);
    }
  };
  // ================

  useEffect(() => {
    setSearchMasterProduct(search);
  }, [search]);

  useEffect(() => {
    if (isFetchingMasterProduct || !list) return;
    page === 1 ? setData(list?.data || []) : setData([...data, ...(list?.data || [])]);
  }, [isFetchingMasterProduct, list]);

  const dropdownStyle = {
    background: "#dfe3ef",
    padding: 0,
    borderRadius: 0,
    boxShadow: "inset 4px 4px 3px rgba(0, 0, 0, 0.03)",
    fontSize: 12,
    fontWeight: 600,
  };

  return (
    <Select
      style={style || { width: 230, background: "#BFCFD9", color: "#234C66" }}
      showSearch
      dropdownStyle={dropdownStyle}
      popupClassName="customDropdownSelectAntd"
      size="middle"
      placeholder={placeholder}
      loading={isFetchingMasterProduct}
      value={value}
      onSearch={(value) => {
        setPage(1);
        setSearchMasterProduct(value);
      }}
      onChange={(_, children: BaseOptionType) => {
        onChange(
          children?.value,
          data.find((product) => product?.id === children?.value)
        );
      }}
      onBlur={() => {
        setSearchMasterProduct("");
        setPage(1);
      }}
      allowClear
      onClear={() => onChange(null)}
      disabled={disabled}
      getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}
      filterOption={false}
      className={className}
      onPopupScroll={onScroll}
    >
      {data.map(({ productName, id }: any, index: number) => {
        return (
          <Select.Option value={id} key={index}>
            {productName}
          </Select.Option>
        );
      })}
      {!data.find((e) => e.id === dataSelected?.id) && dataSelected && <Select.Option value={dataSelected?.id}>{dataSelected?.productName}</Select.Option>}
      {isFetchingMasterProduct && (
        <Select.Option value="load_more">
          <div className="center-element">
            <Spin />
          </div>
        </Select.Option>
      )}
    </Select>
  );
};

export default MasterProductCodeDropdownList;
