import { Form, InputNumber } from "antd";
import { ColumnsType } from "antd/es/table";
import clsx from "clsx";
import commonStyles from "@/styles/common.module.less";
import { handleCheckNumberKey } from "../SiteProductCUR";

export const columns = () =>
  [
    {
      title: "Product name",
      dataIndex: "productName",
      key: "productName",
      width: 160,
      render: (text, record) => (
        <span
          style={{
            color: record?.deletedAt ? "#5B5959" : "#000000E0",
            maxWidth: 160,
            display: "block",
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Ordered quantity",
      dataIndex: "orderQuantity",
      key: "orderQuantity",
      width: 100,
      render: (text, record) => (
        <span
          style={{
            color: record?.deletedAt ? "#5B5959" : "#000000E0",
            maxWidth: 100,
            display: "block",
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Received quantity",
      dataIndex: "receivedQuantity",
      key: "receivedQuantity",
      width: 100,
      editable: true,
      render: (text, record) => (
        <Form.Item name={["receivedQuantity", record.productId]}>
          <InputNumber
            className={clsx(commonStyles.textField, commonStyles.textFieldDark)}
            size="large"
            placeholder="Type..."
            controls={false}
            onKeyDown={handleCheckNumberKey}
            type="number"
            defaultValue={text}
          />
        </Form.Item>
      ),
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
      width: 100,
      render: (text, record) => (
        <span
          style={{
            color: record?.deletedAt ? "#5B5959" : "#000000E0",
            maxWidth: 100,
          }}
        >
          {text}
        </span>
      ),
    },
  ] as ColumnsType<any>;
