import { Col, Divider, Form, Input, Pagination, Row, Typography } from "antd";
import { useHistory, useParams } from "react-router";
import styles from "../../clientCUR.module.less";
import commonStyles from "@/styles/common.module.less";

import { useGetWarehouseListQuery } from "@/rtk-query/warehouseQueries";
import ButtonNotched from "@/components/ButtonNotched";
import { useState } from "react";
import C from "@/utils/constants";
import EmptyWrapper from "@/components/EmptyCustom";
import routes from "@/routes";

const { TextArea } = Input;
const { Title } = Typography;

function WarehouseList() {
  const params: any = useParams();
  const history = useHistory();

  const [page, setPage] = useState(1);
  const { currentData } = useGetWarehouseListQuery(
    {
      companyId: params?.id,
      page,
      pageSize: C.PAGE_SIZE_LITTLE,
      isLoading: undefined,
    },
    {
      skip: !params?.id,
    }
  );

  const warehouseList = currentData?.data || [];

  return (
    <div className={styles.warehouseWrapper}>
      <Divider
        style={{
          marginBottom: 20,
        }}
      />
      <Title
        level={5}
        style={{
          color: "#234C66",
          marginBottom: 20,
        }}
      >
        LIST OF WAREHOUSES
      </Title>

      <EmptyWrapper data={warehouseList} filter={{ page }}>
        {warehouseList.map(({ id, identityCode, numberOfBin, location, bins }, index: number) => (
          <div
            key={index}
            style={{
              marginBottom: 24,
            }}
          >
            <Row gutter={[24, 24]}>
              <Col span={12}>
                <Row gutter={[0, 12]}>
                  <Col span={24}>
                    <Form.Item label={<span className={commonStyles.label}>Warehouse code</span>} wrapperCol={{ span: 24 }} labelCol={{ span: 4 }}>
                      <Input
                        className={commonStyles.textField}
                        style={{
                          height: 38,
                        }}
                        size="large"
                        disabled
                        value={identityCode}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item label={<span className={commonStyles.label}>Number of bins</span>} wrapperCol={{ span: 24 }} labelCol={{ span: 4 }}>
                      <Input
                        className={commonStyles.textField}
                        style={{
                          height: 38,
                        }}
                        size="large"
                        disabled
                        value={numberOfBin}
                      />
                    </Form.Item>
                  </Col>

                  {(bins || []).map(({ binCode }: any, idx: number) => (
                    <Col span={24} key={idx}>
                      <Form.Item label={<span className={commonStyles.label}>Bin Code</span>} wrapperCol={{ span: 24 }} labelCol={{ span: 4 }}>
                        <Input
                          className={commonStyles.textField}
                          style={{
                            height: 38,
                          }}
                          size="large"
                          disabled
                          value={binCode}
                        />
                      </Form.Item>
                    </Col>
                  ))}
                </Row>
              </Col>

              <Col span={12}>
                <Row gutter={[0, 12]}>
                  <Col span={24}>
                    <Form.Item label={<span className={commonStyles.label}>Location</span>} wrapperCol={{ span: 24 }} labelCol={{ span: 4 }}>
                      <TextArea rows={4} className={commonStyles.textField} size="large" disabled value={location} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Row>
                      <Col span={4} />
                      <Col span={20}>
                        <Row justify="end">
                          <ButtonNotched onClick={() => history.push(routes.editWarehouse.path.replace(":id", id))}>Edit</ButtonNotched>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        ))}
      </EmptyWrapper>
      {(currentData?.totalPage || 0) > 1 && (
        <Row
          justify="end"
          style={{
            marginTop: 24,
          }}
        >
          <Pagination
            current={currentData?.page}
            total={currentData?.totalPage || 0}
            pageSize={1}
            onChange={(page) => {
              setPage(page);
            }}
            showSizeChanger={false}
          />
        </Row>
      )}
    </div>
  );
}

export default WarehouseList;
