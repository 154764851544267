import { IconProps } from "@/utils/types";

function FilterIcon({ color = "black", style }: IconProps) {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 8 8"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M0.125089 0.805C1.13509 2.1 3.00009 4.5 3.00009 4.5V7.5C3.00009 7.775 3.22509 8 3.50009 8H4.50009C4.77509 8 5.00009 7.775 5.00009 7.5V4.5C5.00009 4.5 6.86009 2.1 7.87009 0.805C8.12509 0.475 7.89009 0 7.47509 0H0.520089C0.105089 0 -0.129911 0.475 0.125089 0.805Z"
        fill={color}
      />
    </svg>
  );
}
export default FilterIcon;
