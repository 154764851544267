import { ProductPayloadType, FilterType, CommonArray, ProductType } from "@/utils/types";
import baseApi from ".";
import { cleanObject, downloadFile } from "@/utils/helpers";
import queryTagTypes from "./queryTagTypes";
import dayjs from "dayjs";

const productApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getProductList: builder.query<
      CommonArray<ProductType>,
      {
        companyId?: string | number;
        warehouseId?: string | number;
        ignoreDeleted?: boolean;
        binId?: string;
      } & FilterType
    >({
      query: (params) => ({
        url: "/products",
        method: "GET",
        params: cleanObject({
          sortBy: "createdAt",
          sortOrder: "desc",
          ...params,
        }),
      }),
      keepUnusedDataFor: 0.0001,
      providesTags: [queryTagTypes.PRODUCT_QUERY_TAG],
    }),
    getWarningProductList: builder.query<
      CommonArray<ProductType>,
      {
        companyId?: string | number;
        warehouseId?: string | number;
        ignoreDeleted?: boolean;
        binId?: string;
      } & FilterType
    >({
      query: (params) => ({
        url: "/products/warning-product",
        method: "GET",
        params: cleanObject({
          sortBy: "createdAt",
          sortOrder: "desc",
          ...params,
        }),
      }),
      providesTags: [queryTagTypes.PRODUCT_QUERY_TAG],
    }),
    getWarningProductListWarehouse: builder.query<
      ProductType[],
      {
        companyId: string | number;
        warehouseId: string | number;
      }
    >({
      query: (params) => ({
        url: "/products/warning-product-warehouse",
        method: "GET",
        params: cleanObject({
          ...params,
        }),
      }),
      providesTags: [queryTagTypes.PRODUCT_QUERY_TAG],
    }),
    getProductDetail: builder.query<ProductType, string | number>({
      query: (id) => ({
        url: `/products/${id}`,
        method: "GET",
      }),
      providesTags: [queryTagTypes.PRODUCT_QUERY_TAG],
    }),
    createNewProduct: builder.mutation<any, ProductPayloadType>({
      query: (body) => ({
        url: "/products",
        method: "POST",
        body,
      }),
      invalidatesTags: (_, error) => (error ? [] : []),
    }),
    editProduct: builder.mutation<
      any,
      {
        id: string | number;
        body: ProductPayloadType;
      }
    >({
      query: ({ id, body }) => ({
        url: `/products/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (_, error) => (error ? [] : [queryTagTypes.PRODUCT_QUERY_TAG]),
    }),
    deleteProduct: builder.mutation<any, string | number>({
      query: (id) => ({
        url: `/products/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (_, error) => (error ? [] : [queryTagTypes.PRODUCT_QUERY_TAG]),
    }),
    restoreProduct: builder.mutation<any, string | number>({
      query: (id) => ({
        url: `/products/${id}/restore`,
        method: "POST",
      }),
      invalidatesTags: (_, error) => (error ? [] : [queryTagTypes.PRODUCT_QUERY_TAG]),
    }),
    exportProduct: builder.mutation<
      Blob,
      FilterType & {
        companyId?: number | string;
        warehouseId?: number | string;
        binId?: number | string;
      }
    >({
      query: (params) => ({
        url: `/products/export`,
        method: "POST",
        params: cleanObject(params),
        responseHandler: async (response) => {
          if (!response.ok)
            return {
              message: response.statusText,
            };
          const blobReponse = await response.blob();
          downloadFile(
            blobReponse,
            `Product_${dayjs().format("DD_MM_HH_MM")}${params?.companyId ? `_company_${params?.companyId}` : ""}${
              params?.warehouseId ? `_warehouse_${params?.warehouseId}` : ""
            }.xlsx`
          );
          return { data: null };
        },
        cache: "no-cache",
      }),
      invalidatesTags: [],
    }),
  }),
});

export default productApi;
export const {
  useGetProductListQuery,
  useGetWarningProductListQuery,
  useGetWarningProductListWarehouseQuery,
  useGetProductDetailQuery,
  useCreateNewProductMutation,
  useDeleteProductMutation,
  useRestoreProductMutation,
  useEditProductMutation,
  useExportProductMutation,
} = productApi;
