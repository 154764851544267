import { Col, Row, notification } from "antd";
import { Link, useHistory, useLocation } from "react-router-dom";
import LogoIcon from "@/assets/icons/Logo";
import routes from "@/routes";
import ButtonRectangle from "@/components/ButtonRectangle";
import styles from "../../orderManagement.module.less";
import { useTypedSelector } from "@/app/store";
import { CompanySelectType } from "../../orderManagement.slice";
import { useState } from "react";
import { LeftOutlined } from "@ant-design/icons";
import { getError } from "@/utils/helpers";
import CompanyDropdownList from "@/components/FilterSelectCustom/CompanyDropdownList";
import WarehouseCodeDropdownList from "@/components/FilterSelectCustom/WarehouseCodeDropdownList";
import { useExportOrdersMutation } from "@/rtk-query/orderQueries";
import { WarehouseType } from "@/utils/types";

interface IFilterComponentProps {
  type: "create" | "edit" | "view" | "list";
  onSelectCompany?: (value: CompanySelectType) => void;
  onSelectWareHouse?: (value: any | null) => void;
}

function FilterComponent({ type, onSelectCompany, onSelectWareHouse }: IFilterComponentProps) {
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const binId = query.get("binId");

  const [searchCompany, setSearchCompany] = useState("");
  const [searchWarehouse, setSearchWarehouse] = useState("");
  const [defaultWareHouseSelected, setDefaultWareHouseSelected] = useState<WarehouseType | null>(null);

  const { companySelected, wareHouseSelected } = useTypedSelector((store) => store.quickOrderSlide);

  const [exportOrdersApi, { isLoading: isLoadingExportOrdersApi }] = useExportOrdersMutation();

  const handleExportOrders = async () => {
    try {
      await exportOrdersApi({
        companyId: companySelected?.id || "",
        isLoading: undefined,
      }).unwrap();
    } catch (error) {
      notification.error({
        key: "order_export",
        message: getError(error),
      });
    }
  };

  const renderTitle = () => {
    if (binId) return "View bin";

    let title = "";
    switch (type) {
      case "create":
        title = routes.createWarehouse.title;
        break;
      case "edit":
        title = routes.editWarehouse.title;
        break;
      case "view":
        title = routes.orderRecordList.title;
        break;
      default:
        title = routes.orderList.title;
        break;
    }
    return title;
  };

  const shouldDisableFilter = ["edit", "view"].includes(type);

  return (
    <Row justify="space-between" align="middle" className={styles.filterArea}>
      <Col
        flex={1}
        style={{
          height: "100%",
        }}
      >
        <div className={styles.leftContent}>
          {(binId || type === "create") && (
            <div
              className="center-element"
              style={{
                width: 42,
                background: "#D3D7E1",
                height: "100%",
                cursor: "pointer",
              }}
              onClick={() => history.push(type === "create" ? routes.warehouseList.path : location.pathname)}
            >
              <LeftOutlined />
            </div>
          )}
          <div className={styles.logoWrapper}>
            {!binId && type !== "create" && <LogoIcon />}
            <span
              style={{
                fontSize: 14,
                color: "#234C66",
                fontWeight: 700,
              }}
            >
              {renderTitle()}
            </span>
          </div>
          {!shouldDisableFilter && (
            <div
              style={{
                display: "flex",
                gap: 16,
              }}
            >
              <CompanyDropdownList
                value={companySelected?.companyName || (companySelected?.id ? JSON.stringify(companySelected) : null)}
                search={searchCompany}
                onChange={(value: any) => {
                  onSelectCompany?.(JSON.parse(value || "null"));
                  setSearchCompany("");
                }}
                disabled={shouldDisableFilter}
              />
              <WarehouseCodeDropdownList
                value={wareHouseSelected?.identityCode || null}
                search={searchWarehouse}
                onChange={() => {
                  setSearchWarehouse("");
                }}
                onChangeWareHouse={onSelectWareHouse}
                disabled={shouldDisableFilter || type === "create"}
                filter={{
                  companyId: companySelected?.id,
                }}
                skipByCompany={!companySelected?.id}
                onGetFirstWareHouse={setDefaultWareHouseSelected}
              />
            </div>
          )}
        </div>
      </Col>
      {type === "list" && (
        <Col
          style={{
            height: "100%",
          }}
        >
          <div className={styles.rightContent}>
            <Link to={routes.createOrder.path}>
              <ButtonRectangle
                onClick={() => {
                  !wareHouseSelected && onSelectWareHouse?.(defaultWareHouseSelected);
                }}
              >
                Add new order
              </ButtonRectangle>
            </Link>

            <ButtonRectangle
              style={{
                background: "#8491A6",
              }}
              onClick={handleExportOrders}
              disabled={isLoadingExportOrdersApi}
            >
              Export Excel file
            </ButtonRectangle>
          </div>
        </Col>
      )}
    </Row>
  );
}

export default FilterComponent;
