import { IconProps } from "@/utils/types";

function SwapIcon({ color = "black", style }: IconProps) {
  return (
    <svg
      width="21"
      height="24"
      viewBox="0 0 21 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M19.3808 7.2414C19.0288 6.46101 18.5307 5.77083 17.8868 5.17086C17.2413 4.56796 16.5013 4.10148 15.6666 3.77143C14.8011 3.42964 13.8946 3.25874 12.947 3.25874H7.45924V0L0 5.4327L7.45924 10.8654V7.60446H12.947C13.5895 7.60446 14.1388 7.81717 14.595 8.24257C15.0483 8.66651 15.2749 9.17846 15.2749 9.77842V11.6113H19.9309V9.77842C19.9309 8.89534 19.7475 8.04967 19.3808 7.2414ZM0.173829 14.1704C0.173829 15.0534 0.355726 15.8991 0.71952 16.7074C1.07011 17.4878 1.56593 18.178 2.20697 18.7779C2.84947 19.3808 3.5866 19.8473 4.41833 20.1774C5.27941 20.5191 6.18156 20.69 7.12478 20.69H12.5905V23.9488L20.0167 18.5161L12.5905 13.0834V16.3443H7.12478C6.48521 16.3443 5.93879 16.1316 5.48551 15.7062C5.0337 15.2823 4.8078 14.7703 4.8078 14.1704V12.3375H0.173829V14.1704Z"
        fill={color}
      />
    </svg>
  );
}
export default SwapIcon;
