import { IconProps } from "@/utils/types";

function EditIcon({ color = "black", style }: IconProps) {
  return (
    <svg
      width="21"
      height="23"
      viewBox="0 0 21 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M0 18.209V23H4.37439L17.2759 8.86974L12.9015 4.07874L0 18.209ZM20.6588 5.1647C21.1137 4.66644 21.1137 3.86155 20.6588 3.36328L17.9292 0.373698C17.4742 -0.124566 16.7393 -0.124566 16.2844 0.373698L14.1497 2.71171L18.5241 7.50271L20.6588 5.1647Z"
        fill={color}
      />
    </svg>
  );
}
export default EditIcon;
