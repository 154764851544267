import { CSSProperties } from "react";
import PersonIcon from "@/assets/icons/PersonIcon";
import styles from "./avatarCircle.module.less";

interface IAvatarCircleCustomProps {
  src?: string;
  style?: CSSProperties;
}

function AvatarCircleCustom({ src, style }: IAvatarCircleCustomProps) {
  return (
    <div className={styles.avatarCustomWrapper} style={style}>
      {src ? <img src={src} alt="avatar" /> : <PersonIcon />}
    </div>
  );
}

export default AvatarCircleCustom;
