import { IconProps } from "@/utils/types";

function DownloadIcon({ color = "black", style }: IconProps) {
  return (
    <svg
      width="23"
      height="25"
      viewBox="0 0 23 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M17.6691 10.967C17.5473 10.6978 17.2796 10.5264 16.9849 10.5264H13.9774V0.751884C13.9774 0.336844 13.6405 0 13.2255 0H10.2179C9.8029 0 9.46606 0.336844 9.46606 0.751884V10.5264H6.45852C6.16378 10.5264 5.89611 10.6993 5.7743 10.967C5.651 11.2362 5.69912 11.5504 5.8931 11.773L11.1563 17.7881C11.2992 17.952 11.5052 18.0452 11.7217 18.0452C11.9383 18.0452 12.1443 17.9505 12.2871 17.7881L17.5503 11.773C17.7458 11.552 17.7909 11.2362 17.6691 10.967Z"
        fill={color}
      />
      <path
        d="M19.9924 16.541V21.0523H3.4509V16.541H0.443359V22.5561C0.443359 23.3877 1.11705 24.0599 1.94713 24.0599H21.4961C22.3277 24.0599 22.9999 23.3877 22.9999 22.5561V16.541H19.9924Z"
        fill={color}
      />
    </svg>
  );
}
export default DownloadIcon;
