import TableCustom from "@/components/TableCustom";
import { Pagination, Row, Typography } from "antd";
import { InfoFileImport, InfoImportFile, masterProductColumns, siteProductColumns } from "../../excelImport.constants";
import { useEffect, useState } from "react";
import { useGetDetailFileDataImportQuery } from "@/rtk-query/fileImportQueries";
import { checkTotalAllItem } from "@/utils/helpers";
import styles from "../../excelImport.module.less";

const { Title } = Typography;

interface IFileImportDetailProps {
  fileId: string | number | null;
}

const FileImportDetail = ({ fileId }: IFileImportDetailProps) => {
  const [page, setPage] = useState(1);
  const [infoFile, setInfoFile] = useState<InfoFileImport>({
    fileName: "",
    totalNumberOfProduct: 0,
    numberUploadedSuccess: 0,
    numberUploadedFailed: 0,
  });

  const { currentData, isFetching, refetch } = useGetDetailFileDataImportQuery(
    {
      fileImportId: fileId || "",
      page,
      pageSize: 100,
      sortBy: "id",
      sortOrder: "asc",
      isLoading: undefined,
    },
    {
      skip: !fileId || fileId === "null" || fileId === "undefined",
    }
  );

  useEffect(() => {
    let uploadedSuccess = 0;
    if (currentData) {
      uploadedSuccess = currentData.total - currentData.numberImportFail;
    }
    const dataInfo: InfoFileImport = {
      fileName: currentData?.fileImport.name,
      totalNumberOfProduct: currentData?.total,
      numberUploadedSuccess: uploadedSuccess,
      numberUploadedFailed: currentData?.numberImportFail,
    };
    setInfoFile(dataInfo);
  }, [isFetching]);

  useEffect(() => {
    refetch();
  }, [page]);

  return (
    <div className={styles.detailWrapper}>
      <div>
        <InfoImportFile data={infoFile} />
      </div>

      <div>
        <Title
          level={5}
          style={{
            marginBottom: 20,
            color: "#234C66",
          }}
        >
          RESULTS
        </Title>
        <TableCustom
          columns={currentData?.fileImport?.type === 1 ? masterProductColumns({ page }) : siteProductColumns({ page })}
          data={currentData?.data || []}
          loading={isFetching}
          total={checkTotalAllItem({ page })}
        />
        {(currentData?.totalPage || 0) > 1 && (
          <Row
            justify="end"
            style={{
              marginTop: 24,
            }}
          >
            <Pagination
              current={currentData?.page}
              total={currentData?.totalPage || 0}
              pageSize={1}
              onChange={(page) => {
                const contentElement: any = document.getElementById("content");
                contentElement.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
                setPage(page);
              }}
              showSizeChanger={false}
            />
          </Row>
        )}
      </div>
    </div>
  );
};

export default FileImportDetail;
