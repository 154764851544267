import { IconProps } from "@/utils/types";

function BoxIcon({ color = "#8491A6", style }: IconProps) {
  return (
    <svg
      width="30"
      height="26"
      viewBox="0 0 30 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M0 9.94244L10.8292 16.1425L13.6426 12.6126L2.23968 6.5918L0 9.94244Z"
        fill={color}
      />
      <path
        d="M16.3271 12.5998L19.1509 16.1429L30 9.93144L27.7299 6.34766L16.3271 12.5998Z"
        fill={color}
      />
      <path
        d="M3.8833 5.47282L14.9825 11.3333L26.0975 5.23884L15.0066 0L3.8833 5.47282Z"
        fill={color}
      />
      <path
        d="M2.82764 19.4259L14.112 25.3842V14.8409L11.7329 17.826C11.4629 18.1648 10.9863 18.256 10.6103 18.0407L2.82764 13.585V19.4259Z"
        fill={color}
      />
      <path
        d="M15.8682 14.841V25.3569L27.1525 19.161V13.585L19.3699 18.0407C18.9939 18.2559 18.5173 18.1648 18.2473 17.826L15.8682 14.841Z"
        fill={color}
      />
    </svg>
  );
}
export default BoxIcon;
