import { Col, Dropdown, MenuProps, Row, notification } from "antd";
import { Link, useHistory } from "react-router-dom";
import LogoIcon from "@/assets/icons/Logo";
import routes from "@/routes";
import ButtonRectangle from "@/components/ButtonRectangle";
import styles from "@/modules/MasterProductManagement/masterProductManagement.module.less";
import clsx from "clsx";
import { useExportMasterProductMutation } from "@/rtk-query/masterProductQueries";
import { useCheckPermission, getError } from "@/utils/helpers";
import C from "@/utils/constants";
import PrevIcon from "@/assets/icons/PrevIcon";

interface IFilterComponentProps {
  type: "view" | "create" | "edit" | "list";
}

function FilterComponent({ type }: IFilterComponentProps) {
  const history = useHistory();
  const [exportMasterProductApi] = useExportMasterProductMutation();

  const handleExportMasterProduct = async () => {
    try {
      await exportMasterProductApi({}).unwrap();
    } catch (error) {
      notification.error({
        message: getError(error),
        key: "master_product",
      });
    }
  };

  const items: MenuProps["items"] = [
    {
      label: "Import file",
      key: "1",
      onClick: () => history.push(routes.masterProductImportFile.path),
    },
    {
      label: "Download template",
      key: "2",
      onClick: () => {
        const link = document.createElement("a");
        link.href = `/files/master_product_template.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
    },
  ];

  const menuProps = {
    items,
  };

  const renderTitle = () => {
    let title = "";
    switch (type) {
      case "create":
        title = routes.createMasterProduct.title;
        break;
      case "edit":
        title = routes.editMasterProduct.title;
        break;
      case "view":
        title = routes.viewMasterProduct.title;
        break;
      default:
        title = routes.masterProductList.title;
        break;
    }
    return title;
  };

  const isListType = type === "list";

  const canCreate = useCheckPermission(C.PERMISSIONS.MASTER_PRODUCT_CREATE);
  const canImport = useCheckPermission(C.PERMISSIONS.MASTER_PRODUCT_IMPORT);
  const canExport = useCheckPermission(C.PERMISSIONS.MASTER_PRODUCT_EXPORT);
  return (
    <Row justify="space-between" align="middle" className={styles.filterArea}>
      <Col
        flex={1}
        style={{
          height: "100%",
        }}
      >
        <div className={styles.leftContent}>
          {!isListType && (
            <div
              className="center-element"
              style={{
                width: 42,
                background: "#D3D7E1",
                height: "100%",
                cursor: "pointer",
              }}
              onClick={() => history.goBack()}
            >
              <PrevIcon />
            </div>
          )}
          <div className={styles.logoWrapper}>
            {isListType && <LogoIcon />}
            <span
              style={{
                fontSize: 14,
                color: "#234C66",
                fontWeight: 700,
              }}
            >
              {renderTitle()}
            </span>
          </div>
        </div>
      </Col>
      {isListType && (
        <Col
          style={{
            height: "100%",
          }}
        >
          <div className={styles.rightContent}>
            {canCreate && (
              <Link to={routes.createMasterProduct.path}>
                <ButtonRectangle
                  style={{
                    height: "100%",
                  }}
                >
                  Add new product
                </ButtonRectangle>
              </Link>
            )}

            {canImport && (
              <Dropdown
                menu={menuProps}
                trigger={["click"]}
                // eslint-disable-next-line
                dropdownRender={(menu) => <div className={clsx(styles.export_group_button)}>{menu}</div>}
              >
                <div
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <ButtonRectangle
                    style={{
                      background: "#07657A",
                      height: 36,
                    }}
                  >
                    Import Excel file
                  </ButtonRectangle>
                </div>
              </Dropdown>
            )}

            {canExport && (
              <ButtonRectangle
                style={{
                  background: "#8491A6",
                }}
                onClick={handleExportMasterProduct}
              >
                Export Excel file
              </ButtonRectangle>
            )}
          </div>
        </Col>
      )}
    </Row>
  );
}

export default FilterComponent;
