import { Modal, Image, Typography, Button } from "antd";
import DangerousIcon from "@/assets/icons/dangerous.svg";
import { useTypedSelector } from "@/app/store";
import CloseIcon from "@/assets/icons/CloseIcon";

const { Title, Text } = Typography;

const DialogConfirmationModule = () => {
  const {
    isOpen,
    type,
    title,
    description,
    okStyle,
    okText,
    onOk,
    cancelStyle,
    cancelText,
    onCancel,
    showCloseIcon,
  } = useTypedSelector((store) => store.dialogConfirmationSlide);

  const getStyleConfirm = () => {
    let icon;
    let style;
    switch (type) {
      case "success":
        icon = null;
        style = {};
        break;
      case "dangerous":
        icon = <Image src={DangerousIcon} preview={false} />;
        style = {
          color: "#B72424",
        };
        break;
      case "warning":
        icon = null;
        style = {};
        break;
      default:
        icon = null;
        style = {};
        break;
    }
    return {
      icon,
      style,
    };
  };

  return (
    <Modal
      open={isOpen}
      footer={null}
      closable={false}
      maskClosable={false}
      centered
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 20,
          alignItems: "center",
        }}
      >
        {getStyleConfirm().icon}
        <Title
          level={4}
          style={{
            margin: 0,
            ...getStyleConfirm().style,
          }}
        >
          {title}
        </Title>
        <Text
          italic
          style={{
            margin: 0,
            ...getStyleConfirm().style,
          }}
        >
          {description}
        </Text>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: okText && cancelText ? "space-around" : "flex-end",
            alignItems: "center",
          }}
        >
          {cancelText && (
            <Button
              size="large"
              type="primary"
              style={{
                background: "#8C8888",
                margin: 0,
                padding: "0 30px",
                ...cancelStyle,
              }}
              onClick={onCancel}
            >
              {cancelText}
            </Button>
          )}

          {okText && (
            <Button
              size="large"
              type="primary"
              style={{
                margin: 0,
                padding: "0 30px",
                ...okStyle,
              }}
              onClick={onOk}
            >
              {okText}
            </Button>
          )}
        </div>
      </div>
      {showCloseIcon && (
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            padding: 16,
            cursor: "pointer",
          }}
          onClick={onCancel}
        >
          <CloseIcon
            style={{
              width: 14,
            }}
          />
        </div>
      )}
    </Modal>
  );
};

export default DialogConfirmationModule;
