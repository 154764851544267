import { IconProps } from "@/utils/types";

function WareHouseIcon({ color = "#8491A6", style }: IconProps) {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M26.6355 8.86725L13.9793 0.1485C13.6913 -0.0495 13.311 -0.0495 13.0219 0.1485L0.365625 8.86725C0.136125 9.02475 0 9.28463 0 9.5625V25.875C0 26.496 0.504 27 1.125 27H3.375V13.5C3.375 12.879 3.879 12.375 4.5 12.375H22.5C23.121 12.375 23.625 12.879 23.625 13.5V27H25.875C26.496 27 27 26.496 27 25.875V9.5625C27 9.28463 26.8639 9.02475 26.6355 8.86725Z"
        fill={color}
      />
      <path
        d="M10.4142 21.4463V22.2794C10.4142 22.7393 10.0341 23.1126 9.56567 23.1126C9.09726 23.1126 8.7171 22.7393 8.7171 22.2794V21.4463H6.7371C6.42483 21.4463 6.17139 21.6951 6.17139 22.0017V26.4451C6.17139 26.7517 6.42483 27.0006 6.7371 27.0006H12.3942C12.7065 27.0006 12.96 26.7517 12.96 26.4451V22.0017C12.96 21.6951 12.7065 21.4463 12.3942 21.4463H10.4142Z"
        fill={color}
      />
      <path
        d="M18.2829 21.4463V22.2794C18.2829 22.7393 17.9027 23.1126 17.4343 23.1126C16.9659 23.1126 16.5858 22.7393 16.5858 22.2794V21.4463H14.6058C14.2935 21.4463 14.04 21.6951 14.04 22.0017V26.4451C14.04 26.7517 14.2935 27.0006 14.6058 27.0006H20.2629C20.5752 27.0006 20.8286 26.7517 20.8286 26.4451V22.0017C20.8286 21.6951 20.5752 21.4463 20.2629 21.4463H18.2829Z"
        fill={color}
      />
      <path
        d="M14.329 14.6572V15.4904C14.329 15.9503 13.9575 16.3235 13.4998 16.3235C13.042 16.3235 12.6705 15.9503 12.6705 15.4904V14.6572H10.7355C10.4303 14.6572 10.1826 14.9061 10.1826 15.2127V19.6561C10.1826 19.9627 10.4303 20.2115 10.7355 20.2115H16.264C16.5692 20.2115 16.8169 19.9627 16.8169 19.6561V15.2127C16.8169 14.9061 16.5692 14.6572 16.264 14.6572H14.329Z"
        fill={color}
      />
    </svg>
  );
}
export default WareHouseIcon;
