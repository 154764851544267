import { Pagination, Row, Typography } from "antd";
import { useParams } from "react-router";
import { useState } from "react";
import ButtonRectangle from "@/components/ButtonRectangle";
import TableCustom from "@/components/TableCustom";
import styles from "../../clientCUR.module.less";
import { useTypedDispatch } from "@/app/store";
import { dialogUserCURSlide } from "@/modules/DialogUserCUR/dialogUserCUR.slice";
import { useGetUserListQuery } from "@/rtk-query/userQueries";
import { userColumns } from "../../clientCUR.constants";
import { checkTotalAllItem } from "@/utils/helpers";
import C from "@/utils/constants";

const { Title } = Typography;

function UserList() {
  const params: any = useParams();
  const dispatch = useTypedDispatch();
  const { updateClientCURState } = dialogUserCURSlide.actions;
  const [page, setPage] = useState(1);

  const { currentData, isFetching: isFetchingUserList } = useGetUserListQuery(
    {
      companyId: params?.id,
      page,
      pageSize: C.PAGE_SIZE_LITTLE,
      isLoading: undefined,
    },
    {
      skip: !params?.id,
    }
  );
  const userList = currentData?.data || [];

  const handleOpenUpdateUser = (type: "create" | "edit", id?: string | number) => {
    dispatch(
      updateClientCURState({
        type,
        id,
        isOpen: true,
        companyId: params?.id,
      })
    );
  };

  return (
    <>
      <div className={styles.listUserHeader}>
        <Title
          level={5}
          style={{
            color: "#234C66",
            margin: 0,
          }}
        >
          LIST OF USERS
        </Title>
        <ButtonRectangle
          style={{
            height: 36,
          }}
          onClick={() => handleOpenUpdateUser("create")}
        >
          Add new user
        </ButtonRectangle>
      </div>

      <div className={styles.listUserContent}>
        <TableCustom
          columns={userColumns({
            onEdit: handleOpenUpdateUser,
          })}
          data={userList || []}
          loading={isFetchingUserList}
          total={checkTotalAllItem({ page })}
        />
        {(currentData?.totalPage || 0) > 1 && (
          <Row
            justify="end"
            style={{
              marginTop: 24,
            }}
          >
            <Pagination
              current={page}
              total={currentData?.totalPage || 0}
              pageSize={1}
              onChange={(page) => {
                setPage(page);
              }}
              showSizeChanger={false}
            />
          </Row>
        )}
      </div>
    </>
  );
}

export default UserList;
