import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type DialogType = "create" | "edit";
interface IinitialState {
  isOpen: boolean;
  type?: DialogType;
  id?: string | number | null;
  companyId?: string | number | null;
}

const initialState: IinitialState = {
  isOpen: false,
  type: "create",
  id: null,
  companyId: null,
};

export const dialogUserCURSlide = createSlice({
  name: "dialogUserCURSlide",
  initialState,
  reducers: {
    updateClientCURState(state, action: PayloadAction<IinitialState>) {
      state.isOpen = action.payload.isOpen;
      state.type = action.payload.type || "create";
      state.id = action.payload.id || null;
      state.companyId = action.payload.companyId || null;
    },
  },
});

export const dialogUserCURReducer = dialogUserCURSlide.reducer;
