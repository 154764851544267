import { CSSProperties, ReactNode } from "react";
import ImagebackgroundButtonPrimary from "@/assets/images/background_button.svg";
import ImagebackgroundButtonDark from "@/assets/images/background_button_dark.svg";
import styles from "./buttonNotched.module.less";

interface IButtonNotchedProps {
  type?: "primary" | "dark";
  children?: ReactNode;
  onClick?: () => void;
  style?: CSSProperties;
}

function ButtonNotched({
  type = "primary",
  children = null,
  onClick = () => false,
  style = {},
}: IButtonNotchedProps) {
  const imagebackgroundUrl =
    type === "dark" ? ImagebackgroundButtonDark : ImagebackgroundButtonPrimary;
  return (
    <div
      className={styles.btn_notched}
      style={{
        ...style,
        backgroundImage: `url(${imagebackgroundUrl})`,
      }}
      onClick={onClick}
    >
      {children}
    </div>
  );
}

export default ButtonNotched;
