import { FilterType, MasterProductType, WarehouseType } from "../../utils/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cleanObject } from "@/utils/helpers";
import C from "@/utils/constants";
import { CompanySelectType } from "../WarehouseManagement/warehouseManagement.slice";

export interface IFilterQuickOrder extends FilterType {
  companyId?: string | number | null;
}

interface IinitialState {
  filter: IFilterQuickOrder;
  data: Array<any>;
  companySelected?: CompanySelectType;
  masterProductSelected?: MasterProductType | null;
  wareHouseSelected?: WarehouseType | null;
  productIdSelected?: string | number | null;
}

const initialState: IinitialState = {
  filter: {
    page: 1,
    pageSize: C.PAGE_SIZE_LITTLE,
    isLoading: undefined,
  },
  companySelected: null,
  wareHouseSelected: null,
  data: [],
};

export const quickOrderSlide = createSlice({
  name: "quickOrderSlide",
  initialState,
  reducers: {
    updateFilterState(state, action: PayloadAction<IFilterQuickOrder>) {
      state.filter = cleanObject({
        ...state.filter,
        ...action.payload,
      });
    },
    updateDataState(state, action: PayloadAction<Array<any>>) {
      state.data = action.payload;
    },
    onSelectCompany(state, action: PayloadAction<CompanySelectType>) {
      state.companySelected = action.payload;
      state.wareHouseSelected = null;
    },
    onSelectWareHouse(state, action: PayloadAction<WarehouseType | null>) {
      state.wareHouseSelected = action.payload;
    },
  },
});

export const quickOrderReducer = quickOrderSlide.reducer;
