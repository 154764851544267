import BoxIcon from "@/assets/icons/BoxIcon";
import MultiBoxIcon from "@/assets/icons/MultiBoxIcon";
import OrderIcon from "@/assets/icons/OrderIcon";
import PersonIcon from "@/assets/icons/PersonIcon";
import TagIcon from "@/assets/icons/TagIcon";
import WareHouseIcon from "@/assets/icons/WareHouse";
import FullOrderModule from "@/modules/OrderCUR";
import Custom403Page from "@/pages/403";
import Custom404Page from "@/pages/404";
import Custom500Page from "@/pages/500";
import ChangePasswordPage from "@/pages/auth/change-password";
import LoginPage from "@/pages/auth/login";
import LogoutPage from "@/pages/auth/logout";
import CreateClientPage from "@/pages/clients/create";
import EditClientPage from "@/pages/clients/edit";
import ClientManagementPage from "@/pages/clients/list";
import ViewClientPage from "@/pages/clients/view";
import HomePage from "@/pages/master-product-management";
import CreateMasterProductPage from "@/pages/master-product-management/create";
import EditMasterProductPage from "@/pages/master-product-management/edit";
import ProductImportPage from "@/pages/import-excel-file";
import ViewMasterProductPage from "@/pages/master-product-management/view";
import OrderManagementPage from "@/pages/order-management/list";
import RecordManagementPage from "@/pages/order-management/records";
import SiteProductCreatePage from "@/pages/product-management/create";
import SiteProductEditPage from "@/pages/product-management/edit";
import ProductManagementPage from "@/pages/product-management/list";
import SiteProductViewPage from "@/pages/product-management/view";
import QuickOrderPage from "@/pages/quick-order";
import CreateWarehousePage from "@/pages/warehouses/create";
import EditWarehousePage from "@/pages/warehouses/edit";
import WarehouseManagementPage from "@/pages/warehouses/list";
import ViewWarehousePage from "@/pages/warehouses/view";
import C from "@/utils/constants";
import { ReactElement, ReactNode } from "react";
import ViewOrderPage from "@/pages/order-management/view";
import EditOrderPage from "@/pages/order-management/edit";
import CreateOrderPage from "@/pages/order-management/create";
import ViewRecordPage from "@/pages/order-management/view-record";
import UserProfilePage from "@/pages/user/view";
import EditUserProfilePage from "@/pages/user/edit";

const WIDTH_ICON = 18;

type RouterType = {
  path: string;
  title: string;
  tag: string;
  icon: (props?: any) => ReactNode;
  component: () => ReactElement;
  layout: "admin" | "auth";
  sideBar?: boolean;
  screenAction?: typeof C.PERMISSIONS[keyof typeof C.PERMISSIONS];
};

const routes: {
  [key: string]: RouterType;
} = {
  login: {
    path: "/login",
    title: "Login",
    tag: "auth",
    icon: () => null,
    component: () => <LoginPage />,
    layout: "auth",
  },
  changePassword: {
    path: "/change-password",
    title: "Change password",
    tag: "auth",
    icon: () => null,
    component: () => <ChangePasswordPage />,
    layout: "auth",
  },
  logout: {
    path: "/logout",
    title: "Logout",
    tag: "auth",
    icon: () => null,
    component: () => <LogoutPage />,
    layout: "auth",
  },
  403: {
    path: "/403",
    title: "Permission denied",
    tag: "auth",
    icon: () => null,
    component: () => <Custom403Page />,
    layout: "auth",
  },
  404: {
    path: "/404",
    title: "Page not found",
    tag: "auth",
    icon: () => null,
    component: () => <Custom404Page />,
    layout: "auth",
  },
  500: {
    path: "/500",
    title: "Something went wrong",
    tag: "auth",
    icon: () => null,
    component: () => <Custom500Page />,
    layout: "auth",
  },

  masterProductImportFile: {
    path: "/master-products/import-excel-file",
    title: "Import by excel file",
    tag: "master-products",
    icon: ({ color }: { color: string }) => <MultiBoxIcon color={color} style={{ width: WIDTH_ICON }} />,
    component: () => <ProductImportPage />,
    layout: "admin",
    screenAction: C.PERMISSIONS.MASTER_PRODUCT_IMPORT,
  },
  createMasterProduct: {
    path: "/master-products/create",
    title: "Add new product",
    tag: "master-products",
    icon: ({ color }: { color: string }) => <MultiBoxIcon color={color} style={{ width: WIDTH_ICON }} />,
    component: () => <CreateMasterProductPage />,
    layout: "admin",
    screenAction: C.PERMISSIONS.MASTER_PRODUCT_CREATE,
  },
  editMasterProduct: {
    path: "/master-products/:id/edit",
    title: "Edit product",
    tag: "master-products",
    icon: ({ color }: { color: string }) => <MultiBoxIcon color={color} style={{ width: WIDTH_ICON }} />,
    component: () => <EditMasterProductPage />,
    layout: "admin",
    screenAction: C.PERMISSIONS.MASTER_PRODUCT_EDIT,
  },
  viewMasterProduct: {
    path: "/master-products/:id",
    title: "View product",
    tag: "master-products",
    icon: ({ color }: { color: string }) => <MultiBoxIcon color={color} style={{ width: WIDTH_ICON }} />,
    component: () => <ViewMasterProductPage />,
    layout: "admin",
    screenAction: C.PERMISSIONS.MASTER_PRODUCT_READ,
  },
  masterProductList: {
    path: "/master-products",
    title: "Master product",
    tag: "master-products",
    icon: ({ color }: { color: string }) => <MultiBoxIcon color={color} style={{ width: WIDTH_ICON }} />,
    component: () => <HomePage />,
    layout: "admin",
    sideBar: true,
  },
  createSiteProduct: {
    path: "/site-products/create",
    title: "Add new site product",
    tag: "site-products",
    icon: ({ color }: { color: string }) => <PersonIcon color={color} style={{ width: WIDTH_ICON }} />,
    component: () => <SiteProductCreatePage />,
    layout: "admin",
  },
  viewSiteProduct: {
    path: "/site-products/:id/view",
    title: "View Site Product",
    tag: "site-products",
    icon: ({ color }: { color: string }) => <PersonIcon color={color} style={{ width: WIDTH_ICON }} />,
    component: () => <SiteProductViewPage />,
    layout: "admin",
  },
  productImportFile: {
    path: "/site-products/import-excel-file",
    title: "Import by excel file",
    tag: "site-products",
    icon: ({ color }: { color: string }) => <MultiBoxIcon color={color} style={{ width: WIDTH_ICON }} />,
    component: () => <ProductImportPage />,
    layout: "admin",
    screenAction: C.PERMISSIONS.MASTER_PRODUCT_IMPORT,
  },
  editSiteProduct: {
    path: "/site-products/:id/edit",
    title: "Edit Site Product",
    tag: "site-products",
    icon: ({ color }: { color: string }) => <PersonIcon color={color} style={{ width: WIDTH_ICON }} />,
    component: () => <SiteProductEditPage />,
    layout: "admin",
  },
  siteProductList: {
    path: "/site-products",
    title: "Site product management",
    tag: "site-products",
    icon: ({ color }: { color: string }) => <BoxIcon color={color} style={{ width: WIDTH_ICON }} />,
    component: () => <ProductManagementPage />,
    layout: "admin",
    sideBar: true,
  },
  fullOrder: {
    path: "/quick-order/full-order",
    title: "Full order",
    tag: "full order",
    icon: ({ color }: { color: string }) => <TagIcon color={color} style={{ width: WIDTH_ICON }} />,
    component: () => <FullOrderModule type="full" />,
    layout: "admin",
  },
  quickOrderList: {
    path: "/quick-order",
    title: "Quick order",
    tag: "quick-order",
    icon: ({ color }: { color: string }) => <TagIcon color={color} style={{ width: WIDTH_ICON }} />,
    component: () => <QuickOrderPage />,
    layout: "admin",
    sideBar: true,
  },
  viewRecord: {
    path: "/orders/:id/records/:recordId",
    title: "View record",
    tag: "orders",
    icon: ({ color }: { color: string }) => <OrderIcon color={color} style={{ width: WIDTH_ICON }} />,
    component: () => <ViewRecordPage />,
    layout: "admin",
    sideBar: false,
  },
  orderRecordList: {
    path: "/orders/:id/records",
    title: "View record",
    tag: "orders",
    icon: ({ color }: { color: string }) => <OrderIcon color={color} style={{ width: WIDTH_ICON }} />,
    component: () => <RecordManagementPage />,
    layout: "admin",
    sideBar: false,
  },
  createOrder: {
    path: "/orders/create",
    title: "Create new order",
    tag: "orders",
    icon: ({ color }: { color: string }) => <OrderIcon color={color} style={{ width: WIDTH_ICON }} />,
    component: () => <CreateOrderPage />,
    layout: "admin",
  },
  editOrder: {
    path: "/orders/:id/edit",
    title: "Edit order",
    tag: "orders",
    icon: ({ color }: { color: string }) => <OrderIcon color={color} style={{ width: WIDTH_ICON }} />,
    component: () => <EditOrderPage />,
    layout: "admin",
  },
  viewOrder: {
    path: "/orders/:id/view",
    title: "View order",
    tag: "orders",
    icon: ({ color }: { color: string }) => <OrderIcon color={color} style={{ width: WIDTH_ICON }} />,
    component: () => <ViewOrderPage />,
    layout: "admin",
  },
  orderList: {
    path: "/orders",
    title: "Order management",
    tag: "orders",
    icon: ({ color }: { color: string }) => <OrderIcon color={color} style={{ width: WIDTH_ICON }} />,
    component: () => <OrderManagementPage />,
    layout: "admin",
    sideBar: true,
  },
  createWarehouse: {
    path: "/warehouse-management/create",
    title: "Add new warehouse",
    tag: "warehouse-management",
    icon: ({ color }: { color: string }) => <PersonIcon color={color} style={{ width: WIDTH_ICON }} />,
    component: () => <CreateWarehousePage />,
    layout: "admin",
    screenAction: C.PERMISSIONS.WAREHOUSE_CREATE,
  },
  viewWarehouse: {
    path: "/warehouse-management/:id/view",
    title: "View Warehouse",
    tag: "warehouse-management",
    icon: ({ color }: { color: string }) => <PersonIcon color={color} style={{ width: WIDTH_ICON }} />,
    component: () => <ViewWarehousePage />,
    layout: "admin",
    screenAction: C.PERMISSIONS.WAREHOUSE_READ,
  },
  editWarehouse: {
    path: "/warehouse-management/:id/edit",
    title: "Edit Warehouse",
    tag: "warehouse-management",
    icon: ({ color }: { color: string }) => <PersonIcon color={color} style={{ width: WIDTH_ICON }} />,
    component: () => <EditWarehousePage />,
    layout: "admin",
    screenAction: C.PERMISSIONS.WAREHOUSE_EDIT,
  },
  warehouseList: {
    path: "/warehouse-management",
    title: "Warehouse management",
    tag: "warehouse-management",
    icon: ({ color }: { color: string }) => <WareHouseIcon color={color} style={{ width: WIDTH_ICON }} />,
    component: () => <WarehouseManagementPage />,
    layout: "admin",
    sideBar: true,
  },
  createClient: {
    path: "/client-management/create",
    title: "Add new client",
    tag: "client-management",
    icon: ({ color }: { color: string }) => <PersonIcon color={color} style={{ width: WIDTH_ICON }} />,
    component: () => <CreateClientPage />,
    layout: "admin",
    screenAction: C.PERMISSIONS.COMPANY_CREATE,
  },
  viewClient: {
    path: "/client-management/:id/view",
    title: "View client",
    tag: "client-management",
    icon: ({ color }: { color: string }) => <PersonIcon color={color} style={{ width: WIDTH_ICON }} />,
    component: () => <ViewClientPage />,
    layout: "admin",
    screenAction: C.PERMISSIONS.COMPANY_READ,
  },
  editClient: {
    path: "/client-management/:id/edit",
    title: "Edit client",
    tag: "client-management",
    icon: ({ color }: { color: string }) => <PersonIcon color={color} style={{ width: WIDTH_ICON }} />,
    component: () => <EditClientPage />,
    layout: "admin",
    screenAction: C.PERMISSIONS.COMPANY_EDIT,
  },
  clientList: {
    path: "/client-management",
    title: "Client management",
    tag: "client-management",
    icon: ({ color }: { color: string }) => <PersonIcon color={color} style={{ width: WIDTH_ICON }} />,
    component: () => <ClientManagementPage />,
    layout: "admin",
    sideBar: true,
    screenAction: C.PERMISSIONS.COMPANY_SEARCH,
  },
  userProfile: {
    path: "/user-profile/view",
    title: "User Profile",
    tag: "user",
    icon: ({ color }: { color: string }) => <PersonIcon color={color} style={{ width: WIDTH_ICON }} />,
    component: () => <UserProfilePage />,
    layout: "admin",
  },
  editUserProfile: {
    path: "/user-profile/edit",
    title: "User Profile",
    tag: "user",
    icon: ({ color }: { color: string }) => <PersonIcon color={color} style={{ width: WIDTH_ICON }} />,
    component: () => <EditUserProfilePage />,
    layout: "admin",
  },
};

export default routes;
