import { IconProps } from "@/utils/types";

function CopyIcon({ color = "black", style }: IconProps) {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M14 0H2C0.9 0 0 0.818182 0 1.81818V14.5455H2V1.81818H14V0ZM13 3.63636L19 9.09091V18.1818C19 19.1818 18.1 20 17 20H5.99C4.89 20 4 19.1818 4 18.1818L4.01 5.45455C4.01 4.45455 4.9 3.63636 6 3.63636H13ZM12 10H17.5L12 5V10Z"
        fill={color}
      />
    </svg>
  );
}
export default CopyIcon;
