import { Select, Spin } from "antd";
import commonStyles from "@/styles/common.module.less";
import clsx from "clsx";
import { CSSProperties, useEffect, useState } from "react";
import useDebounce from "@/hooks/useDebounce";
import { BaseOptionType } from "antd/es/select";
import { useGetWarehouseListQuery } from "@/rtk-query/warehouseQueries";
import styles from "./select.module.less";
import { WarehouseType } from "@/utils/types";
import { SizeType } from "antd/es/config-provider/SizeContext";

interface IWarehouseCodeDropdownListProps {
  value: string | number | null;
  onChange: (value: any) => void;
  disabled?: boolean;
  filter: any;
  search?: string;
  skipByCompany?: boolean;
  onChangeWareHouse?: (data?: any) => void;
  onGetFirstWareHouse?: (value: WarehouseType | null) => void;
  style?: CSSProperties;
  size?: SizeType;
  className?: string;
  placeholder?: string;
}

const WarehouseCodeDropdownList = ({
  value,
  onChange,
  disabled,
  filter = {},
  search = "",
  skipByCompany,
  onChangeWareHouse = () => {},
  style,
  size = "large",
  className,
  placeholder = "Warehouse code...",
  onGetFirstWareHouse = () => {},
}: IWarehouseCodeDropdownListProps) => {
  const [searchWarehouse, setSearchWarehouse] = useState("");
  const searchWarehouseDebounce = useDebounce(searchWarehouse, 300);
  const [page, setPage] = useState(1);
  const [data, setData] = useState<Array<WarehouseType>>([]);

  const { currentData: warehouseList, isFetching: isFetchingWarehouse } = useGetWarehouseListQuery(
    {
      ...filter,
      page,
      search: searchWarehouseDebounce,
    },
    {
      skip: skipByCompany && !filter?.companyId,
    }
  );

  // Scroll infinite =========
  const onScroll = async (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    if (
      !isFetchingWarehouse &&
      event.target.scrollTop + event.target.offsetHeight === event.target.scrollHeight &&
      (warehouseList?.totalPage || 1) > page
    ) {
      setPage(page + 1);
    }
  };
  // ================

  useEffect(() => {
    setSearchWarehouse(search);
  }, [search]);

  useEffect(() => {
    if (isFetchingWarehouse || !warehouseList) return;
    page === 1 ? setData(warehouseList?.data || []) : setData([...data, ...(warehouseList?.data || [])]);
  }, [isFetchingWarehouse, warehouseList]);

  useEffect(() => {
    data[0] && onGetFirstWareHouse(data[0]);
  }, [data?.[0]]);
  const dropdownStyle = {
    background: "#dfe3ef",
    padding: 0,
    borderRadius: 0,
    boxShadow: "inset 4px 4px 3px rgba(0, 0, 0, 0.03)",
    fontSize: 12,
    fontWeight: 600,
  };

  return (
    <Select
      style={style || { width: 230, background: "#BFCFD9", color: "#234C66" }}
      showSearch
      dropdownStyle={dropdownStyle}
      popupClassName="customDropdownSelectAntd"
      className={style || className ? className : clsx(commonStyles.select, styles.filterSelect)}
      size={size}
      placeholder={placeholder}
      loading={isFetchingWarehouse}
      value={value}
      onSearch={(value) => {
        setPage(1);
        setSearchWarehouse(value);
      }}
      onChange={(_, children: BaseOptionType) => {
        onChange(children?.value);
        onChangeWareHouse(data?.find((e) => e?.identityCode === children?.value));
      }}
      onBlur={() => {
        setSearchWarehouse("");
        setPage(1);
      }}
      allowClear
      onClear={() => onChange(null)}
      disabled={disabled}
      getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}
      onPopupScroll={onScroll}
      filterOption={false}
    >
      {(skipByCompany ? [] : data).map(({ identityCode }: any, index: number) => (
        <Select.Option value={identityCode} key={index}>
          {identityCode}
        </Select.Option>
      ))}
      {isFetchingWarehouse && (
        <Select.Option value="load_more">
          <div className="center-element">
            <Spin />
          </div>
        </Select.Option>
      )}
    </Select>
  );
};

export default WarehouseCodeDropdownList;
