function UploadIcon() {
  return (
    <svg
      width="60"
      height="62"
      viewBox="0 0 60 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect width="60" height="62" fill="url(#pattern0)" />
      <defs>
        <pattern
          id="pattern0"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image0_2943_373"
            transform="matrix(0.0104167 0 0 0.0100806 0 0.016129)"
          />
        </pattern>
        <image
          id="image0_2943_373"
          width="96"
          height="96"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsTAAALEwEAmpwYAAABRklEQVR4nO3cQUoDURBF0VqI0+A2M8xagrgzwbiBF4QsQOxB9f91DvRQaN5NJTOrAAAA1nKtqlv3S0weP69HhMbxI0L/+BGhf/yI0D9+ROgfPyL0jx8R+sePCP3jR4T+8SNC//gRoX/8iNA/fkToHz8i9I8fEfrHz/QI1xOMPzbCmcbPtAhnHD9TIpx5/OweYYXxs2uElcbPbhFWHD+7RTgiBx8EWFtcgACjxQUIMFpcgACjxQUIMFpcgACjxQUIMFpcgACjxQUIMFpcgACjxQUIMFpcgACjxQUIMFpcgACjxQUIMFpcgACj/Ry4gO/ul9/B54EAH90vv4P3qvr6x/i/f3PpfvldvFXVvaoefxj+8frkGx8AAACA5XT/06Qs/ghQArR/CuMC+oeIr6D+MdLw+A0oAdo/hXEB/UNk1a8gAACgtvIEsUHzHt36joQAAAAASUVORK5CYII="
        />
      </defs>
    </svg>
  );
}
export default UploadIcon;
