import { ThemeConfig } from "antd";

const theme: ThemeConfig = {
  token: {
    fontFamily: "Plus Jakarta Sans",
    colorPrimary: "#F36F21",
    fontSizeHeading1: 36,
    colorTextBase: "#000000",
  },
  components: {
    Button: {
      colorPrimary: "#F36F21",
    },
    Typography: {
      colorPrimary: "#DE5706",
    },
  },
};

export default theme;
