import { Col, Image, Row } from "antd";
import { ReactNode } from "react";
import LoginBackground from "@/assets/images/background_login.png";
import Logo from "@/assets/images/logo.png";
import PageLayout from "@/components/layouts/PageLayout";
import styles from "./authLayout.module.less";
import C from "@/utils/constants";

interface IAuthLayoutProps {
  title?: string;
  children?: ReactNode;
}

function AuthLayout({ title = "", children = null }: IAuthLayoutProps) {
  return (
    <PageLayout title={title}>
      <Row>
        <Col className={styles.authLeftContent} xl={10} xs={24}>
          <Image
            preview={false}
            src={Logo}
            width={200}
            style={{
              marginBottom: 60,
            }}
            fallback={C.IMAGE_DEFAULT}
          />
          {children}
        </Col>
        <Col xl={14} xs={0}>
          <Image
            preview={false}
            src={LoginBackground}
            width="100%"
            height="100vh"
            style={{
              objectFit: "cover",
              objectPosition: "left 60%",
            }}
            fallback={C.IMAGE_DEFAULT}
          />
        </Col>
      </Row>
    </PageLayout>
  );
}

export default AuthLayout;
