import { IconProps } from "@/utils/types";

function OrderIcon({ color = "#8491A6", style }: IconProps) {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M17.3799 8.5827e-07H3.24032C2.8147 -0.000309015 2.39321 0.0832927 1.99993 0.246024C1.60666 0.408755 1.24933 0.647423 0.948376 0.948376C0.647423 1.24933 0.408755 1.60666 0.246024 1.99993C0.0832927 2.39321 -0.000309015 2.8147 8.5827e-07 3.24032V22.0931C-0.000309015 22.5187 0.0832927 22.9402 0.246024 23.3334C0.408755 23.7267 0.647423 24.084 0.948376 24.385C1.24933 24.686 1.60666 24.9246 1.99993 25.0874C2.39321 25.2501 2.8147 25.3337 3.24032 25.3334H14.4224C12.9405 24.4329 11.794 23.0722 11.158 21.459C10.5221 19.8458 10.4316 18.0688 10.9004 16.3994C11.3692 14.7299 12.3715 13.2598 13.7542 12.2135C15.1369 11.1671 16.8242 10.6021 18.5582 10.6047C19.2553 10.6028 19.9493 10.698 20.6202 10.8875V3.24032C20.6205 2.8147 20.5369 2.39321 20.3742 1.99993C20.2114 1.60666 19.9728 1.24933 19.6718 0.948376C19.3709 0.647423 19.0135 0.408755 18.6203 0.246024C18.227 0.0832927 17.8055 -0.000309015 17.3799 8.5827e-07ZM10.3101 11.1938H4.41861C4.18424 11.1938 3.95946 11.1007 3.79373 10.935C3.628 10.7693 3.53489 10.5445 3.53489 10.3101C3.53489 10.0757 3.628 9.85094 3.79373 9.68521C3.95946 9.51948 4.18424 9.42637 4.41861 9.42637H10.3101C10.5445 9.42637 10.7693 9.51948 10.935 9.68521C11.1007 9.85094 11.1938 10.0757 11.1938 10.3101C11.1938 10.5445 11.1007 10.7693 10.935 10.935C10.7693 11.1007 10.5445 11.1938 10.3101 11.1938ZM12.6667 6.48063H4.41861C4.18424 6.48063 3.95946 6.38753 3.79373 6.2218C3.628 6.05607 3.53489 5.83129 3.53489 5.59691C3.53489 5.36253 3.628 5.13775 3.79373 4.97202C3.95946 4.80629 4.18424 4.71319 4.41861 4.71319H12.6667C12.9011 4.71319 13.1258 4.80629 13.2916 4.97202C13.4573 5.13775 13.5504 5.36253 13.5504 5.59691C13.5504 5.83129 13.4573 6.05607 13.2916 6.2218C13.1258 6.38753 12.9011 6.48063 12.6667 6.48063Z"
        fill={color}
      />
      <path
        d="M18.5579 11.7832C17.2179 11.7832 15.908 12.1806 14.7938 12.925C13.6796 13.6695 12.8112 14.7276 12.2984 15.9656C11.7857 17.2037 11.6515 18.5659 11.9129 19.8802C12.1743 21.1944 12.8196 22.4017 13.7671 23.3492C14.7147 24.2967 15.9219 24.942 17.2361 25.2034C18.5504 25.4649 19.9127 25.3307 21.1507 24.8179C22.3887 24.3051 23.4468 23.4367 24.1913 22.3225C24.9358 21.2083 25.3331 19.8984 25.3331 18.5584C25.3309 16.7622 24.6164 15.0401 23.3463 13.77C22.0762 12.4999 20.3541 11.7854 18.5579 11.7832ZM20.9499 18.0046L18.5933 20.3612C18.5114 20.4434 18.4141 20.5087 18.3069 20.5532C18.1997 20.5977 18.0848 20.6206 17.9688 20.6206C17.8527 20.6206 17.7378 20.5977 17.6307 20.5532C17.5235 20.5087 17.4262 20.4434 17.3443 20.3612L16.166 19.1829C16.0792 19.102 16.0095 19.0044 15.9612 18.896C15.9129 18.7876 15.8869 18.6706 15.8848 18.552C15.8828 18.4333 15.9046 18.3154 15.949 18.2054C15.9935 18.0954 16.0596 17.9954 16.1435 17.9115C16.2275 17.8276 16.3274 17.7614 16.4375 17.717C16.5475 17.6725 16.6654 17.6507 16.784 17.6528C16.9027 17.6549 17.0197 17.6808 17.1281 17.7291C17.2365 17.7774 17.3341 17.8471 17.415 17.9339L17.9688 18.4865L19.7009 16.7556C19.7818 16.6688 19.8793 16.5991 19.9877 16.5508C20.0961 16.5025 20.2132 16.4766 20.3318 16.4745C20.4505 16.4724 20.5683 16.4942 20.6784 16.5387C20.7884 16.5831 20.8884 16.6493 20.9723 16.7332C21.0562 16.8171 21.1224 16.9171 21.1668 17.0271C21.2113 17.1371 21.2331 17.255 21.231 17.3737C21.2289 17.4923 21.2029 17.6093 21.1546 17.7177C21.1063 17.8261 21.0367 17.9237 20.9499 18.0046Z"
        fill={color}
      />
    </svg>
  );
}
export default OrderIcon;
