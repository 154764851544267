import { Col, Row, Dropdown, Space, Typography, Badge, Popover, Button } from "antd";
import type { MenuProps } from "antd";
import { ReactNode, useEffect, useState } from "react";
import { useHistory } from "react-router";
import BellIcon from "@/assets/icons/BellIcon";
import AvatarCircleCustom from "@/components/AvatarCircle";
import styles from "../dashboardLayout.module.less";
import BreadCrumbComponent from "../../../Breadcrumb";
import { BreadcrumbItemType } from "./header.types";
import routes from "@/routes";
import { useGetUserInfoQuery } from "@/rtk-query/userQueries";
import { isAuthorized } from "@/utils/helpers";
import Notification from "@/components/Notification";
import { useGetNotificationListQuery } from "@/rtk-query/notificationQueries";

const { Text } = Typography;

interface IHeaderProps {
  dataBreadCrumb: Array<BreadcrumbItemType>;
  renderFilter?: ReactNode;
}

function Header({ dataBreadCrumb = [], renderFilter = null }: IHeaderProps) {
  const [isOpenNoti, setIsOpenNoti] = useState(false);
  const { currentData, isFetching } = useGetUserInfoQuery();
  const { currentData: notifications } = useGetNotificationListQuery(
    {
      page: 1,
      status: 1,
      isLoading: undefined,
    },
    { refetchOnMountOrArgChange: true }
  );
  const history = useHistory();

  const items: MenuProps["items"] = [
    {
      label: <span>Profile</span>,
      key: "0",
      onClick: () => history.push(routes.userProfile.path),
    },
    {
      label: <span>Change password</span>,
      key: "1",
      onClick: () => history.push(routes.changePassword.path),
    },
    {
      type: "divider",
    },
    {
      label: "Logout",
      danger: true,
      key: "3",
      onClick: () => {
        window.location.href = routes.logout.path;
      },
    },
  ];

  useEffect(() => {
    if (!isFetching && !isAuthorized(currentData.platform)) {
      window.location.href = routes.logout.path;
    }
  }, [isFetching]);

  return (
    <div className={styles.headerWrapper}>
      <div className={styles.breadcrumbWrapper}>
        <Row justify="space-between" align="middle">
          <Col flex={1}>
            <BreadCrumbComponent data={dataBreadCrumb} />
          </Col>
          <Col>
            <Row
              style={{
                gap: 20,
                alignItems: "center",
              }}
            >
              {/* <Button type="primary" shape="circle" size="large" className="center-element">
                <SettingIcon
                  style={{
                    width: 22,
                  }}
                />
              </Button> */}

              <Popover
                placement="bottom"
                content={<Notification onSetOpenNoti={setIsOpenNoti} pathname={history.location.pathname} />}
                trigger="click"
                open={isOpenNoti}
                onOpenChange={setIsOpenNoti}
              >
                <Button type="primary" shape="circle" size="large" className="center-element" onClick={() => setIsOpenNoti(true)}>
                  <Badge count={notifications?.total}>
                    <BellIcon
                      style={{
                        width: 22,
                      }}
                    />
                  </Badge>
                </Button>
              </Popover>

              <Dropdown menu={{ items }} trigger={["click"]}>
                <div
                  onClick={(e) => e.preventDefault()}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <Space>
                    <AvatarCircleCustom
                      style={{
                        width: 52,
                        height: 52,
                      }}
                      src={currentData?.avatar}
                    />
                    <div>
                      <Text
                        style={{
                          fontSize: 14,
                          fontWeight: 600,
                          display: "block",
                        }}
                      >
                        {currentData?.fullName || ""}
                      </Text>
                      <Text
                        style={{
                          fontSize: 11,
                        }}
                      >
                        {currentData?.roles?.[0]?.name || ""}
                      </Text>
                    </div>
                  </Space>
                </div>
              </Dropdown>
            </Row>
          </Col>
        </Row>
      </div>
      <div className={styles.lineBreak}>
        <div className={styles.triangle} />
        <div className={styles.line} />
        <div className={styles.lineSkew} />
      </div>
      <div className={styles.filterWrapper}>{renderFilter}</div>
      <div className={styles.lineBreak}>
        <div className={styles.triangle} />
        <div className={styles.line} />
        <div className={styles.lineSkew} />
      </div>
    </div>
  );
}

export default Header;
