import { Button, ButtonProps } from "antd";
import clsx from "clsx";
import styles from "./buttonRectangle.module.less";

function ButtonRectangle(props: ButtonProps) {
  return (
    <Button
      type="primary"
      {...props}
      className={clsx(styles.button, props.className)}
    />
  );
}

export default ButtonRectangle;
