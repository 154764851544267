import { CommonArray, CompanyPayloadType, FilterType } from "../utils/types";
// import { CommonArray, CommonQueryType, ProductType, SimilarProductType } from '@/utils/types';
import queryTagTypes from "./queryTagTypes";
import baseApi from ".";
import { CompanyType } from "@/utils/types";
import { cleanObject } from "@/utils/helpers";

const companyApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCompanies: builder.query<CommonArray<CompanyType>, FilterType>({
      query: (params) => ({
        url: "/companies",
        method: "GET",
        params: {
          sortBy: "createdAt",
          sortOrder: "desc",
          ...cleanObject(params),
        },
      }),
      providesTags: [queryTagTypes.COMPANY_QUERY_TAG],
    }),
    getDetailCompany: builder.query<CompanyType, string | number>({
      query: (id) => ({
        url: `/companies/${id}`,
        method: "GET",
      }),
      providesTags: [queryTagTypes.COMPANY_QUERY_TAG],
    }),
    createCompany: builder.mutation<
      CommonArray<CompanyType>,
      CompanyPayloadType
    >({
      query: (body) => ({
        url: "/companies",
        method: "POST",
        body,
      }),
      invalidatesTags: (_, error) =>
        error ? [] : [queryTagTypes.COMPANY_QUERY_TAG],
    }),
    updateCompany: builder.mutation<
      any,
      { id: string | number; body: CompanyPayloadType }
    >({
      query: ({ id, body }) => ({
        url: `/companies/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (_, error) =>
        error ? [] : [queryTagTypes.COMPANY_QUERY_TAG],
    }),
    deleteCompany: builder.mutation<any, { id: string | number }>({
      query: ({ id }) => ({
        url: `/companies/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (_, error) =>
        error ? [] : [queryTagTypes.COMPANY_QUERY_TAG],
    }),
    restoreCompany: builder.mutation<any, { id: string | number }>({
      query: ({ id }) => ({
        url: `/companies/${id}/restore`,
        method: "POST",
      }),
      invalidatesTags: (_, error) =>
        error ? [] : [queryTagTypes.COMPANY_QUERY_TAG],
    }),
    getCompanyRoles: builder.query<any, string | number>({
      query: (id) => ({
        url: `/companies/${id}/roles`,
        method: "GET",
      }),
      providesTags: [queryTagTypes.COMPANY_QUERY_TAG],
      transformResponse: (response: {
        data: Array<{
          id: string | number;
          name: string;
        }>;
      }) => response.data,
    }),
  }),
});

export default companyApi;
export const {
  useGetCompaniesQuery,
  useGetDetailCompanyQuery,
  useCreateCompanyMutation,
  useUpdateCompanyMutation,
  useDeleteCompanyMutation,
  useRestoreCompanyMutation,
  useGetCompanyRolesQuery,
} = companyApi;
