import { IconProps } from "@/utils/types";

function TagIcon({ color = "#8491A6", style }: IconProps) {
  return (
    <svg
      width="28"
      height="25"
      viewBox="0 0 28 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M21.0861 0H12.786C12.2606 0 11.7561 0.209139 11.3844 0.580831L0.580584 11.3827C-0.193528 12.1568 -0.193528 13.4117 0.580584 14.1868L8.88072 22.4869C9.65483 23.261 10.9097 23.261 11.6838 22.4869L22.4876 11.685C22.8593 11.3123 23.0685 10.8078 23.0685 10.2815V1.98236C23.0685 0.887106 22.1814 0 21.0861 0ZM17.617 6.93826C16.7963 6.93826 16.1302 6.27219 16.1302 5.45149C16.1302 4.63079 16.7963 3.96472 17.617 3.96472C18.4377 3.96472 19.1038 4.63079 19.1038 5.45149C19.1038 6.27219 18.4377 6.93826 17.617 6.93826Z"
        fill={color}
      />
      <path
        d="M25.0507 1.9834L25.0497 11.2103C25.0497 11.6672 24.8683 12.1063 24.5442 12.4294L13.5659 23.4077L13.7344 23.5763C14.5085 24.3504 15.7634 24.3504 16.5375 23.5763L26.4513 13.6645C26.8239 13.2928 27.0331 12.7882 27.0331 12.2629V3.96576C27.0331 2.8705 26.146 1.9834 25.0507 1.9834Z"
        fill={color}
      />
    </svg>
  );
}
export default TagIcon;
