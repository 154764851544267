import ContentWrapper from "@/components/layouts/DashboardLayout/Content";
import FilterComponent from "./components/FilterComponent";
import routes from "@/routes";
import MultiBoxIcon from "@/assets/icons/MultiBoxIcon";
import UploadFileComponent from "./components/UploadFileComponent";
import FileImportDetail from "./components/FileImportDetail";
import { useLocation } from "react-router";

const ExcelFileImportModule = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const fileId = query.get("fileId");

  return (
    <ContentWrapper
      title={routes.masterProductImportFile.title}
      renderFilter={<FilterComponent title="Import Excel file" />}
      dataBreadCrumb={[
        {
          // title: routes.masterProductList.title,
          title: location?.pathname === "/site-products/import-excel-file" ? "Site product management" : "Master product",
          url: routes.masterProductList.path,
          icon: <MultiBoxIcon />,
        },
        {
          title: routes.masterProductImportFile.title,
          url: routes.masterProductImportFile.path,
        },
      ]}
    >
      {fileId ? <FileImportDetail fileId={fileId} /> : <UploadFileComponent />}
    </ContentWrapper>
  );
};

export default ExcelFileImportModule;
