import { FilterType, MasterProductType, WarehouseType } from "../../utils/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cleanObject } from "@/utils/helpers";
import C from "@/utils/constants";
import { CompanySelectType } from "../WarehouseManagement/warehouseManagement.slice";

export interface IFilterSiteProduct extends FilterType {
  companyId?: string | number | null;
}

interface IinitialState {
  filter: IFilterSiteProduct;
  data: Array<any>;
  companySelected?: CompanySelectType;
  masterProductSelected?: MasterProductType | null;
  wareHouseSelected?: WarehouseType | null;
  productIdSelected?: string | number | null;
  fileIdImporting: string | number | null;
  fileIdLastImported: string | number | null;
}

const initialState: IinitialState = {
  filter: {
    page: 1,
    pageSize: C.PAGE_SIZE_LITTLE,
    isLoading: undefined,
  },
  companySelected: null,
  wareHouseSelected: null,
  productIdSelected: null,
  masterProductSelected: null,
  data: [],
  fileIdImporting: null,
  fileIdLastImported: null,
};

export const siteProductManagementSlide = createSlice({
  name: "siteProductManagementSlide",
  initialState,
  reducers: {
    updateFilterState(state, action: PayloadAction<IFilterSiteProduct>) {
      state.filter = cleanObject({
        ...state.filter,
        ...action.payload,
      });
    },
    updateDataState(state, action: PayloadAction<Array<any>>) {
      state.data = action.payload;
    },
    onSelectCompany(state, action: PayloadAction<CompanySelectType>) {
      state.companySelected = action.payload;
    },
    onSelectMasterProduct(state, action: PayloadAction<MasterProductType | null>) {
      state.masterProductSelected = action.payload;
    },
    onSelectProduct(state, action: PayloadAction<string | number | null>) {
      state.productIdSelected = action.payload;
    },
    onSelectWareHouse(state, action: PayloadAction<WarehouseType | null>) {
      state.wareHouseSelected = action.payload;
    },
    updateFileIdImporting(state, action: PayloadAction<string | number | null>) {
      state.fileIdImporting = action.payload;
      if (!action.payload) return;
      state.fileIdLastImported = action.payload;
    },
    updateFileIdLastImported(state, action: PayloadAction<string | number | null>) {
      state.fileIdLastImported = action.payload;
    },
  },
});

export const siteProductManagementReducer = siteProductManagementSlide.reducer;
