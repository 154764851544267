import ProductCodeDropdownList from "@/components/FilterSelectCustom/ProductDropdownList";
import ProductWarningCodeDropdownList from "@/components/FilterSelectCustom/ProductWarningDropdownList";
import { IProductOrder, ProductType } from "@/utils/types";
import { Modal, Typography, Form, Row, Col, Button, InputNumber } from "antd";
import { useEffect, useState } from "react";
import styles from "./dialogAddProduct.module.less";
import clsx from "clsx";
import commonStyles from "@/styles/common.module.less";
import { handleCheckNumberKey } from "@/modules/SiteProductCUR";

const { Title } = Typography;
interface IDialogAddProduct {
  isOpen?: boolean;
  isFull?: boolean;
  setIsOpen: (o: boolean) => void;
  addNewProduct: (productAddDialog: IProductOrder) => void;
  wareHouseSelectedId: string;
  companySelectedId: string;
  listProductSelected: IProductOrder[];
}
const DialogAddProduct = ({
  isOpen = false,
  setIsOpen,
  addNewProduct,
  wareHouseSelectedId,
  companySelectedId,
  isFull,
  listProductSelected,
}: IDialogAddProduct) => {
  const [form] = Form.useForm();

  const [search, setSearch] = useState("");
  const [orderedQuantity, setOrderedQuantity] = useState(0);

  const [productSelected, setProductSelected] = useState<ProductType | null>(null);

  const defaultOrderedQuantity = Math.max(
    +(productSelected?.minimumQuantity || 0) - +(productSelected?.inventory || 0),
    productSelected?.masterProduct?.minimumQuantity || 0
  );

  const handleClose = () => {
    setIsOpen(false);
    setSearch("");
    setProductSelected(null);
  };
  const handleSubmit = () => {
    // add product to detail order
    if (productSelected) {
      addNewProduct({
        id: productSelected.id,
        productId: productSelected.id,
        productName: productSelected.productName,
        productCode: productSelected.productCode,
        position: productSelected.position,
        unit: productSelected.unit,
        binCode: productSelected.bin.binCode,
        orderQuantity: orderedQuantity,
        receivedQuantity: null,
        warehouseCode: productSelected.bin.warehouse?.identityCode || "",
        companyName: productSelected.bin.warehouse?.company.companyName || "",
        companyId: productSelected.bin.warehouse?.company.id || "",
        address: productSelected.bin.warehouse?.address || "",
        minOrderQuantity: productSelected?.masterProduct?.minimumQuantity,
        manufactureItemNumber: productSelected?.masterProduct?.manufactureItemNumber,
      });
      handleClose();
    }
  };

  useEffect(() => {
    setOrderedQuantity(defaultOrderedQuantity);
  }, [productSelected]);

  return (
    <Modal
      title={
        <Title level={5} style={{ color: "#DE5706", marginLeft: 92, marginBottom: 32 }}>
          ADD PRODUCT TO THE ORDER
        </Title>
      }
      open={isOpen}
      footer={null}
      centered
      width={1000}
      wrapClassName={styles.dialogWrap}
      onCancel={handleClose}
    >
      <Form name="basic" labelCol={{ span: 6 }} wrapperCol={{ span: 24 }} onFinish={handleSubmit} autoComplete="off" form={form}>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Row gutter={[16, 16]}>
              <Col span={8} className={styles.textRight}>
                Product name:
              </Col>
              <Col span={16} className={styles.textValueLeft} style={{ paddingBottom: 0, paddingTop: 0,paddingLeft: 0, paddingRight: 0, }}>
                {isFull ? (
                  <ProductWarningCodeDropdownList
                    className={styles.productDropdown}
                    value={productSelected?.id || null}
                    search={search}
                    onChange={(product) => {
                      setProductSelected(product);
                      setSearch("");
                    }}
                    filter={{
                      companyId: companySelectedId,
                      warehouseId: wareHouseSelectedId,
                    }}
                    disabled={!wareHouseSelectedId}
                    skip={!wareHouseSelectedId}
                    listHide={listProductSelected}
                    productChoiced={listProductSelected}
                  />
                ) : (
                  <ProductCodeDropdownList
                    className={styles.productDropdown}
                    value={productSelected?.id || null}
                    search={search}
                    onChange={(product) => {
                      setProductSelected(product);
                      setSearch("");
                    }}
                    filter={{
                      companyId: companySelectedId,
                      warehouseId: wareHouseSelectedId,
                      ignoreDeleted: true,
                    }}
                    disabled={!wareHouseSelectedId}
                    skip={!wareHouseSelectedId}
                    listHide={listProductSelected}
                  />
                )}
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row gutter={[16, 16]}>
              <Col span={8} className={styles.textRight}>
                Product id:
              </Col>
              <Col span={16} className={styles.textValueLeft}>
                {productSelected?.productCode}
              </Col>
            </Row>
          </Col>

          <Col span={12}>
            <Row gutter={[16, 16]}>
              <Col span={8} className={styles.textRight}>
                Inventory:
              </Col>
              <Col span={16} className={styles.textValueLeft}>
                {productSelected?.inventory}
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row gutter={[16, 16]}>
              <Col span={8} className={styles.textRight}>
                Minimum quantity:
              </Col>
              <Col span={16} className={styles.textValueLeft}>
                {productSelected?.minimumQuantity}
              </Col>
            </Row>
          </Col>

          <Col span={12}>
            <Row gutter={[16, 16]}>
              <Col span={8} className={styles.textRight}>
                Ordered quantity:
              </Col>
              <Col
                span={16}
                style={{ paddingBottom: 0, paddingTop: 0,paddingLeft: 0, paddingRight: 0, }}
              >
                <InputNumber
                  className={clsx(commonStyles.textFieldDark)}
                  style={{
                    height: 35,
                    border: "none",
                  }}
                  size="large"
                  type="Number"
                  min={productSelected?.masterProduct?.minimumQuantity}
                  value={orderedQuantity}
                  onChange={(value) => setOrderedQuantity(value || 0)}
                  onKeyDown={handleCheckNumberKey}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row gutter={[16, 16]}>
              <Col span={8} className={styles.textRight}>
                Unit:
              </Col>
              <Col span={16} className={styles.textValueLeft}>
                {productSelected?.unit}
              </Col>
            </Row>
          </Col>
        </Row>

        <div
          style={{
            marginTop: 61,
            display: "flex",
            justifyContent: "flex-end",
            gap: 18,
          }}
        >
          <Button
            type="primary"
            size="large"
            onClick={handleClose}
            style={{
              background: "#8C8888",
              width: 114,
            }}
          >
            Cancel
          </Button>
          <Button
            htmlType="submit"
            type="primary"
            size="large"
            style={{
              width: 230,
              fontWeight: 600,
            }}
            disabled={!productSelected}
          >
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default DialogAddProduct;
