const message = {
  ERROR_NOT_BLANK: (field: string) => `${field} cannot be blank!`,
  ERROR_INVALID: (field: string) => `Invalid ${field}!`,
  ERROR_DUPLICATE: (field: string) => `This ${field} already exists!`,
  ERROR_MAX_CHARACTER: (value: number) => `No more than ${value} characters!`,
  ERROR_NO_SPECIAL: "No special characters in this field!",
  SUCCESSFULLY: "Successfully!",
  NO_MATCHING_RESULT: "No matching result",
  NO_DATA: "No data",
  LOADING: "Loading...",
};

export default message;
