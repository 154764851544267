import ButtonRectangle from "@/components/ButtonRectangle";
import { Col, Row, theme } from "antd";
import styles from "./cardRecord.module.less";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import routes from "@/routes";

const { useToken } = theme;

interface ICardRecordProps {
  id: string;
  data: any;
  version: number;
}

const CardRecord = ({ data, version, id }: ICardRecordProps) => {
  const { token } = useToken();
  return (
    <div className={styles.wrapper}>
      <Row align="middle" justify="space-between">
        <Col>
          <div className={styles.version}>V{version}</div>
        </Col>
        <Col>
          <div
            style={{
              fontSize: 16,
              fontWeight: 600,
              color: "#234C66",
            }}
          >
            <span
              style={{
                color: token.colorPrimary,
              }}
            >
              Version {version}{" "}
            </span>
            by {data?.createBy}
          </div>
        </Col>
        <Col>
          <div
            style={{
              color: "#234C66",
              fontSize: 14,
            }}
          >
            {dayjs(data?.recordedAt).format("D MMMM YYYY [at] hh:mm A")}
            {/* 29 Jul 2022 at 03:30 PM */}
          </div>
        </Col>
        <Col>
          <Link
            to={`${routes.viewRecord.path
              .replace(":id", id || "")
              .replace(":recordId", data?.id || "")}`}
          >
            <ButtonRectangle
              style={{
                background: "#8491A6",
                height: 36,
                width: 158,
              }}
            >
              View
            </ButtonRectangle>
          </Link>
        </Col>
      </Row>
    </div>
  );
};

export default CardRecord;
