import { FilterType, WarehousePayloadType } from "../utils/types";
// import { CommonArray, CommonQueryType, ProductType, SimilarProductType } from '@/utils/types';
import { CommonArray, WarehouseType } from "@/utils/types";
import baseApi from ".";
import queryTagTypes from "./queryTagTypes";
import { cleanObject, downloadFile } from "@/utils/helpers";

const warehouseApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getWarehouseList: builder.query<
      CommonArray<WarehouseType>,
      {
        companyId?: string | number;
      } & FilterType
    >({
      query: (params) => ({
        url: "/warehouse",
        method: "GET",
        params: cleanObject({
          sortBy: "createdAt",
          sortOrder: "desc",
          ...params,
        }),
      }),
      providesTags: [queryTagTypes.WAREHOUSE_QUERY_TAG],
    }),
    getWarehouseDetail: builder.query<WarehouseType, string | number>({
      query: (id) => ({
        url: `/warehouse/${id}`,
        method: "GET",
      }),
      providesTags: [queryTagTypes.WAREHOUSE_QUERY_TAG],
    }),
    createNewWarehouse: builder.mutation<any, WarehousePayloadType>({
      query: (body) => ({
        url: "/warehouse",
        method: "POST",
        body,
      }),
      invalidatesTags: (_, error) =>
        error
          ? []
          : [
              queryTagTypes.WAREHOUSE_QUERY_TAG,
              queryTagTypes.COMPANY_QUERY_TAG,
            ],
    }),
    editWarehouse: builder.mutation<
      any,
      {
        id: string | number;
        body: WarehousePayloadType;
      }
    >({
      query: ({ id, body }) => ({
        url: `/warehouse/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (_, error) =>
        error ? [] : [queryTagTypes.WAREHOUSE_QUERY_TAG],
    }),
    deleteWarehouse: builder.mutation<any, string | number>({
      query: (id) => ({
        url: `/warehouse/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (_, error) =>
        error ? [] : [queryTagTypes.WAREHOUSE_QUERY_TAG],
    }),
    restoreWarehouse: builder.mutation<any, string | number>({
      query: (id) => ({
        url: `/warehouse/${id}/restore`,
        method: "POST",
      }),
      invalidatesTags: (_, error) =>
        error ? [] : [queryTagTypes.WAREHOUSE_QUERY_TAG],
    }),
    exportWarehouse: builder.mutation<
      Blob,
      {
        companyId: string | number;
      }
    >({
      query: (params) => ({
        url: `/warehouse/export`,
        method: "POST",
        params: cleanObject(params),
        responseHandler: async (response) => {
          const blobReponse = await response.blob();
          downloadFile(
            blobReponse,
            `warehouses_${params?.companyId || "all"}_report.xlsx`
          );
          return { data: null };
        },
        cache: "no-cache",
      }),
      invalidatesTags: [],
    }),
  }),
});

export default warehouseApi;
export const {
  useGetWarehouseListQuery,
  useGetWarehouseDetailQuery,
  useCreateNewWarehouseMutation,
  useEditWarehouseMutation,
  useDeleteWarehouseMutation,
  useRestoreWarehouseMutation,
  useExportWarehouseMutation,
} = warehouseApi;
