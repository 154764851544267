import DeleteIcon from "@/assets/icons/DeleteIcon";
import EditIcon from "@/assets/icons/EditIcon";
import { useGetMasterProductUnitListQuery } from "@/rtk-query/masterProductQueries";
import { ProductType } from "@/utils/types";
import { Space } from "antd";
import { ColumnsType } from "antd/es/table";
import QRCode from "qrcode.react";

export const productColumns = ({
  onEdit,
  onDelete,
  onRestore,
  loadingList,
}: {
  onEdit?: any;
  onDelete?: any;
  onRestore?: any;
  loadingList?: Array<string | number>;
}) => {
  const { currentData: masterProductUnitList } =
    // eslint-disable-next-line
    useGetMasterProductUnitListQuery({ isLoading: undefined });

  const findUnit = (key: string) =>
    (masterProductUnitList?.data || []).find((unit) => unit.key === key) || {
      key,
      name: key,
    };

  return [
    {
      title: "Product ID",
      dataIndex: "productCode",
      key: "productCode",
      width: 160,
      render: (value) => (
        <div
          style={{
            maxWidth: 160,
          }}
        >
          {value}
        </div>
      ),
    },
    {
      title: "Product name",
      dataIndex: "productName",
      key: "productName",
      width: 160,
      render: (value) => (
        <div
          style={{
            maxWidth: 160,
          }}
        >
          {value}
        </div>
      ),
    },
    {
      title: "Company",
      dataIndex: "bin",
      key: "company",
      width: 160,
      render: (value) => (
        <div
          style={{
            maxWidth: 160,
          }}
        >
          {value?.warehouse?.company?.companyName || ""}
        </div>
      ),
    },
    {
      title: "Warehouse code",
      dataIndex: "bin",
      key: "warehouse",
      width: 160,
      render: (value) => (
        <div
          style={{
            maxWidth: 160,
          }}
        >
          {value?.warehouse?.identityCode || ""}
        </div>
      ),
    },
    {
      title: "Bin Code",
      dataIndex: "bin",
      key: "bin",
      width: 160,
      render: (value) => (
        <div
          style={{
            maxWidth: 160,
          }}
        >
          {value?.binCode || ""}
        </div>
      ),
    },
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
      width: 160,
      render: (value) => (
        <div
          style={{
            maxWidth: 160,
          }}
        >
          {value}
        </div>
      ),
    },
    {
      title: "QR code",
      dataIndex: "bin",
      key: "qrCode",
      width: 51,
      render: (value, record) => (
        <QRCode
          size={51}
          value={JSON.stringify({
            productId: record?.id,
            companyId: value?.warehouse?.company?.id,
          })}
        />
      ),
    },
    {
      title: "Inventory",
      dataIndex: "inventory",
      key: "inventory",
      width: 160,
      render: (value) => (
        <div
          style={{
            maxWidth: 160,
          }}
        >
          {value}
        </div>
      ),
    },
    {
      title: "Minimum quantity",
      dataIndex: "minimumQuantity",
      key: "minimumQuantity",
      width: 160,
      render: (value) => (
        <div
          style={{
            maxWidth: 160,
          }}
        >
          {value}
        </div>
      ),
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
      width: 160,
      render: (value) => (
        <div
          style={{
            maxWidth: 160,
          }}
        >
          {findUnit(value || "")?.name}
        </div>
      ),
    },
    {
      title: "Functions",
      width: 240,
      render: (_, record) => {
        const loading = !!loadingList?.find((item) => item === record?.id);

        return (
          <Space
            size="middle"
            style={{
              maxWidth: 240,
              fontSize: 12,
            }}
          >
            {record?.deletedAt ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 6,
                  color: "#192EEB",
                  cursor: "pointer",
                  justifyContent: "center",
                  width: "100%",
                }}
                onClick={() => (loading ? undefined : onRestore?.(record?.id))}
              >
                <span>Restore</span>
                <span>&gt;&gt;</span>
              </div>
            ) : (
              <>
                <div
                  onClick={() => {
                    onEdit?.(record?.id);
                  }}
                >
                  <EditIcon style={{ width: 16, cursor: "pointer" }} />
                </div>
                <div onClick={() => onDelete?.(record?.id || "")}>
                  <DeleteIcon style={{ width: 16, cursor: "pointer" }} />
                </div>
              </>
            )}
          </Space>
        );
      },
    },
  ] as ColumnsType<ProductType>;
};
