import { Col, Form, Input, Row, Select, notification, theme } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useHistory, useLocation, useParams } from "react-router";
import { useEffect } from "react";
import clsx from "clsx";
import PersonIcon from "@/assets/icons/PersonIcon";
import ContentWrapper from "@/components/layouts/DashboardLayout/Content";
import FilterComponent from "./components/FilterComponent";
import routes from "@/routes";
import styles from "./clientCUR.module.less";
import commonStyles from "@/styles/common.module.less";
import ButtonNotched from "@/components/ButtonNotched";
import WarehouseList from "./components/WarehouseList";
import { useCreateCompanyMutation, useGetDetailCompanyQuery, useUpdateCompanyMutation } from "@/rtk-query/companyQueries";
import UserList from "./components/UserList";
import message from "@/utils/message";
import C from "@/utils/constants";
import { getError, renderFieldViewOnly } from "@/utils/helpers";
import { useGetCallingCodeListQuery } from "@/rtk-query/commonQueries";

const { useToken } = theme;

interface IClientCURModuleProps {
  type?: "create" | "view" | "edit";
}

function ClientCURModule({ type }: IClientCURModuleProps) {
  const params: any = useParams();
  const location = useLocation();
  const history = useHistory();
  const [form] = Form.useForm();
  const { token } = useToken();

  const viewOnly = type === "view";

  const { currentData: detailCompany, isError: isErrorCompanyDetail } = useGetDetailCompanyQuery(params?.id, {
    skip: !params?.id || type === "create",
  });
  const { currentData: callingCodeList } = useGetCallingCodeListQuery({});

  const [createClientApi, { isLoading: isLoadingCreateCompany }] = useCreateCompanyMutation();
  const [updateClientApi, { isLoading: isLoadingUpdateCompany }] = useUpdateCompanyMutation();

  const initialData = () => {
    form.setFieldValue("companyName", detailCompany?.companyName || "");
    form.setFieldValue("contact", {
      phoneExt: detailCompany?.phoneExt || "",
      phoneNumber: detailCompany?.phoneNumber || "",
    });
    form.setFieldValue("address", detailCompany?.address || "");
    form.setFieldValue("logoUrl", detailCompany?.logoUrl || "");
    form.setFieldValue("numberOfWarehouse", detailCompany?.numberOfWarehouse || 0);
  };

  const handleSubmit = async (values: any) => {
    const { numberOfWarehouse, contact, companyId, ...payload } = values;
    try {
      type === "create"
        ? await createClientApi({
            ...payload,
            ...contact,
          }).unwrap()
        : await updateClientApi({
            id: params?.id,
            body: {
              ...payload,
              ...contact,
            },
          }).unwrap();
      notification.success({
        key: "client_cur",
        message: message.SUCCESSFULLY,
      });
      history.push(routes.clientList.path);
    } catch (err) {
      notification.error({
        key: "client_cur",
        message: getError(err),
      });
    }
  };

  const handleCancel = () => {
    history.goBack();
  };

  useEffect(() => {
    initialData();
    return () => form.resetFields();
  }, [detailCompany]);

  useEffect(() => {
    isErrorCompanyDetail && history.replace(routes[404].path);
  }, [isErrorCompanyDetail]);

  const titlePage = () => {
    let title = "";
    switch (type) {
      case "edit":
        title = routes.editClient.title;
        break;
      case "view":
        title = routes.viewClient.title;
        break;
      default:
        title = routes.createClient.title;
        break;
    }

    return title;
  };

  const requiredValidate = (field: string) => ({
    required: true,
    validator: (_: any, value: string) => (value.trim().length > 0 ? Promise.resolve() : Promise.reject()),
    message: message.ERROR_NOT_BLANK(field),
  });

  const dropdownStyle = {
    background: "#dfe3ef",
    padding: 0,
    borderRadius: 0,
    boxShadow: "inset 4px 4px 3px rgba(0, 0, 0, 0.03)",
    fontSize: 12,
    fontWeight: 600,
  };

  return (
    <ContentWrapper
      title={type === "view" ? "" : titlePage()}
      renderFilter={<FilterComponent title={titlePage()} />}
      dataBreadCrumb={[
        {
          title: routes.clientList.title,
          url: routes.clientList.path,
          icon: <PersonIcon />,
        },
        {
          title: titlePage(),
          url: location.pathname,
          style: {
            fontWeight: 800,
          },
        },
      ]}
    >
      <Form name="basic" labelCol={{ span: 6 }} wrapperCol={{ span: 24 }} onFinish={handleSubmit} autoComplete="off" form={form}>
        <div className={styles.infoGeneral}>
          <Row gutter={[50, 16]}>
            <Col span={12}>
              <Form.Item label={<span className={commonStyles.label}>Company</span>} name="companyName" rules={[requiredValidate("Company")]}>
                {viewOnly ? (
                  renderFieldViewOnly(detailCompany?.companyName || "")
                ) : (
                  <Input
                    className={clsx(commonStyles.textField)}
                    style={{
                      height: 38,
                    }}
                    size="large"
                    disabled={viewOnly}
                  />
                )}
              </Form.Item>
            </Col>
            {type !== "create" ? (
              <Col span={12}>
                <Form.Item label={<span className={commonStyles.label}>Company ID</span>} name="companyId" initialValue={detailCompany?.id || ""}>
                  {viewOnly ? (
                    renderFieldViewOnly(detailCompany?.id || "")
                  ) : (
                    <Input
                      className={clsx(commonStyles.textField)}
                      style={{
                        height: 38,
                        background: "#ACB7D8",
                      }}
                      size="large"
                      disabled
                    />
                  )}
                </Form.Item>
              </Col>
            ) : (
              <Col span={12}>
                <Form.Item label={<span className={commonStyles.label}>Contact</span>} required>
                  {viewOnly ? (
                    renderFieldViewOnly(`${detailCompany?.phoneExt} ${detailCompany?.phoneNumber}`.trim() || "")
                  ) : (
                    <Input.Group compact>
                      <Form.Item
                        name={["contact", "phoneExt"]}
                        noStyle
                        rules={[
                          {
                            required: true,
                            message: message.ERROR_NOT_BLANK("Country code"),
                          },
                        ]}
                        initialValue={params?.id ? detailCompany?.phoneExt || "" : ""}
                      >
                        <Select
                          style={{ width: "30%" }}
                          dropdownStyle={dropdownStyle}
                          popupClassName="customDropdownSelectAntd"
                          className={commonStyles.select}
                          size="large"
                          disabled={viewOnly}
                          showSearch
                          filterOption={(input, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                          getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}
                        >
                          {(callingCodeList || []).map(({ alpha2Code, code }: any, index: number) => (
                            <Select.Option value={code} key={index}>
                              {`${alpha2Code} ${code}`}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name={["contact", "phoneNumber"]}
                        noStyle
                        rules={[
                          requiredValidate("Contact"),
                          {
                            pattern: new RegExp(C.REGEX_PHONE_NUMBER),
                            validator: (rule, value) => {
                              if (rule.pattern?.test(value)) return Promise.resolve();
                              return Promise.reject();
                            },
                            message: message.ERROR_INVALID("contact number"),
                          },
                        ]}
                        initialValue={params?.id ? detailCompany?.phoneNumber || "" : ""}
                      >
                        <Input
                          style={{ width: "70%", height: 38, borderRadius: 0 }}
                          className={clsx(commonStyles.textField)}
                          size="large"
                          disabled={viewOnly}
                        />
                      </Form.Item>
                    </Input.Group>
                  )}
                </Form.Item>
              </Col>
            )}
            <Col span={12}>
              <Form.Item label={<span className={commonStyles.label}>Address</span>} name="address" rules={[requiredValidate("Address")]}>
                {viewOnly ? (
                  renderFieldViewOnly(detailCompany?.address || "")
                ) : (
                  <TextArea rows={3} className={clsx(commonStyles.textField)} size="large" disabled={viewOnly} />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<span className={commonStyles.label}>URL logo</span>}
                name="logoUrl"
                rules={[
                  {
                    required: true,
                    message: message.ERROR_NOT_BLANK("URL Logo"),
                  },
                  {
                    pattern: new RegExp(C.REGEX_IMAGE),
                    message: message.ERROR_INVALID("information"),
                    validator: (rule, value) => {
                      if (!value || rule.pattern?.test(value)) return Promise.resolve();
                      return Promise.reject();
                    },
                  },
                  {
                    max: 255,
                    message: message.ERROR_MAX_CHARACTER(255),
                  },
                ]}
              >
                {viewOnly ? (
                  renderFieldViewOnly(detailCompany?.logoUrl || "")
                ) : (
                  <TextArea rows={3} className={clsx(commonStyles.textField)} size="large" disabled={viewOnly} />
                )}
              </Form.Item>
            </Col>
            {type !== "create" && (
              <Col span={12}>
                <Form.Item label={<span className={commonStyles.label}>Contact</span>} required>
                  {viewOnly ? (
                    renderFieldViewOnly(`${detailCompany?.phoneExt} ${detailCompany?.phoneNumber}`.trim() || "")
                  ) : (
                    <Input.Group compact>
                      <Form.Item
                        name={["contact", "phoneExt"]}
                        noStyle
                        rules={[
                          {
                            required: true,
                            message: message.ERROR_NOT_BLANK("Country code"),
                          },
                        ]}
                        initialValue={params?.id ? detailCompany?.phoneExt || "" : ""}
                      >
                        <Select
                          className={commonStyles.select}
                          style={{ width: "30%" }}
                          dropdownStyle={dropdownStyle}
                          popupClassName="customDropdownSelectAntd"
                          size="large"
                          disabled={viewOnly}
                          showSearch
                          filterOption={(input, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                          getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}
                        >
                          {(callingCodeList || []).map(({ alpha2Code, code }: any, index: number) => (
                            <Select.Option value={code} key={index}>
                              {`${alpha2Code} ${code}`}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name={["contact", "phoneNumber"]}
                        noStyle
                        rules={[
                          requiredValidate("Contact"),
                          {
                            pattern: new RegExp(C.REGEX_PHONE_NUMBER),
                            validator: (rule, value) => {
                              if (!value || rule.pattern?.test(value)) return Promise.resolve();
                              return Promise.reject();
                            },
                            message: message.ERROR_INVALID("contact number"),
                          },
                        ]}
                        initialValue={params?.id ? detailCompany?.phoneNumber || "" : ""}
                      >
                        <Input
                          style={{ width: "70%", height: 38, borderRadius: 0 }}
                          className={clsx(commonStyles.textField)}
                          size="large"
                          disabled={viewOnly}
                        />
                      </Form.Item>
                    </Input.Group>
                  )}
                </Form.Item>
              </Col>
            )}
            {type !== "create" && (
              <Col span={12}>
                <Form.Item
                  label={
                    <span
                      className={commonStyles.label}
                      style={{
                        whiteSpace: "pre-line",
                      }}
                    >
                      Number of warehouse
                    </span>
                  }
                  name="numberOfWarehouse"
                  rules={[]}
                >
                  {viewOnly ? (
                    renderFieldViewOnly(detailCompany?.numberOfWarehouse || "0", {
                      color: token.colorPrimary,
                    })
                  ) : (
                    <Input
                      className={clsx(commonStyles.textField)}
                      style={{
                        height: 38,
                        color: token.colorPrimary,
                      }}
                      size="large"
                      disabled
                    />
                  )}
                </Form.Item>
              </Col>
            )}
          </Row>
        </div>
        <div className={styles.listUserWrapper}>
          {type !== "create" && <UserList />}
          {type !== "create" && <WarehouseList />}
          <div className={styles.buttonGroup}>
            <ButtonNotched onClick={handleCancel} type="dark">
              {viewOnly ? "Back" : "Cancel"}
            </ButtonNotched>
            {viewOnly ? (
              <ButtonNotched
                onClick={() => {
                  history.push(routes.editClient.path.replace(":id", params?.id));
                }}
              >
                Edit
              </ButtonNotched>
            ) : (
              <button
                type="submit"
                style={{
                  background: "transparent",
                  border: "none",
                }}
                onClick={() => {
                  const contentElement: any = document.getElementById("content");
                  contentElement.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                disabled={isLoadingCreateCompany || isLoadingUpdateCompany}
              >
                <ButtonNotched>Save</ButtonNotched>
              </button>
            )}
          </div>
        </div>
      </Form>
    </ContentWrapper>
  );
}

export default ClientCURModule;
