import ContentWrapper from "@/components/layouts/DashboardLayout/Content";
import routes from "@/routes";
import FilterComponent from "../SiteProductManagement/components/FilterComponent";
import { useTypedDispatch, useTypedSelector } from "@/app/store";
import { siteProductManagementSlide } from "../SiteProductManagement/siteProductManagement.slice";
import { useEffect, useState } from "react";
import styles from "./siteProductCUR.module.less";
import { Col, Form, Input, notification, QRCode, Row, Image, Spin, InputNumber } from "antd";
import clsx from "clsx";
import ButtonNotched from "@/components/ButtonNotched";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { BinType, MasterProductType, WarehouseType } from "@/utils/types";
import BoxIcon from "@/assets/icons/BoxIcon";
import WarehouseCodeDropdownList from "@/components/FilterSelectCustom/WarehouseCodeDropdownList";
import MasterProductCodeDropdownList from "@/components/FilterSelectCustom/MasterProductDropdownList";
import BinsCodeDropdownList from "@/components/FilterSelectCustom/BinsDropdownList";
import UnitDropdownList from "@/components/FilterSelectCustom/UnitDropdownList";
import message from "@/utils/message";
import { useCreateNewProductMutation, useEditProductMutation, useGetProductDetailQuery } from "@/rtk-query/productQueries";
import { getError } from "@/utils/helpers";
import { useGetUserInfoQuery } from "@/rtk-query/userQueries";
import C from "@/utils/constants";
import { CompanySelectType } from "../WarehouseManagement/warehouseManagement.slice";

interface IProductCURModuleProps {
  type: "create" | "edit" | "view";
}
const validatorNumberSimple = (_: any, value: any) => {
  if (!value || (value && +value > 0)) {
    return Promise.resolve();
  }
  return Promise.reject();
};

export const handleCheckNumberKey = (evt: any) => {
  if ([".", "e", "-", "+", ","].includes(evt.key.toLowerCase())) {
    evt.preventDefault();
  }
};

function ProductCURModule({ type }: IProductCURModuleProps) {
  const params: any = useParams();
  const history = useHistory();
  const location = useLocation();
  const { companySelected, productIdSelected, wareHouseSelected, masterProductSelected } = useTypedSelector((store) => store.siteProductManagementSlide);

  const { currentData: userInfo } = useGetUserInfoQuery();

  const [binData, setBinData] = useState<BinType | null>(null);
  const [binSearch, setBinSearch] = useState("");
  const [searchWarehouse, setSearchWarehouse] = useState("");
  const [unit, setUnit] = useState<string>("");

  const [searchSiteProduct, setSearchSiteProduct] = useState("");
  const [form] = Form.useForm();

  const requiredValidate = (field: string, more?: { isNumber?: boolean }) => {
    return {
      required: true,
      validator: (_: any, value: string) => {
        if (more?.isNumber) {
          if (value === null || value === undefined) {
            return Promise.reject();
          }
          return String(value) && String(value)?.length && String(value)?.trim()?.length > 0 ? Promise.resolve() : Promise.reject();
        }
        return value && String(value) && String(value)?.length && String(value)?.trim()?.length > 0 ? Promise.resolve() : Promise.reject();
      },
      message: message.ERROR_NOT_BLANK(field),
    };
  };
  const dispatch = useTypedDispatch();

  const { onSelectCompany, onSelectProduct, onSelectWareHouse, onSelectMasterProduct } = siteProductManagementSlide.actions;
  const [createProduct, { isLoading: isLoadingCreate }] = useCreateNewProductMutation();
  const [updateProduct, { isLoading: isLoadingUpdate }] = useEditProductMutation();

  const { currentData: dataProduct, isLoading: isLoadingDataProduct } = useGetProductDetailQuery(params?.id, {
    skip: !params?.id,
    refetchOnMountOrArgChange: true,
  });

  const handleSelectProduct = (value: string | number | null) => {
    dispatch(onSelectProduct(value));
  };

  const handleSelectWarehouse = (value: WarehouseType | null) => {
    dispatch(onSelectWareHouse(value));
  };

  const handleSelectMasterProduct = (value: MasterProductType | null) => {
    dispatch(onSelectMasterProduct(value));
  };

  const handleSelectCompany = (value: CompanySelectType) => {
    dispatch(onSelectCompany(value));
    if (type === "create") {
      handleSelectWarehouse(null);
      setBinData(null);
      setBinSearch("");
      form.setFieldsValue({ warehouseId: "", binId: "" });
    }
  };

  const handleSubmit = async (values: any) => {
    if (!companySelected?.id) {
      return notification.error({
        message: message.ERROR_NOT_BLANK("Company"),
      });
    }
    const {
      productName = "",
      position = "",
      productCode = "",
      manufactureItemNumber = masterProductSelected?.manufactureItemNumber,
      inventory = "",
      minimumQuantity = "",
      unit = "",
      binId = "",
      defaultReOrderQuantity = "",
    } = values;

    const masterProductId = productIdSelected;

    const body = {
      productName: String(productName),
      position: String(position),
      productCode: String(productCode),
      manufactureItemNumber: String(manufactureItemNumber),
      inventory: Number(inventory),
      minimumQuantity: Number(minimumQuantity),
      unit: String(unit),
      binId: String(binId),
      defaultReOrderQuantity: Number(defaultReOrderQuantity),
    };

    try {
      const res =
        type === "create"
          ? await createProduct({
              ...body,
              masterProductId: String(masterProductId),
            }).unwrap()
          : await updateProduct({ id: params?.id, body });
      if (res?.id || res?.data) {
        notification.success({
          message: message.SUCCESSFULLY,
        });
        handleSelectWarehouse(null);
        history.push(routes.siteProductList.path);
        return;
      }
      if (Array.isArray(res?.error?.data?.errors)) {
        res?.error?.data?.errors?.map(
          (erItem: string) =>
            erItem &&
            notification.error({
              message: erItem,
            })
        );
        return;
      }
      if (res?.errors) {
        notification.error({
          message: res?.message,
        });
      }
    } catch (error) {
      notification.error({
        key: "error_product_cur",
        message: getError(error),
      });
    }
  };

  useEffect(() => {
    if (type === "create") {
      setUnit(masterProductSelected?.unit || dataProduct?.unit || "");
      form.setFieldsValue({
        defaultReOrderQuantity: masterProductSelected?.minimumQuantity || dataProduct?.defaultReOrderQuantity,
        productCode: masterProductSelected?.id,
        productName: masterProductSelected?.productName,
      });
    }
  }, [masterProductSelected]);

  useEffect(() => {
    if (!dataProduct) return;
    const dataProductClone: any = dataProduct;
    handleSelectCompany(dataProductClone?.bin?.warehouse?.company);
    handleSelectProduct(dataProductClone?.masterProduct?.id || null);
    handleSelectMasterProduct(dataProductClone?.masterProduct);
    handleSelectWarehouse(dataProductClone?.bin?.warehouse);
    form.setFieldsValue({
      productName: dataProductClone?.productName,
      productCode: dataProductClone?.productCode,
      position: dataProductClone?.position,
      minimumQuantity: dataProductClone?.minimumQuantity,
      unit: dataProductClone?.unit,
      defaultReOrderQuantity: dataProductClone?.defaultReOrderQuantity,
      inventory: dataProductClone?.inventory,
      binId: dataProductClone?.bin.id,
      warehouseId: dataProductClone?.bin?.warehouse?.identityCode,
    });
    setBinData(dataProduct?.bin || null);
  }, [dataProduct, params]);

  useEffect(() => {
    handleSelectCompany({
      id: userInfo?.company?.id,
      companyName: userInfo?.company?.companyName,
      address: userInfo?.company?.address,
    });
  }, [userInfo]);

  useEffect(() => {
    return () => {
      handleSelectProduct(null);
      handleSelectWarehouse(null);
    };
  }, []);

  const renderViewProduct = () => {
    return (
      <>
        <div className={styles.box1}>
          <Row>
            <Col span={18}>
              <p className={styles.title}>Linkage to Stocktake’s product:</p>
              <Row className={clsx(styles.space, styles.itemCenter, styles.flex)}>
                <Col span={5} className={styles.contentRight}>
                  Product name:
                </Col>
                <Col span={17}>{masterProductSelected?.productName}</Col>
              </Row>
              <Row className={styles.space}>
                <Col span={5} className={styles.contentRight}>
                  Manufacture item no:
                </Col>
                <Col span={17}>{masterProductSelected?.manufactureItemNumber}</Col>
              </Row>
              <Row className={styles.space}>
                <Col span={5} className={styles.contentRight}>
                  Unit:
                </Col>
                <Col span={17}>{masterProductSelected?.unit}</Col>
              </Row>
            </Col>
            <Col span={6}>
              <div className={styles.wrapImage}>
                <p>Image:</p>
                <br />
                <Image
                  src={masterProductSelected?.images?.[0]?.imageUrl || C.IMAGE_DEFAULT}
                  width={146}
                  alt="images"
                  style={{ borderRadius: 5 }}
                  // fallback={C.IMAGE_DEFAULT}
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className={clsx(styles.box1, styles.spaceBox)} style={{ paddingBottom: 100 }}>
          <Row>
            <Col span={12}>
              <Row>
                <Col span={5} className={styles.contentRight}>
                  Company:
                </Col>
                <Col span={17}>{dataProduct?.bin.warehouse?.company.companyName}</Col>
              </Row>
              <Row className={styles.space}>
                <Col span={5} className={styles.contentRight}>
                  Address:
                </Col>
                <Col span={17}>{dataProduct?.bin.warehouse?.company.address}</Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row className={styles.itemCenter}>
                <Col span={5} className={styles.contentRight}>
                  Warehouse code:
                </Col>
                <Col span={17}>{dataProduct?.bin.warehouse?.identityCode}</Col>
              </Row>
              <Row className={styles.space}>
                <Col span={5} className={styles.contentRight}>
                  Location:
                </Col>
                <Col span={17}>{dataProduct?.bin.warehouse?.location}</Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className={clsx(styles.box1, styles.spaceBox)}>
          <Row>
            <Col span={12}>
              <Row className={styles.itemCenter}>
                <Col span={5} className={styles.contentRight}>
                  Product name:
                </Col>
                <Col span={17}>{dataProduct?.productName}</Col>
              </Row>
              <Row className={clsx(styles.space, styles.itemCenter, styles.flex)}>
                <Col span={5} className={styles.contentRight}>
                  Product id:
                </Col>
                <Col span={17}>{dataProduct?.productCode}</Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row className={styles.itemCenter}>
                <Col span={6} className={styles.contentRight}>
                  Inventory:
                </Col>
                <Col span={18}>{dataProduct?.inventory}</Col>
              </Row>
              <Row className={clsx(styles.space, styles.itemCenter, styles.flex)}>
                <Col span={6} className={styles.contentRight}>
                  Max Par Level:
                </Col>
                <Col span={18}>{dataProduct?.minimumQuantity}</Col>
              </Row>
            </Col>
            {/* // info product */}
            <Col span={12} className={styles.space}>
              <Row className={styles.itemCenter}>
                <Col span={5} className={styles.contentRight}>
                  Bin Code:
                </Col>
                <Col span={17}>
                  <div className={clsx(styles.wFull, styles.flex)}>
                    <div style={{ width: "50%" }}>{dataProduct?.bin.binCode}</div>
                    <div
                      className={clsx(styles.itemCenter, styles.flex)}
                      style={{
                        marginLeft: 34,
                        whiteSpace: "nowrap",
                      }}
                    >
                      Position:
                      <div className={styles.txtLeft}>{dataProduct?.position}</div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col span={12} className={styles.space}>
              <Row>
                <Col className={clsx(styles.flex)} span={6}>
                  Unit:
                  <span className={styles.txtLeft}>{dataProduct?.unit}</span>
                </Col>
                <Col className={clsx(styles.itemCenter, styles.flex)} span={18}>
                  <Row className={clsx(styles.flex, styles.wFull)}>
                    <Col span={7} className={styles.contentRight} style={{ textAlign: "right" }}>
                      Default Reorder
                      <br /> Quantity:
                    </Col>
                    <Col span={17} className={styles.wFull}>
                      {dataProduct?.defaultReOrderQuantity}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </>
    );
  };

  const renderCreateSiteProduct = () => {
    if (productIdSelected)
      return (
        <>
          <div className={styles.box1}>
            <Row>
              <Col span={18}>
                <p className={styles.title}>Linkage to Stocktake’s product:</p>
                <Row className={clsx(styles.space, styles.flex)}>
                  <Col span={5} className={clsx(styles.contentRight, styles.leftMarginTop)}>
                    Product name:
                  </Col>
                  <Col span={17}>
                    <MasterProductCodeDropdownList
                      disabled={type === "edit"}
                      placeholder="Type here..."
                      style={{ width: 441, marginLeft: 13 }}
                      search={searchSiteProduct}
                      value={productIdSelected}
                      onChange={(productIdSelected: any, data?: MasterProductType) => {
                        handleSelectProduct(productIdSelected);
                        handleSelectMasterProduct(data || null);
                        setSearchSiteProduct("");
                      }}
                      dataSelected={masterProductSelected}
                      className={styles.masterProduct}
                      filter={{
                        ignoreDeleted: true,
                      }}
                    />
                  </Col>
                </Row>
                <Row className={styles.space}>
                  <Col span={5} className={styles.contentRight}>
                    Manufacture item no:
                  </Col>
                  <Col span={17}>{masterProductSelected?.manufactureItemNumber}</Col>
                </Row>
                <Row className={styles.space}>
                  <Col span={5} className={styles.contentRight}>
                    Unit:
                  </Col>
                  <Col span={17}>{masterProductSelected?.unit}</Col>
                </Row>
              </Col>
              <Col span={6}>
                <div className={styles.wrapImage}>
                  <p>Image:</p>
                  <br />
                  <Image
                    src={masterProductSelected?.images?.[0]?.imageUrl || ""}
                    width={146}
                    alt="images"
                    style={{ borderRadius: 5 }}
                    fallback={C.IMAGE_DEFAULT}
                  />
                </div>
              </Col>
            </Row>
          </div>
          <div className={clsx(styles.box1, styles.spaceBox)} style={{ paddingBottom: 100 }}>
            <Row>
              <Col span={12}>
                <Row>
                  <Col span={5} className={styles.contentRight}>
                    Company:
                  </Col>
                  <Col span={17}>{companySelected?.companyName}</Col>
                </Row>
                <Row className={styles.space}>
                  <Col span={5} className={styles.contentRight}>
                    Address:
                  </Col>
                  <Col span={17}>{companySelected?.address}</Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row>
                  <Col span={5} className={clsx(styles.contentRight, styles.leftMarginTop)}>
                    Warehouse code:
                  </Col>
                  <Col span={17}>
                    <Form.Item name="warehouseId" rules={[requiredValidate("Warehouse code")]}>
                      <WarehouseCodeDropdownList
                        placeholder={wareHouseSelected?.identityCode}
                        value={wareHouseSelected?.identityCode || null}
                        search={searchWarehouse}
                        onChange={() => {
                          setSearchWarehouse("");
                          form.setFieldsValue({ binId: "" });
                        }}
                        onChangeWareHouse={handleSelectWarehouse}
                        filter={{
                          companyId: companySelected?.id,
                          ignoreDeleted: true,
                        }}
                        className={styles.dropdown}
                        size="middle"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className={styles.space}>
                  <Col span={5} className={styles.contentRight}>
                    Location:
                  </Col>
                  <Col span={17}>{wareHouseSelected?.location}</Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div className={clsx(styles.box1, styles.spaceBox)}>
            <Row>
              <Col span={12}>
                <Row className={styles.itemCenter}>
                  <Col span={5} className={clsx(styles.contentRight, styles.leftMarginTop)}>
                    Product name:
                  </Col>
                  <Col span={17}>
                    <Form.Item name="productName" rules={[requiredValidate("Product name")]}>
                      <Input defaultValue={masterProductSelected?.productName} size="middle" style={{ borderRadius: 0, height: 32 }} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className={clsx(styles.space, styles.flex)}>
                  <Col span={5} className={clsx(styles.contentRight, styles.leftMarginTop)}>
                    Product id:
                  </Col>
                  <Col span={17}>
                    <Form.Item name="productCode" rules={[requiredValidate("Product id")]}>
                      <Input defaultValue={masterProductSelected?.productCode} size="middle" style={{ borderRadius: 0, height: 32 }} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row>
                  <Col span={6} className={clsx(styles.contentRight, styles.leftMarginTop)}>
                    Inventory:
                  </Col>
                  <Col span={18}>
                    <Form.Item
                      name="inventory"
                      rules={[
                        requiredValidate("inventory", { isNumber: true }),
                        {
                          required: true,
                          validator: validatorNumberSimple,
                          message: "Information is invalid!",
                        },
                      ]}
                    >
                      <InputNumber
                        onKeyDown={handleCheckNumberKey}
                        min={0}
                        size="middle"
                        name="inventory"
                        type="number"
                        style={{ borderRadius: 0, height: 32, width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className={clsx(styles.space, styles.flex)}>
                  <Col span={6} className={clsx(styles.contentRight, styles.leftMarginTop)}>
                    Max Par Level:
                  </Col>
                  <Col span={18}>
                    <Form.Item
                      name="minimumQuantity"
                      rules={[
                        requiredValidate("Max Par Level", {
                          isNumber: true,
                        }),
                        {
                          validator: validatorNumberSimple,
                          message: "Information is invalid!",
                        },
                      ]}
                    >
                      <InputNumber
                        onKeyDown={handleCheckNumberKey}
                        min={0}
                        size="middle"
                        type="number"
                        style={{ borderRadius: 0, height: 32, width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              {/* // info product */}
              <Col span={12} style={{ justifyContent: "center" }}>
                <Row className={clsx(styles.space, styles.flex)}>
                  <Col span={12} className={clsx(styles.flex)} style={{ justifyContent: "center" }}>
                    <Col span={3} className={styles.leftMarginTop}>
                      Bin Code:
                    </Col>
                    <Col span={9} className={styles.txtLeft}>
                      <Form.Item name="binId" rules={[requiredValidate("Bin Code")]} className={styles.wFull} style={{ minWidth: 162 }}>
                        <BinsCodeDropdownList
                          className={clsx(styles.dropdown, styles.wFull)}
                          onChange={(e) => {
                            setBinData(e || null);
                            setBinSearch("");
                            form.setFieldsValue({ binId: e?.id || "" });
                          }}
                          value={binData?.id}
                          search={binSearch}
                          filter={{
                            warehouseId: wareHouseSelected?.id,
                            ignoreDeleted: true,
                          }}
                          skip={!wareHouseSelected?.id}
                        />
                      </Form.Item>
                    </Col>
                  </Col>
                  <Col span={12}>
                    <Row className={styles.flex}>
                      <Col className={styles.leftMarginTop} span={5}>
                        Position:
                      </Col>
                      <Col className={styles.txtLeft} span={7}>
                        <Form.Item name="position" rules={[requiredValidate("Position")]}>
                          <Input
                            size="middle"
                            name="position"
                            placeholder="Type here..."
                            style={{
                              borderRadius: 0,
                              height: 32,
                              minWidth: 183,
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={12} className={styles.space}>
                <Row>
                  <Col className={clsx(styles.flex)} span={7}>
                    <p className={styles.leftMarginTop}>Unit:</p>
                    <span className={styles.txtLeft}>
                      <Form.Item name="unit" rules={[requiredValidate("Unit")]}>
                        <UnitDropdownList
                          className={styles.dropdown}
                          value={unit}
                          onChange={(value) => {
                            setUnit(value || "");
                            form.setFieldsValue({ unit: value || "" });
                          }}
                        />
                      </Form.Item>
                    </span>
                  </Col>
                  <Col className={styles.flex} span={15}>
                    <Row className={clsx(styles.flex, styles.wFull)}>
                      <Col span={6} className={styles.contentRight} style={{ textAlign: "right" }}>
                        Default Reorder Quantity:
                      </Col>
                      <Col span={18} className={styles.wFull}>
                        <Form.Item
                          name="defaultReOrderQuantity"
                          rules={[
                            requiredValidate("Default Reorder Quantity", {
                              isNumber: true,
                            }),
                          ]}
                          className={styles.wFull}
                        >
                          <InputNumber
                            onKeyDown={handleCheckNumberKey}
                            min={masterProductSelected?.minimumQuantity}
                            size="middle"
                            type="number"
                            step={20}
                            style={{
                              borderRadius: 0,
                              height: 32,
                              width: "100%",
                            }}
                            className={styles.wFull}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </>
      );
    return (
      <div className={styles.box1}>
        <Row>
          <Col span={18}>
            <p className={styles.title}>Linkage to Stocktake’s product:</p>
            <Row className={clsx(styles.space, styles.itemCenter, styles.flex)}>
              <Col span={5} className={styles.contentRight}>
                Product name:
              </Col>
              <Col span={17}>
                <MasterProductCodeDropdownList
                  disabled={type === "edit"}
                  placeholder="Type here..."
                  style={{ width: 441, marginLeft: 13 }}
                  search={searchSiteProduct}
                  value={productIdSelected}
                  onChange={(e: any, data?: MasterProductType | null) => {
                    handleSelectProduct(e);
                    setSearchSiteProduct("");
                    handleSelectMasterProduct(data || null);
                  }}
                  className={styles.masterProduct}
                  filter={{
                    ignoreDeleted: true,
                  }}
                  dataSelected={masterProductSelected}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  };

  const titlePage = () => {
    let title = "";
    switch (type) {
      case "edit":
        title = routes.editSiteProduct.title;
        break;
      case "view":
        title = routes.viewSiteProduct.title;
        break;
      default:
        title = routes.createSiteProduct.title;
        break;
    }

    return title;
  };
  return (
    <ContentWrapper
      dataBreadCrumb={[
        {
          title: routes.siteProductList.title,
          icon: <BoxIcon />,
          url: routes.siteProductList.path,
        },
        {
          title: titlePage(),
          url: location.pathname,
          style: {
            fontWeight: 800,
          },
        },
      ]}
      renderFilter={<FilterComponent type={type} onSelectCompany={handleSelectCompany} onSelectWareHouse={handleSelectWarehouse} />}
    >
      {isLoadingDataProduct ? (
        <Row
          justify="center"
          style={{
            padding: 36,
          }}
        >
          <Spin tip="Loading" size="large" />
        </Row>
      ) : (
        <Form name="control-hooks" labelCol={{ span: 4, sm: 6 }} wrapperCol={{ span: 24 }} onFinish={handleSubmit} autoComplete="off" form={form}>
          <div className={styles.siteProductView}>
            {type === "view" ? renderViewProduct() : renderCreateSiteProduct()}

            {productIdSelected && (
              <div className={clsx(styles.spaceBox, styles.boxBottom)}>
                {type !== "create" ? (
                  <div className={styles.boxQr}>
                    <p style={{ color: "#234C66" }}>QR code</p>
                    <QRCode
                      style={{ width: 110, height: 110 }}
                      value={JSON.stringify({
                        productId: dataProduct?.id,
                        companyId: companySelected?.id,
                      })}
                    />
                  </div>
                ) : (
                  <div />
                )}
                <div className={clsx(styles.wrapButtons)}>
                  <Link to={routes.siteProductList.path}>
                    <ButtonNotched type="dark">{type === "create" ? "Cancel" : "Back"}</ButtonNotched>
                  </Link>
                  {type === "view" && (
                    <Link to={routes.editSiteProduct.path.replace(":id", dataProduct?.id || "")}>
                      <ButtonNotched type="primary">Edit</ButtonNotched>
                    </Link>
                  )}
                  {(type === "create" || type === "edit") && (
                    <div>
                      {isLoadingCreate || isLoadingUpdate ? (
                        <Spin>
                          <button
                            type="submit"
                            style={{
                              background: "transparent",
                              border: "none",
                            }}
                          >
                            <ButtonNotched type="primary">Save</ButtonNotched>
                          </button>
                        </Spin>
                      ) : (
                        <button
                          type="submit"
                          style={{
                            background: "transparent",
                            border: "none",
                          }}
                        >
                          <ButtonNotched type="primary">Save</ButtonNotched>
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </Form>
      )}
    </ContentWrapper>
  );
}

export default ProductCURModule;
