import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IinitialState {
  fileIdImporting: string | number | null;
}

const initialState: IinitialState = {
  fileIdImporting: null,
};

export const excelImportSlide = createSlice({
  name: "excelImportSlide",
  initialState,
  reducers: {
    updateFileIdImporting(
      state,
      action: PayloadAction<string | number | null>
    ) {
      state.fileIdImporting = action.payload;
    },
  },
});

export const excelImportReducer = excelImportSlide.reducer;
