import { IconProps } from "@/utils/types";

function PersonIcon({ color = "#8491A6", style }: IconProps) {
  return (
    <svg
      width="19"
      height="25"
      viewBox="0 0 19 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M9.49994 11.8913C12.2773 11.8913 14.529 9.22934 14.529 5.94566C14.529 2.66192 13.7898 0 9.49994 0C5.21012 0 4.4707 2.66192 4.4707 5.94566C4.4707 9.22934 6.72242 11.8913 9.49994 11.8913Z"
        fill={color}
      />
      <path
        d="M0.000988962 20.9691C0.000132496 20.7689 -0.00072397 20.9127 0.000988962 20.9691V20.9691Z"
        fill={color}
      />
      <path
        d="M18.9985 21.1262C19.0012 21.0714 18.9995 20.7459 18.9985 21.1262V21.1262Z"
        fill={color}
      />
      <path
        d="M18.9879 20.7296C18.8948 15.0094 18.1272 13.3794 12.2538 12.3477C12.2538 12.3477 11.427 13.3731 9.49997 13.3731C7.57292 13.3731 6.746 12.3477 6.746 12.3477C0.936665 13.3682 0.122452 14.9739 0.0153937 20.5438C0.00661494 20.9986 0.00254675 21.0225 0.000976562 20.9697C0.00133342 21.0686 0.0017615 21.2516 0.0017615 21.5707C0.0017615 21.5707 1.40008 24.3144 9.49997 24.3144C17.5997 24.3144 18.9982 21.5707 18.9982 21.5707C18.9982 21.3657 18.9983 21.2231 18.9985 21.1261C18.997 21.1588 18.9938 21.0955 18.9879 20.7296Z"
        fill={color}
      />
    </svg>
  );
}
export default PersonIcon;
