import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IinitialState {
  isOpenSidebar: boolean;
}

const initialState: IinitialState = {
  isOpenSidebar: true,
};

export const dashboardLayoutSlide = createSlice({
  name: "dashboardLayoutSlide",
  initialState,
  reducers: {
    toggleSidebar(state, action: PayloadAction<boolean>) {
      state.isOpenSidebar = action.payload;
    },
  },
});

export const dashboardLayoutReducer = dashboardLayoutSlide.reducer;
