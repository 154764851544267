import { IconProps } from "@/utils/types";

function NextIcon({ color = "black", style }: IconProps) {
  return (
    <svg
      width="9"
      height="14"
      viewBox="0 0 9 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M1.645 0L0 1.645L5.34333 7L0 12.355L1.645 14L8.645 7L1.645 0Z"
        fill={color}
      />
    </svg>
  );
}
export default NextIcon;
