import { useGetUserInfoQuery } from "@/rtk-query/userQueries";
import { Redirect, Route, RouteProps, useLocation } from "react-router";
import routes from "../../routes";
import { Row, Spin } from "antd";
import C from "@/utils/constants";
import { useCheckPermission } from "@/utils/helpers";

interface IProtectedRouteProps extends RouteProps {
  screenAction?: typeof C.PERMISSIONS[keyof typeof C.PERMISSIONS];
}

const ProtectedRoute = ({ screenAction, ...rest }: IProtectedRouteProps) => {
  const location = useLocation();
  const { currentData, isError } = useGetUserInfoQuery();
  const canGoToPage = useCheckPermission(screenAction);

  if (isError) {
    return (
      <Redirect
        to={{
          pathname: routes.logout.path,
          state: { from: location },
        }}
      />
    );
  }

  if (currentData === undefined) {
    return (
      <Row
        justify="center"
        align="middle"
        style={{
          height: "100vh",
          background: "#edeff6",
        }}
      >
        <Spin />
      </Row>
    );
  }

  if (currentData?.isFirstTimeLogin) {
    return (
      <Redirect
        to={{
          pathname: routes.changePassword.path,
          state: { from: location },
        }}
      />
    );
  }

  return canGoToPage ? (
    <Route {...rest} />
  ) : (
    <Redirect
      to={{
        pathname: routes[403].path,
        state: { from: location },
      }}
    />
  );
};

export default ProtectedRoute;
