// import { CommonArray, CommonQueryType, ProductType, SimilarProductType } from '@/utils/types';
import { cleanObject } from "@/utils/helpers";
import baseApi from ".";

const commonApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getRoleList: builder.query<
      any,
      {
        isSuperAdmin?: boolean;
        search?: string;
        type?: string;
        page?: number;
        pageSize?: number;
        sortBy?: string;
        sortOrder?: string;
        status?: string | number;
      }
    >({
      query: (params) => ({
        url: "/roles",
        method: "GET",
        params: cleanObject(params),
      }),
      providesTags: [],
      transformResponse: (response: { data: any }) => response.data,
    }),
    getCallingCodeList: builder.query<any, any>({
      query: (params) => ({
        url: "/users/calling-code",
        method: "GET",
        params: cleanObject(params),
      }),
      providesTags: [],
      transformResponse: (response: { data: any }) => response.data,
    }),
  }),
});

export default commonApi;
export const { useGetRoleListQuery, useGetCallingCodeListQuery } = commonApi;
