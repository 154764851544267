import { checkTotalAllItem } from "@/utils/helpers";
import message from "@/utils/message";
import { Empty, Row, Spin } from "antd";
import { ReactNode } from "react";

interface IEmptyWrapperProps {
  children?: ReactNode;
  loading?: boolean;
  data?: Array<any>;
  filter?: any;
}

const EmptyWrapper = ({
  children,
  loading,
  data,
  filter,
}: IEmptyWrapperProps) => {
  if (loading)
    return (
      <Row
        justify="center"
        style={{
          padding: 36,
        }}
      >
        <Spin tip="Loading" size="large" />
      </Row>
    );
  if ((data || []).length > 0) return <>{children}</>;
  return (
    <Empty
      style={{
        color: "#00000040",
      }}
      description={
        !checkTotalAllItem(filter)
          ? message.NO_DATA
          : message.NO_MATCHING_RESULT
      }
    />
  );
};

export default EmptyWrapper;
