import { Col, Form, Input, Pagination, Row, notification } from "antd";
import Title from "antd/es/typography/Title";
import clsx from "clsx";
import ButtonNotched from "@/components/ButtonNotched";
import commonStyles from "@/styles/common.module.less";
import message from "@/utils/message";
import styles from "../../warehouseCUR.module.less";
import {
  useCreateNewBinMutation,
  useDeleteBinMutation,
  useEditBinMutation,
  useExportBinMutation,
  useGetBinListQuery,
  useRestoreBinMutation,
} from "@/rtk-query/binQueries";
import { useEffect, useState } from "react";
import ButtonRectangle from "@/components/ButtonRectangle";
import { useHistory, useLocation, useParams } from "react-router";
import { getError } from "@/utils/helpers";
import C from "@/utils/constants";
import EmptyWrapper from "@/components/EmptyCustom";
import { Toast } from "@/app/store";

type TypeItem = "create" | "edit" | "view";

interface IBinItemProps {
  type: TypeItem;
  value?: string;
  keyId?: string | number;
  binList?: Array<any>;
  setBinList?: (value: Array<any>) => void;
  field?: any;
}

const BinItem = ({ keyId, type, value, field, binList, setBinList }: IBinItemProps) => {
  const location = useLocation();
  const history = useHistory();
  const params: any = useParams();

  const [typeState, setTypeState] = useState<TypeItem>("create");
  const [binCode, setBinCode] = useState<string>("");

  const [createBinApi, { isLoading: isLoadingCreate }] = useCreateNewBinMutation();
  const [editBinApi, { isLoading: isLoadingEdit }] = useEditBinMutation();
  const [deleteBinApi, { isLoading: isLoadingDelete }] = useDeleteBinMutation();
  const [restoreBinApi, { isLoading: isLoadingRestore }] = useRestoreBinMutation();
  const [exportBinApi, { isLoading: isLoadingExport }] = useExportBinMutation();

  const isLoading = isLoadingCreate || isLoadingEdit || isLoadingDelete || isLoadingRestore;

  const handleCancel = () => {
    setBinList?.((binList || []).filter((item) => item.key !== keyId));
  };

  const handleUpdateBin = async (type: "create" | "edit" | "delete" | "restore", id: any, binCode?: string) => {
    const body = {
      warehouseId: params?.id,
      binCode: binCode || "",
    };
    Toast.close();

    try {
      switch (type) {
        case "create":
          await createBinApi(body).unwrap();
          handleCancel?.();
          break;
        case "edit":
          await editBinApi({
            id,
            body,
          }).unwrap();
          break;
        case "delete":
          await deleteBinApi(id).unwrap();
          break;
        case "restore":
          await restoreBinApi(id).unwrap();
          break;
        default:
          break;
      }
      notification.success({
        message: message.SUCCESSFULLY,
        key: "bin_curd",
      });
    } catch (err) {
      notification.error({
        key: "bin_curd",
        message: getError(err),
      });
    }
  };

  const handleDeletePopup = (id: string | number) => {
    Toast.delete({
      isOpen: true,
      title: "Are you sure you want to delete this bin?",
      description: "You will not able to view and edit this bin information.",
      cancelText: "Cancel",
      okText: "Delete",
      onCancel: () => {
        Toast.close();
      },
      onOk: () => handleUpdateBin("delete", id),
      okStyle: {
        background: "#F32121",
      },
    });
  };

  const handleExportBins = async (id: string | number) => {
    try {
      await exportBinApi({
        id,
      }).unwrap();
      notification.success({
        key: "bins_export",
        message: message.SUCCESSFULLY,
      });
    } catch (error) {
      notification.error({
        key: "bins_export",
        message: getError(error),
      });
    }
  };

  useEffect(() => {
    setBinCode(value || "");
  }, [value]);

  useEffect(() => {
    setTypeState(type);
  }, [type]);

  const styleInputDisable = {
    background: "transparent",
    border: "none",
  };

  const viewOnly = typeState === "view";

  return (
    <Row
      align="middle"
      style={{
        gap: 16,
      }}
    >
      <div
        className={styles.item}
        style={{
          flex: 1,
        }}
      >
        <Row gutter={[12, 0]}>
          <Col span={viewOnly ? 7 : 9} xxl={viewOnly ? 9 : 11}>
            <Form.Item
              label={<span className={commonStyles.label}>Bin Code</span>}
              rules={[
                {
                  required: true,
                  message: message.ERROR_NOT_BLANK("Bin Code"),
                },
              ]}
            >
              {viewOnly ? (
                <div
                  style={{
                    fontSize: 12,
                    color: "#234c66",
                    fontWeight: 600,
                    padding: "0px 11px",
                  }}
                >
                  {binCode}
                </div>
              ) : (
                <Input
                  className={clsx(commonStyles.textField)}
                  style={{
                    height: 38,
                    width: 560,
                    maxWidth: "100%",
                    ...(viewOnly ? styleInputDisable : {}),
                  }}
                  size="large"
                  value={binCode}
                  onChange={(event) => setBinCode(event.target.value)}
                  disabled={viewOnly}
                />
              )}
            </Form.Item>
          </Col>
          {viewOnly && (
            <Col
              style={{
                flex: 1,
              }}
            >
              <Row
                justify="end"
                style={{
                  justifyContent: "flex-end",
                  gap: 20,
                }}
              >
                {field?.deletedAt ? (
                  <ButtonRectangle
                    style={{
                      height: 36,
                      width: 150,
                    }}
                    onClick={() => handleUpdateBin("restore", field?.id)}
                    disabled={isLoading}
                  >
                    Restore
                  </ButtonRectangle>
                ) : (
                  <>
                    <ButtonRectangle
                      style={{
                        height: 36,
                        width: 150,
                        background: "#8491A6",
                      }}
                      onClick={() => {
                        history.push(`${location.pathname}?binId=${field?.id || ""}&binCode=${value || ""}`);
                      }}
                    >
                      View
                    </ButtonRectangle>
                    <ButtonRectangle
                      style={{
                        height: 36,
                        width: 150,
                      }}
                      onClick={() => setTypeState("edit")}
                    >
                      Edit
                    </ButtonRectangle>
                    <ButtonRectangle
                      style={{
                        height: 36,
                        width: 150,
                        background: "#07657A",
                      }}
                      disabled={isLoadingExport}
                      onClick={() => handleExportBins(field?.id)}
                    >
                      Export excel file
                    </ButtonRectangle>
                    <ButtonRectangle
                      style={{
                        height: 36,
                        width: 150,
                        background: "#DF0505",
                      }}
                      onClick={() => handleDeletePopup(field?.id)}
                      disabled={isLoading}
                    >
                      Delete
                    </ButtonRectangle>
                  </>
                )}
              </Row>
            </Col>
          )}
        </Row>
      </div>
      {!viewOnly && (
        <>
          <Col span={2}>
            <ButtonNotched
              style={{
                width: "100%",
              }}
              onClick={() => {
                handleCancel?.();
                setTypeState("view");
                setBinCode(value || "");
              }}
              type="dark"
            >
              Cancel
            </ButtonNotched>
          </Col>

          <Col span={2}>
            <ButtonNotched
              style={{
                width: "100%",
              }}
              onClick={() => {
                if (isLoading) return;
                if (typeState === "create") {
                  handleUpdateBin?.("create", null, binCode);
                }
                if (typeState === "edit") {
                  handleUpdateBin?.("edit", field?.id, binCode);
                }
              }}
            >
              {type === "create" ? "Create" : "Save"}
            </ButtonNotched>
          </Col>
        </>
      )}
    </Row>
  );
};

interface IBinListProps {
  newBinList: Array<{ key: string; binCode: string }>;
  setNewBinList: (value: Array<{ key: string; binCode: string }>) => void;
}

function BinList({ newBinList, setNewBinList }: IBinListProps) {
  const params: any = useParams();

  const [page, setPage] = useState(1);

  const { currentData: binList, isFetching: isFetchingBinList } = useGetBinListQuery({
    page,
    warehouseId: params?.id,
    pageSize: C.PAGE_SIZE_LITTLE,
    isLoading: undefined,
  });

  return (
    <>
      <Title
        level={5}
        style={{
          color: "#234C66",
          marginBottom: 20,
        }}
      >
        LIST OF BINS
      </Title>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 16,
        }}
      >
        {newBinList.map((item) => (
          <BinItem type="create" key={item.key} keyId={item.key} binList={newBinList} setBinList={setNewBinList} />
        ))}
        <EmptyWrapper loading={isFetchingBinList} data={[...(binList?.data || []), ...newBinList]} filter={{ page }}>
          {(binList?.data || []).map((field) => (
            <BinItem type="view" field={field} key={field?.id} value={field?.binCode} />
          ))}
          {(binList?.totalPage || 0) > 1 && (
            <Row
              justify="end"
              style={{
                marginTop: 24,
              }}
            >
              <Pagination
                current={page}
                total={binList?.totalPage || 0}
                pageSize={1}
                onChange={(page) => {
                  setPage(page);
                }}
                showSizeChanger={false}
              />
            </Row>
          )}
        </EmptyWrapper>
      </div>
    </>
  );
}

export default BinList;
