import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CSSProperties } from "react";

type DialogType = "success" | "dangerous" | "warning" | "default";
interface IinitialState {
  isOpen: boolean;
  type?: DialogType;
  title?: string;
  description?: string;
  onOk?: VoidFunction;
  onCancel?: VoidFunction;
  okText?: string;
  cancelText?: string;
  okStyle?: CSSProperties;
  cancelStyle?: CSSProperties;
  showCloseIcon?: boolean;
}

const initialState: IinitialState = {
  isOpen: false,
  type: "default",
  title: "",
  description: "",
  onOk: () => {},
  onCancel: () => {},
  okText: "",
  cancelText: "",
  okStyle: {},
  cancelStyle: {},
  showCloseIcon: true,
};

export const dialogConfirmationSlide = createSlice({
  name: "dialogConfirmationSlide",
  initialState,
  reducers: {
    updateDialogConfirmationState(state, action: PayloadAction<IinitialState>) {
      state.isOpen = action.payload.isOpen;
      state.type = action.payload.type || "default";
      state.title = action.payload.title || "";
      state.description = action.payload.description || "";
      state.onOk = action.payload.onOk;
      state.onCancel = action.payload.onCancel;
      state.okText = action.payload.okText || "";
      state.cancelText = action.payload.cancelText || "";
      state.okStyle = action.payload.okStyle || {};
      state.cancelStyle = action.payload.cancelStyle || {};
      state.showCloseIcon = action.payload.showCloseIcon || true;
    },
  },
});

export const dialogConfirmationReducer = dialogConfirmationSlide.reducer;
