import PersonIcon from "@/assets/icons/PersonIcon";
import ContentWrapper from "@/components/layouts/DashboardLayout/Content";
import routes from "@/routes";
import { useGetUserInfoQuery, useUpdateUserMutation } from "@/rtk-query/userQueries";
import { Col, DatePicker, Form, Input, Row, Select, notification } from "antd";
import dayjs from "dayjs";
import { useHistory, useLocation } from "react-router";
import styles from "../ClientCUR/clientCUR.module.less";
import commonStyles from "@/styles/common.module.less";
import message from "@/utils/message";
import { renderFieldViewOnly, getError } from "@/utils/helpers";
import clsx from "clsx";
import C from "@/utils/constants";
import { useGetCallingCodeListQuery, useGetRoleListQuery } from "@/rtk-query/commonQueries";
import CalendarIcon from "@/assets/icons/CalendarIcon";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ButtonNotched from "@/components/ButtonNotched";
import { useEffect } from "react";

interface IUserProfileModule {
  type: "view" | "edit";
}

function UserProfileModule({ type }: IUserProfileModule) {
  const location = useLocation();
  const history = useHistory();
  const [form] = Form.useForm();
  const viewOnly = type === "view";
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { currentData: detailUser, isFetching } = useGetUserInfoQuery();
  const { currentData: callingCodeList } = useGetCallingCodeListQuery({});
  const { currentData: roleList } = useGetRoleListQuery({});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [updateUserApi, { isLoading: isLoadingUpdateUser }] = useUpdateUserMutation();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const initialData = () => {
    const initialValues = {
      fullName: detailUser?.fullName || "",
      username: detailUser?.username || "",
      role: detailUser?.roles?.[0]?.id || "",
      dob: detailUser?.dob ? dayjs(detailUser?.dob, "YYYY-MM-DD") : "",
      status: detailUser?.status || 1,
      contact: {
        phoneExt: detailUser?.phoneExt || "",
        phoneNumber: detailUser?.phoneNumber || "",
      },
      address: detailUser?.address || "",
    };
    form.setFields(
      Object.entries(initialValues).map(([name, value]) => ({
        name,
        value,
      }))
    );
  };

  const requiredValidate = (field: string) => ({
    required: true,
    validator: (_: any, value: string) => (value?.trim().length > 0 ? Promise.resolve() : Promise.reject()),
    message: message.ERROR_NOT_BLANK(field),
  });

  const dropdownStyle = {
    background: "#dfe3ef",
    padding: 0,
    borderRadius: 0,
    boxShadow: "inset 4px 4px 3px rgba(0, 0, 0, 0.03)",
    fontSize: 12,
    fontWeight: 600,
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSubmit = async ({ fullName, username, role, dob, contact, address, status }: any) => {
    const body = {
      fullName,
      roleId: role,
      dob: dayjs(dob).format("YYYY-MM-DD"),
      ...contact,
      address,
      status,
    } as any;
    try {
      await updateUserApi({ id: detailUser?.id || "", body }).unwrap();
      notification.success({
        message: message.SUCCESSFULLY,
        key: "user_cur",
      });
    } catch (err) {
      notification.error({
        key: "user_cur",
        message: getError(err),
      });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleCancel = () => {
    history.goBack();
  };

  useEffect(() => {
    initialData();
    return () => form.resetFields();
  }, [detailUser]);

  return (
    <ContentWrapper
      title={routes.userProfile.title}
      dataBreadCrumb={[
        {
          title: routes.clientList.title,
          url: routes.clientList.path,
          icon: <PersonIcon />,
        },
        {
          title: routes.userProfile.title,
          url: location.pathname,
          style: {
            fontWeight: 800,
          },
        },
      ]}
    >
      <Form name="basic" labelCol={{ span: 6 }} wrapperCol={{ span: 24 }} onFinish={handleSubmit} autoComplete="off" form={form}>
        <div className={styles.infoGeneral}>
          <Row gutter={[50, 16]}>
            <Col span={12}>
              <Form.Item label={<span className={commonStyles.label}>Fullname</span>} name="fullName" rules={[requiredValidate("Fullname")]}>
                {viewOnly ? (
                  renderFieldViewOnly(detailUser?.fullName || "")
                ) : (
                  <Input
                    className={clsx(commonStyles.textField)}
                    style={{
                      height: 38,
                    }}
                    size="large"
                    disabled={viewOnly}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<span className={commonStyles.label}>D.O.B</span>}
                name="dob"
                rules={[
                  {
                    required: true,
                    message: message.ERROR_NOT_BLANK("D.O.B"),
                  },
                ]}
              >
                {viewOnly ? (
                  renderFieldViewOnly(detailUser?.dob ? dayjs(detailUser.dob).format("DD/MM/YYYY") : "")
                ) : (
                  <DatePicker className={commonStyles.textField} format="DD/MM/YY" suffixIcon={<CalendarIcon />} disabled={viewOnly} />
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={<span className={commonStyles.label}>Address</span>}
                name="address"
                rules={[requiredValidate("Address")]}
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 3 }}
              >
                {viewOnly ? renderFieldViewOnly(detailUser?.address || "") : <Input className={clsx(commonStyles.textField)} size="large" />}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={<span className={commonStyles.label}>Contact</span>} required>
                {viewOnly ? (
                  renderFieldViewOnly(`${detailUser?.phoneExt} ${detailUser?.phoneNumber}`.trim()) || ""
                ) : (
                  <Input.Group compact>
                    <Form.Item
                      name={["contact", "phoneExt"]}
                      noStyle
                      rules={[
                        {
                          required: true,
                          message: message.ERROR_NOT_BLANK("Country code"),
                        },
                      ]}
                    >
                      <Select
                        style={{ width: "30%" }}
                        dropdownStyle={dropdownStyle}
                        popupClassName="customDropdownSelectAntd"
                        className={commonStyles.select}
                        size="large"
                        disabled={viewOnly}
                        showSearch
                        filterOption={(input, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}
                      >
                        {(callingCodeList || []).map(({ alpha2Code, code }: any, index: number) => (
                          <Select.Option value={code} key={index}>
                            {`${alpha2Code} ${code}`}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name={["contact", "phoneNumber"]}
                      noStyle
                      rules={[
                        renderFieldViewOnly("Contact"),
                        {
                          pattern: new RegExp(C.REGEX_PHONE_NUMBER),
                          validator: (rule, value) => {
                            if (rule.pattern?.test(value)) return Promise.resolve();
                            return Promise.reject();
                          },
                          message: message.ERROR_INVALID("contact number"),
                        },
                      ]}
                    >
                      <Input style={{ width: "70%", height: 38, borderRadius: 0 }} className={clsx(commonStyles.textField)} size="large" disabled={viewOnly} />
                    </Form.Item>
                  </Input.Group>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<span className={commonStyles.label}>Status</span>}
                name="status"
                rules={[
                  {
                    required: true,
                    message: message.ERROR_NOT_BLANK("Status"),
                  },
                ]}
                style={{
                  flex: 1,
                  width: "100%",
                }}
              >
                {viewOnly ? (
                  renderFieldViewOnly(Object.keys(C.USER_STATUS).find((key: any) => C.USER_STATUS[key] === detailUser.status))
                ) : (
                  <Select
                    className={commonStyles.select}
                    size="large"
                    dropdownStyle={dropdownStyle}
                    popupClassName="customDropdownSelectAntd"
                    optionFilterProp="children"
                    getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}
                    disabled
                  >
                    {Object.entries(C.USER_STATUS)
                      .slice(Object.keys(C.USER_STATUS).length / 2)
                      .map(([key, value], index) => (
                        <Select.Option value={value} key={index}>
                          {key}
                        </Select.Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={<span className={commonStyles.label}>Username</span>} name="username" rules={[requiredValidate("Username")]}>
                {viewOnly ? renderFieldViewOnly(detailUser?.username) || "" : <Input className={clsx(commonStyles.textField)} size="large" disabled />}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<span className={commonStyles.label}>Role</span>}
                name="role"
                rules={[
                  {
                    required: true,
                    message: message.ERROR_NOT_BLANK("Role"),
                  },
                ]}
              >
                {viewOnly ? (
                  renderFieldViewOnly(detailUser?.roles[0]?.name || "")
                ) : (
                  <Select
                    className={commonStyles.select}
                    size="large"
                    dropdownStyle={dropdownStyle}
                    popupClassName="customDropdownSelectAntd"
                    optionFilterProp="children"
                    disabled
                  >
                    {(roleList || []).map(({ name, id }: any) => (
                      <Select.Option value={id} key={id}>
                        {name}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className={styles.listUserWrapper}>
          <div className={styles.buttonGroup}>
            <ButtonNotched onClick={handleCancel} type="dark">
              {viewOnly ? "Back" : "Cancel"}
            </ButtonNotched>
            {/* {viewOnly ? (
              <ButtonNotched
                onClick={() => {
                  history.push(routes.editUserProfile.path);
                }}
              >
                Edit
              </ButtonNotched>
            ) : (
              <button
                type="submit"
                style={{
                  background: "transparent",
                  border: "none",
                }}
                onClick={() => {
                  const contentElement: any = document.getElementById("content");
                  contentElement.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                disabled={isLoadingUpdateUser}
              >
                <ButtonNotched>Save</ButtonNotched>
              </button>
            )} */}
          </div>
        </div>
      </Form>
    </ContentWrapper>
  );
}

export default UserProfileModule;
