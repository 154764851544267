import { Col, Row } from "antd";
import LogoIcon from "@/assets/icons/Logo";
import styles from "@/modules/QuickOrder/quickOrder.module.less";
import { useState, useEffect } from "react";
import CompanyDropdownList from "@/components/FilterSelectCustom/CompanyDropdownList";
import WarehouseCodeDropdownList from "@/components/FilterSelectCustom/WarehouseCodeDropdownList";
import { CompanySelectType } from "@/modules/WarehouseManagement/warehouseManagement.slice";
import { useGetUserInfoQuery } from "@/rtk-query/userQueries";

interface IFilterComponentProps {
  title: string;
  companySelected?: any;
  warehouseSelected?: any;
  onSelectCompany?: (value: CompanySelectType) => void;
  onSelectWareHouse?: (value: any | null) => void;
  dataOrder?: any;
  isCreate: boolean;
}

function FilterComponent({
  title,
  companySelected,
  warehouseSelected,
  onSelectCompany,
  onSelectWareHouse,
  dataOrder,
  isCreate,
}: IFilterComponentProps) {
  const [searchWarehouse, setSearchWarehouse] = useState("");
  const [searchCompany, setSearchCompany] = useState("");

  const { currentData: userInfo } = useGetUserInfoQuery();

  useEffect(() => {
    if (dataOrder) {
      const firstProduct = dataOrder?.products?.[0];
      onSelectCompany?.({
        companyName: firstProduct?.companyName,
        id: firstProduct?.companyId,
        address: firstProduct?.address,
      });
      onSelectWareHouse?.({
        id: firstProduct?.warehouseId,
        identityCode: firstProduct?.warehouseCode,
      });
    }
  }, [userInfo, dataOrder]);

  return (
    <Row justify="space-between" align="middle" className={styles.filterArea}>
      <Col
        flex={1}
        style={{
          height: "100%",
        }}
      >
        <div className={styles.leftContent}>
          <div className={styles.logoWrapper}>
            <LogoIcon />
            <span
              style={{
                fontSize: 14,
                color: "#234C66",
                fontWeight: 700,
              }}
            >
              {title}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              gap: 16,
            }}
          >
            <CompanyDropdownList
              value={
                companySelected?.companyName || companySelected?.id || null
              }
              search={searchCompany}
              onChange={(value: any) => {
                onSelectCompany?.(JSON.parse(value || null));
                setSearchCompany("");
              }}
              disabled={!isCreate}
            />
            <WarehouseCodeDropdownList
              placeholder={warehouseSelected?.identityCode}
              value={warehouseSelected?.identityCode || null}
              search={searchWarehouse}
              onChange={() => {
                setSearchWarehouse("");
              }}
              onChangeWareHouse={onSelectWareHouse}
              filter={{
                companyId: companySelected?.id,
                ignoreDeleted: true,
              }}
              skipByCompany={!companySelected}
              disabled={!isCreate}
            />
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default FilterComponent;
