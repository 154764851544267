import TableCustom from "@/components/TableCustom";
import { Button, Form, Modal, notification, Typography } from "antd";
import { columns } from "./dialogReceiveOrder.constants";
import styles from "./dialogReceiveOrder.module.less";
import useWindowDimensions from "@/hooks/useWindowDimention";
import { useTypedDispatch, useTypedSelector } from "@/app/store";
import { dialogReceiveSlide } from "./dialogReceiveOrder.slice";
import { useReceivedOrderMutation } from "@/rtk-query/orderQueries";
import message from "@/utils/message";
import { getError } from "@/utils/helpers";
import { useEffect, useState } from "react";

const { Title } = Typography;

const DialogReceiveOrder = () => {
  const [receivedOrderApi] = useReceivedOrderMutation();

  const dispatch = useTypedDispatch();
  const { updateReceiveOrderState } = dialogReceiveSlide.actions;
  const { isOpen, data, id } = useTypedSelector((store) => store.dialogReceiveSlide);
  const windowDimention = useWindowDimensions();
  const [form] = Form.useForm();
  const [isTimeout, setIsTimeout] = useState(false);
  useEffect(() => {
    if (isTimeout) {
      const timeout = setTimeout(() => {
        setIsTimeout(false);
        notification.success({
          message: message.SUCCESSFULLY,
          key: "order_management",
        });
        dispatch(updateReceiveOrderState({ isOpen: false }));
        window.location.reload();
      }, 2000);
      return () => clearTimeout(timeout);
    }
  }, [isTimeout]);
  const handleSubmit = async (values: any) => {
    setIsTimeout(true);
    const products: Array<any> = Object.keys(values.receivedQuantity).map((key) => ({
      productId: key,
      receivedQuantity: values.receivedQuantity[key] ? values.receivedQuantity[key] : data && data[0].receivedQuantity,
    }));

    try {
      await receivedOrderApi({
        id: id || "",
        body: { products },
      }).unwrap();
    } catch (err) {
      notification.error({
        key: "order_management",
        message: getError(err),
      });
    }
  };

  return (
    <Modal
      title={
        <Title level={5} style={{ color: "#DE5706", marginBottom: 32 }}>
          RECEIVE ORDER
        </Title>
      }
      open={isOpen}
      footer={null}
      centered
      width={634}
      closable={false}
      style={{
        background: "transparent",
        borderRadius: 24,
        overflow: "hidden",
      }}
    >
      <Form name="basic" labelCol={{ span: 6 }} wrapperCol={{ span: 24 }} onFinish={handleSubmit} autoComplete="off" form={form}>
        <TableCustom
          data={data || []}
          columns={columns()}
          total={0}
          style={{
            background: "#ffffff",
            borderRadius: 10,
          }}
          scroll={{
            y: windowDimention.height - 300,
          }}
          className={styles.table}
        />
        <div
          style={{
            marginTop: 24,
            display: "flex",
            justifyContent: "flex-end",
            gap: 14,
          }}
        >
          <Button
            type="primary"
            size="large"
            onClick={() => {
              dispatch(updateReceiveOrderState({ isOpen: false }));
            }}
            style={{
              background: "#8C8888",
            }}
          >
            Cancel
          </Button>
          <Button
            htmlType="submit"
            type="primary"
            size="large"
            style={{
              width: 180,
              fontWeight: 600,
            }}
          >
            Save &#38; Update
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default DialogReceiveOrder;
