import { IconProps } from "@/utils/types";

function CalendarIcon({ color = "black", style }: IconProps) {
  return (
    <svg
      width="26"
      height="27"
      viewBox="0 0 26 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M21.8833 2.38333H21.0167V1.08333C21.0167 0.433333 20.5833 0 19.9333 0C19.2833 0 18.85 0.433333 18.85 1.08333V2.38333H16.3583V1.08333C16.3583 0.433333 15.925 0 15.275 0C14.625 0 14.1917 0.433333 14.1917 1.08333V2.38333H11.7V1.08333C11.7 0.433333 11.2667 0 10.6167 0C9.96667 0 9.53333 0.433333 9.53333 1.08333V2.38333H7.15V1.08333C7.15 0.433333 6.71667 0 6.06667 0C5.41667 0 4.98333 0.433333 4.98333 1.08333V2.38333H4.11667C1.84167 2.38333 0 4.225 0 6.5V22.3167C0 24.5917 1.84167 26.4333 4.11667 26.4333H21.8833C24.1583 26.4333 26 24.5917 26 22.3167V6.5C26 5.63333 25.7833 4.875 25.2417 4.225C24.5917 3.03333 23.2917 2.38333 21.8833 2.38333ZM23.8333 22.3167C23.8333 23.4 22.9667 24.2667 21.8833 24.2667H4.11667C3.03333 24.2667 2.16667 23.4 2.16667 22.3167V9.75H23.8333V22.3167Z"
        fill={color}
      />
      <path
        d="M5.30869 16.0337H8.12536C8.45036 16.0337 8.77536 15.7087 8.77536 15.3837V12.567C8.77536 12.242 8.45036 11.917 8.12536 11.917H5.30869C4.98369 11.917 4.65869 12.242 4.65869 12.567V15.3837C4.55036 15.7087 4.87536 16.0337 5.30869 16.0337Z"
        fill={color}
      />
      <path
        d="M11.5919 16.0337H14.4086C14.7336 16.0337 15.0586 15.7087 15.0586 15.3837V12.567C15.0586 12.242 14.7336 11.917 14.4086 11.917H11.5919C11.2669 11.917 10.9419 12.242 10.9419 12.567V15.3837C10.9419 15.7087 11.2669 16.0337 11.5919 16.0337Z"
        fill={color}
      />
      <path
        d="M17.8751 16.0337H20.6918C21.0168 16.0337 21.3418 15.7087 21.3418 15.3837V12.567C21.3418 12.242 21.0168 11.917 20.6918 11.917H17.8751C17.5501 11.917 17.2251 12.242 17.2251 12.567V15.3837C17.2251 15.7087 17.5501 16.0337 17.8751 16.0337Z"
        fill={color}
      />
      <path
        d="M5.30869 22.4253H8.12536C8.45036 22.4253 8.77536 22.1003 8.77536 21.7753V18.9586C8.77536 18.6336 8.45036 18.3086 8.12536 18.3086H5.30869C4.98369 18.3086 4.65869 18.6336 4.65869 18.9586V21.7753C4.55036 22.1003 4.87536 22.4253 5.30869 22.4253Z"
        fill={color}
      />
      <path
        d="M11.5919 22.4253H14.4086C14.7336 22.4253 15.0586 22.1003 15.0586 21.7753V18.9586C15.0586 18.6336 14.7336 18.3086 14.4086 18.3086H11.5919C11.2669 18.3086 10.9419 18.6336 10.9419 18.9586V21.7753C10.9419 22.1003 11.2669 22.4253 11.5919 22.4253Z"
        fill={color}
      />
      <path
        d="M17.8751 22.4253H20.6918C21.0168 22.4253 21.3418 22.1003 21.3418 21.7753V18.9586C21.3418 18.6336 21.0168 18.3086 20.6918 18.3086H17.8751C17.5501 18.3086 17.2251 18.6336 17.2251 18.9586V21.7753C17.2251 22.1003 17.5501 22.4253 17.8751 22.4253Z"
        fill={color}
      />
    </svg>
  );
}
export default CalendarIcon;
