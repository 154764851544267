function UploadImage() {
  return (
    <svg
      width="63"
      height="86"
      viewBox="0 0 63 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.5 51C39.5081 51 46 57.4919 46 65.5C46 73.5081 39.5081 80 31.5 80C23.4919 80 17 73.5081 17 65.5C17 57.4919 23.4919 51 31.5 51Z"
        fill="#F57C0C"
        fillOpacity="0.24"
      />
      <g filter="url(#filter0_d_952_882)">
        <path
          d="M31.4999 53.7432C37.993 53.7432 43.2567 59.0068 43.2567 65.4999C43.2567 71.993 37.993 77.2567 31.4999 77.2567C25.0068 77.2567 19.7432 71.993 19.7432 65.4999C19.7432 59.0068 25.0068 53.7432 31.4999 53.7432Z"
          fill="#F36F21"
        />
      </g>
      <path
        d="M34.974 61.8834L32.1414 58.6462C32.0645 58.5588 31.9537 58.5078 31.8371 58.5078C31.7206 58.5078 31.6097 58.5588 31.5328 58.6462L28.7003 61.8834C28.5959 62.0032 28.57 62.1723 28.6363 62.3172C28.7019 62.4613 28.8459 62.5543 29.0046 62.5543H30.6232V67.8148C30.6232 68.0382 30.8044 68.2194 31.0278 68.2194H32.6464C32.8698 68.2194 33.0511 68.0382 33.0511 67.8148V62.5543H34.6697C34.8283 62.5543 34.9723 62.4621 35.0379 62.3172C35.1035 62.1723 35.0792 62.0024 34.974 61.8834Z"
        fill="white"
      />
      <path
        d="M36.288 67.4111V69.839H27.3857V67.4111H25.7671V70.6483C25.7671 71.0959 26.1297 71.4576 26.5764 71.4576H37.0973C37.5449 71.4576 37.9066 71.0959 37.9066 70.6483V67.4111H36.288Z"
        fill="white"
      />
      <path
        d="M9.69844 49.7904H1.01613C0.455226 49.7904 0 49.3351 0 48.7742V39.813C0 39.2521 0.455226 38.7969 1.01613 38.7969C1.57703 38.7969 2.03226 39.2521 2.03226 39.813V47.7581H9.69844C10.2593 47.7581 10.7146 48.2133 10.7146 48.7742C10.7146 49.3351 10.2599 49.7904 9.69844 49.7904Z"
        fill="#F36F21"
      />
      <path
        d="M61.984 49.7899H53.3174C52.7565 49.7899 52.3013 49.3346 52.3013 48.7737C52.3013 48.2128 52.7565 47.7576 53.3174 47.7576H60.9678V39.813C60.9678 39.2521 61.4231 38.7969 61.984 38.7969C62.5449 38.7969 63.0001 39.2521 63.0001 39.813V48.7737C63.0001 49.3346 62.5449 49.7899 61.984 49.7899Z"
        fill="#F36F21"
      />
      <path
        d="M61.9838 10.676C61.4229 10.676 60.9677 10.2207 60.9677 9.65983V2.03226H53.1206C52.5597 2.03226 52.1045 1.57703 52.1045 1.01613C52.1045 0.455226 52.5597 0 53.1206 0H61.9838C62.5447 0 62.9999 0.455226 62.9999 1.01613V9.65983C62.9999 10.2207 62.5447 10.676 61.9838 10.676Z"
        fill="#F36F21"
      />
      <path
        d="M1.01613 11.4123C0.455226 11.4123 0 10.957 0 10.3961V1.57765C0 1.01675 0.455226 0.561523 1.01613 0.561523H9.48607C10.047 0.561523 10.5022 1.01675 10.5022 1.57765C10.5022 2.13856 10.047 2.59378 9.48607 2.59378H2.03226V10.3961C2.03226 10.9575 1.57703 11.4123 1.01613 11.4123Z"
        fill="#F36F21"
      />
      <path
        d="M6.09668 6.09668H56.9031V43.6934H6.09668V6.09668Z"
        fill="#D7F4FF"
      />
      <path
        d="M25.1318 15.4524C26.8765 13.6955 29.7059 13.6955 31.4506 15.4524C33.1953 17.2093 33.1953 20.0575 31.4506 21.8139C29.7059 23.5703 26.8765 23.5708 25.1318 21.8139C23.3871 20.057 23.3871 17.2093 25.1318 15.4524Z"
        fill="#FFCC64"
      />
      <path
        d="M32.7465 18.3552C32.0458 17.6464 30.794 17.839 30.3723 18.7565C30.1279 19.288 29.7575 19.7615 29.3018 20.1237C28.6215 20.6648 28.7693 22.3541 29.4593 22.7144C29.7123 22.8465 29.8139 22.8668 30.069 22.756C30.5836 22.5325 31.0551 22.2119 31.4504 21.8141C32.2633 20.9956 32.6921 19.9403 32.7465 18.8688C32.7627 18.8632 32.7693 18.7332 32.7465 18.3552Z"
        fill="#F9B43A"
      />
      <path
        d="M25.7435 18.9155C25.8253 18.3861 25.9036 17.8496 26.1617 17.3817C26.6143 16.5596 25.9417 15.5796 25.0409 15.5547C23.9211 16.7517 23.5726 18.4125 24.0024 19.8971C24.2808 20.0541 25.5779 19.9896 25.7435 18.9155Z"
        fill="#FFE6C2"
      />
      <path
        d="M18.5422 25.1387L6.09668 39.5199V43.6937H21.6429L27.8596 36.3186L18.5422 25.1387Z"
        fill="#44AC3D"
      />
      <path
        d="M18.5423 25.1387L11.3567 33.4369C10.6429 34.262 10.6002 35.5723 11.2505 36.4467C11.9847 37.4333 13.3869 37.5217 14.2008 36.5813L21.3635 28.5239L18.5423 25.1387Z"
        fill="#64C35B"
      />
      <path
        d="M27.8597 36.3185L21.6431 43.6936H56.9033V36.6299L42.2176 19.2861L27.8597 36.3185Z"
        fill="#44AC3D"
      />
      <path
        d="M56.9032 43.5613L56.9027 36.4712C55.5401 34.8622 52.23 31.1909 52.23 31.1909C51.5609 30.4029 50.4569 30.377 49.7593 31.1294C49.0623 31.8834 49.0374 33.1342 49.705 33.9217L53.4027 38.2449L53.4052 40.424H9.34584C8.45013 40.424 7.72461 41.1556 7.72461 42.0584C7.72461 42.9612 8.45013 43.6934 9.34584 43.6934H56.8915C56.8941 43.6486 56.9032 43.6065 56.9032 43.5613Z"
        fill="#237F19"
      />
      <defs>
        <filter
          id="filter0_d_952_882"
          x="15.7432"
          y="53.7432"
          width="31.5134"
          height="31.5137"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.152941 0 0 0 0 0.145098 0 0 0 0 0.145098 0 0 0 0.19 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_952_882"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_952_882"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
export default UploadImage;
