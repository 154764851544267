import { Button, Col, Form, Input, Row, Typography, notification } from "antd";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import styles from "./changePassword.module.less";
import { getError, isAuthenticated } from "@/utils/helpers";
import AuthLayout from "@/components/layouts/AuthLayout";
import routes from "@/routes";
import message from "@/utils/message";
import {
  useChangePasswordMutation,
  useGetUserInfoQuery,
} from "@/rtk-query/userQueries";

const { Title, Text } = Typography;

function ChangePasswordModule() {
  const history = useHistory();
  const { currentData } = useGetUserInfoQuery();

  const [changePasswordApi, { isLoading }] = useChangePasswordMutation();

  const onFinish = async (values: any) => {
    try {
      await changePasswordApi(values).unwrap();
      notification.success({
        message: message.SUCCESSFULLY,
        key: "change_password",
      });
      history.push("/");
    } catch (err) {
      notification.error({
        key: "change_password",
        message: getError(err),
      });
    }
  };

  useEffect(() => {
    !isAuthenticated() && history.push("/login");
  }, [history]);

  return (
    <AuthLayout title={routes.changePassword.title}>
      <Title
        level={3}
        style={{
          marginBottom: 16,
          fontSize: 25,
        }}
      >
        Change password
      </Title>
      <Text
        className={styles.textBase}
        style={{
          color: "#454545",
          width: 365,
          textAlign: "center",
          marginBottom: 40,
        }}
      >
        To change your password, enter your current password and your new
        password below.
      </Text>

      <Form
        name="normal_login"
        className={styles.loginForm}
        initialValues={{ remember: false }}
        onFinish={onFinish}
      >
        <Form.Item
          name="oldPassword"
          rules={[
            {
              required: true,
              message: message.ERROR_NOT_BLANK("This field"),
            },
          ]}
          style={{
            marginBottom: "0px !important",
          }}
        >
          <Input.Password
            size="large"
            type="password"
            placeholder="Current password..."
            className={styles.textField}
          />
        </Form.Item>

        <Form.Item
          name="newPassword"
          rules={[
            {
              required: true,
              message: message.ERROR_NOT_BLANK("This field"),
            },
          ]}
          style={{
            marginBottom: "0px !important",
          }}
        >
          <Input.Password
            size="large"
            type="password"
            placeholder="New password..."
            className={styles.textField}
          />
        </Form.Item>

        <Form.Item
          name="confirmPassword"
          rules={[
            {
              required: true,
              message: message.ERROR_NOT_BLANK("This field"),
            },
          ]}
          style={{
            marginBottom: "0px !important",
          }}
        >
          <Input.Password
            size="large"
            type="password"
            placeholder="Repeat new password..."
            className={styles.textField}
          />
        </Form.Item>

        <Row justify="space-between" gutter={[48, 0]}>
          <Col span={8}>
            <Button
              type="primary"
              className="login-form-button"
              style={{
                width: "100%",
                marginTop: 40,
                background: "#858585",
              }}
              size="large"
              onClick={() => {
                if (currentData?.isFirstTimeLogin) {
                  history.replace(routes.logout.path);
                  return;
                }
                history.goBack();
              }}
            >
              <Title
                level={5}
                style={{
                  color: "#fff",
                  fontSize: 15,
                }}
              >
                Cancel
              </Title>
            </Button>
          </Col>
          <Col span={16}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                style={{
                  width: "100%",
                  marginTop: 40,
                }}
                size="large"
                disabled={isLoading}
              >
                <Title
                  level={5}
                  style={{
                    color: "#fff",
                    fontSize: 15,
                  }}
                >
                  Change password
                </Title>
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </AuthLayout>
  );
}

export default ChangePasswordModule;
