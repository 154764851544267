import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import EditIcon from "@/assets/icons/EditIcon";
import C from "@/utils/constants";
import { UserType } from "@/utils/types";

export const mockDataClient = {
  id: "1",
  createdAt: "2022-12-21T03:49:53.770Z",
  updatedAt: "2022-12-21T03:49:53.770Z",
  companyName: "huynhdn's company",
  address: "FLC 36 Phạm Hùng, HN, Việt Nam",
  logoUrl: "https://d33wubrfki0l68.cloudfront.net/e937e774cbbe23635999615ad5d7732decad182a/26072/logo-small.ede75a6b.svg",
  contact: 374539633,
};

export const userColumns = ({ onEdit }: { onEdit: (type: "create" | "edit", id?: string | number) => void }) =>
  [
    {
      title: "No.",
      dataIndex: "id",
      key: "id",
      render: (_, record, index) => (
        <div
          style={{
            maxWidth: 160,
          }}
        >
          {index + 1}
        </div>
      ),
    },
    {
      title: "Full name",
      dataIndex: "fullName",
      key: "fullName",
      width: 160,
      render: (text) => (
        <div
          style={{
            maxWidth: 160,
          }}
        >
          {text}
        </div>
      ),
    },
    {
      title: "D.O.B",
      dataIndex: "dob",
      key: "dob",
      render: (text) => <span>{dayjs(text).format("DD/MM/YYYY")}</span>,
    },
    {
      title: "Contact",
      dataIndex: "contact",
      key: "contact",
      width: 160,
      render: (text, record) => (
        <div
          style={{
            maxWidth: 160,
          }}
        >
          {`${record?.phoneExt} ${record?.phoneNumber}`}
        </div>
      ),
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: 160,
      render: (text) => (
        <div
          style={{
            maxWidth: 160,
          }}
        >
          {text}
        </div>
      ),
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => <div>{C.USER_STATUS[status]}</div>,
    },
    {
      title: "User role",
      dataIndex: "roles",
      key: "roles",
      width: 100,
      render: (roles) => <div>{roles?.[0]?.name || ""}</div>,
    },
    {
      title: "Update on",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (text) => <span>{dayjs(text).format("DD/MM/YYYY")}</span>,
    },
    {
      title: "Functions",
      render: (_, record) => (
        <div onClick={() => onEdit("edit", record?.id)}>
          <EditIcon style={{ width: 16, cursor: "pointer" }} />
        </div>
      ),
    },
  ] as ColumnsType<UserType>;
