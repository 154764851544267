import { IconProps } from "@/utils/types";

function DeleteIcon({ color = "black", style }: IconProps) {
  return (
    <svg
      width="16"
      height="21"
      viewBox="0 0 16 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M1.14258 18.479C1.14258 19.7361 2.17115 20.7647 3.42832 20.7647H12.5712C13.8283 20.7647 14.8569 19.7361 14.8569 18.479V4.76465H1.14258V18.479ZM3.95974 10.3418L5.57691 8.72466L7.99974 11.1475L10.4226 8.72466L12.0398 10.3418L9.6169 12.7647L12.0398 15.1876L10.4226 16.8047L7.99974 14.3818L5.57686 16.8047L3.95969 15.1875L6.38257 12.7646L3.95974 10.3418Z"
        fill={color}
      />
      <path
        d="M12 1.3362L10.8571 0.193359H5.14287L3.99997 1.3362H0V3.62194H16V1.3362H12Z"
        fill="black"
      />
    </svg>
  );
}
export default DeleteIcon;
