import { Layout } from "antd";
import { Switch } from "react-router-dom";
import { Redirect } from "react-router";
import { useEffect } from "react";
import routes from "@/routes";
import styles from "./dashboardLayout.module.less";
import { isAuthenticated } from "@/utils/helpers";
import Sidebar from "@/components/layouts/DashboardLayout/Sidebar";
import DialogUserCUR from "@/modules/DialogUserCUR";
import { useTypedDispatch, useTypedSelector } from "@/app/store";
import C from "@/utils/constants";
import useMediaQuery from "@/hooks/useMediaquery";
import ProtectedRoute from "@/components/ProtectedRouter";
import { dashboardLayoutSlide } from "./dashboardLayout.slice";
import DialogReceiveOrder from "@/modules/DialogReceiveOrder";

const { Sider } = Layout;

function DashboardLayout() {
  const dispatch = useTypedDispatch();
  const { toggleSidebar } = dashboardLayoutSlide.actions;
  const { isOpenSidebar } = useTypedSelector((store) => store.dashboardLayoutSlide);

  const isTablet = useMediaQuery(`(max-width: ${C.DESKTOP_SMALL_WIDTH}px)`);

  const handleToggleSidebar = () => {
    dispatch(toggleSidebar(!isOpenSidebar));
  };

  const getRoutes = (allRoutes: any[]) =>
    allRoutes
      .filter(({ layout }) => layout === "admin")
      .map((route: any, index) => {
        if (isAuthenticated() && route.path) {
          return <ProtectedRoute path={route.path} component={route.component} key={index} screenAction={route.screenAction} />;
        }
        return (
          <Redirect
            to={{
              pathname: routes.login.path,
            }}
            key={index}
          />
        );
      });

  useEffect(() => {
    if (isTablet) {
      dispatch(toggleSidebar(false));
    }
  }, [isTablet]);

  return (
    <Layout className={styles.layoutWrapper}>
      <Sider
        trigger={null}
        collapsible
        width={!isOpenSidebar ? 56 : 290}
        style={{
          background: "#EDEFF6",
        }}
      >
        <Sidebar routes={Object.values(routes)} collapsed={!isOpenSidebar} onToggleSidebar={handleToggleSidebar} isTablet={isTablet} />
      </Sider>
      <Layout className="site-layout">
        <Switch>
          {getRoutes(Object.values(routes))}
          <Redirect from="/" to={routes.masterProductList.path} />
        </Switch>
      </Layout>

      <DialogUserCUR />
      <DialogReceiveOrder />
    </Layout>
  );
}

export default DashboardLayout;
